import {default as axios, type AxiosResponse} from 'axios';

export type NotificationGroup = {
  title: string,
  slug: string,
}

export type NotificationsSchema = {
  globalVariables: string[],
  groups: NotificationGroup[],
  placeholders: Record<string, any>[],
  emails: {
    settings: Record<string, string>,
    styles: Record<string, string>,
    templates: Record<string, any>,
  }
}

export function getSchema(): Promise<NotificationsSchema> {
  return new Promise((resolve, reject): void => {
    axios.get(`notification/schema`)
      .then((res: AxiosResponse): void => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getEmails(): Promise<NotificationsSchema> {
  return new Promise((resolve, reject): void => {
    axios.get(`notification/emails`)
      .then((res: AxiosResponse): void => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}