import axios from 'axios';
import changeCase from 'change-object-case';
import { isObject, merge } from 'lodash';

changeCase.options = { recursive: true, arrayRecursive: true };

export default {
  getAll() {
    return axios.get('/destinations')
      .then(res => {
        const sorted = res.data.sort((a, b) => {
          return a.id - b.id;
        });

        return changeCase.toCamel(sorted);
      });
  },
  getById(id) {
    if (!id) {
      return Promise.reject('API: Destinations.getById() requires an id to fetch.');
    }

    return axios.get(`/destinations/${id}`)
      .then(res => {
        if (res.data.address) {
          res.data.address = changeCase.toCamel(res.data.address);
        }
        return changeCase.toCamel(res.data);
      });
  },
  save(destination) {
    if (!destination || !isObject(destination)) {
      return Promise.reject('API: Destinations.save() requires a destination object to save.');
    }

    const endpoint = destination.id ? `/destinations/${destination.id}` : '/destinations';
    const verb = destination.id ? 'patch' : 'post';
    const transformed = merge(
      {},
      changeCase.toSnake({
        ...destination,
        rules: destination.rules
          .filter((rule) => {
            // Remove any rules with insufficient data
            return rule.action.length && rule.value.length;
          })
          .map((rule) => {
            const { id, ...rest } = rule;

            // Remove any new rule ids
            return {
              ...rest,
              ...(id.toString().startsWith('new-rule') ? {} : { id })
            };
          })
      }),
      {
        address: {
          // Both addresses and destinations require a name, we're just using the same name for both
          name: destination.name,
          // Our api isn't consistent with snake case, so we're adding an exception here for the numbers
          address1: destination.address.address1,
          address2: destination.address.address2
        }
      }
    );
    return axios[verb](endpoint, transformed)
      .then(res => {
        return changeCase.toCamel(res.data);
      });
  },
  remove(destination) {
    if (!destination || !isObject(destination)) {
      return Promise.reject('API: Destinations.remove() requires a destination object to remove.');
    }

    return axios.delete(`/destinations/${destination.id}`)
      .then(res => {
        return changeCase.toCamel(res.data);
      });
  },
  export() {
    return axios.get(`/destinations/bulk/export`)
      .then(res => {
        // Returns CSV data instead of JSON
        return res.data;
      });
  },
  import(file) {
    if (!file || !(file instanceof File)) {
      return Promise.reject('API: destinations.import() requires a File in order to upload.');
    }

    const formData = new FormData();
    formData.append('csv', file);
    return axios.post('/destinations/bulk/import', formData)
      .then((res) => changeCase.toCamel(res.data));
  },
  confirm(batchId) {
    if (!batchId) {
      return Promise.reject('API: destinations.confirm() requires a batchId in order to confirm an import.');
    }

    return axios.post('/destinations/bulk/confirm', changeCase.toSnake({ batchId }))
      .then((res) => changeCase.toCamel(res.data));
  }
};
