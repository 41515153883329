import axios from 'axios';
import customization from './customizations';
import Destinations from './destinations';

const policies = function () {
  // This is what the endpoint expects
  /* const requestTemplate = [{
        id: '',
        value: ''
    }] */
};

policies.prototype = {
  get() {
    return Promise.all([
      axios.get(`policies`),
      customization.get(),
      Destinations.getAll().then((res) => ({ destinations: res }))
    ])
      .then(res => {
        return res.reduce((acc, item) => {
          const data = item.data || item;
          return {
            data: {
              ...acc.data,
              ...data
            }
          };
        }, {});
      });
  },

  /**
   * @param {Object[]} settings
   * @param {Number} settings.id - The database id
   * @param {Number|String} settings.value - The value to set
   * @param {Boolean} map - true = maps boolean values to yes/no strings
   */
  save(settings, map = false) {
    if (!Array.isArray(settings) || settings === null) {
      console.error('API: policies.save() requires an object as an input.');
      return Promise.reject();
    }

    return axios.put(`policies/batch`, settings.map(setting => {
      if (typeof setting.value === 'boolean' && map) {
        return {
          ...setting,
          value: setting.value ? 'yes' : 'no'
        };
      }

      return setting;
    }));
  }
};

export default policies;
