import store from '@/store';
import Tasks from '@/util/api/tasks';
import { assignableTaskGroups } from '@/constants/assignableTaskGroups';

export default [
  {
    path: '/testing-hub',
    component: () => import('@/pages/OnboardingHub/TestingHubPage'),
    name: 'TestingHub',
    meta: {
      layout: 'app',
      loader: false,
      title: 'Testing & Validation',
    },
    children: [
      {
        path: 'testing',
        name: 'TestingHubTesting',
        component: () => import('@/pages/OnboardingHub/Pages/SetupTesting'),
        beforeEnter: () => {
          if (!store.state.userData.has_onboarding_modules) {
            return { name: 'OnboardingHubBasicSetup' };
          }
        }
      },
      {
        path: 'testing/:slug',
        name: 'TestingHubTestingAndValidation',
        component: () => import('@/pages/OnboardingHub/Pages/TestingAndValidation'),
        beforeEnter: async () => {
          const shopId = store.getters.shop.id;
          const assignments = await Tasks.getAll(shopId, 'shop', { group: assignableTaskGroups.BASIC_SETUP });
          const allAssignmentsComplete = assignments.every((assignment) => Boolean(assignment.completedAt));
          if (!allAssignmentsComplete || !store.state.userData.has_onboarding_modules) {
            return { name: 'OnboardingHubBasicSetup' };
          }
        }
      },
    ]
  },
];
