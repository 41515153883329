/**
 * TODO: Deprecated, remove when return reason improvements is live
 */
import _ from 'lodash';
import ReasonsApi from '@/util/api/reasons.old';
import Products from '@/util/api/products';

const reasonsRequest = new ReasonsApi();

const sortReasons = reasons => {
  return reasons
    .sort((a, b) => a.order - b.order)
    .map(item => {
      if (item.children) {
        const sorted = item.children.sort((a, b) => a.order - b.order);

        return Object.assign({}, item, { children: sorted });
      }

      return item;
    });
};

const formatItem = (item, active) => {
  const { id, order, parent_id, reason, children, copy, comments, comments_required } = item;
  return {
    id,
    order,
    title: copy && copy.en ? copy.en : reason,
    parent: parent_id,
    options: children ? children.filter(child => !child.delete).map(child => formatItem(child, active)) : null,
    comments,
    comments_required,
    icon: comments ? 'comment' : null,
    active: active.includes(id)
  };
};

const updateReason = (reasons, { id, reason, parent, comments, comments_required }) => {
  if (id) {
    return reasons.map(item => {
      let children = {};
      if (item.children && item.children.length) {
        children = {
          children: updateReason(item.children, { id, reason, comments, comments_required })
        };
      }
      if (item.id === id) {
        return {
          ...item,
          ...children,
          reason,
          comments,
          comments_required,
          update: true
        };
      }

      return {
        ...item,
        ...children
      };
    });
  }

  const random = [...Array(16)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
  return [
    ...reasons,
    {
      id: `new-${random}`,
      order: reasons.length,
      children: [],
      parent_id: parent || 0,
      reason,
      comments,
      comments_required,
      update: true
    }
  ];
};

// Because we have a delayed save, we're merely flagging for deletion
const deleteReason = (reasons, { id }) => {
  return reasons.map(item => {
    let children = {};
    if (item.children) {
      children = {
        children: deleteReason(item.children, { id })
      };
    }

    if (item.id === id) {
      return {
        ...item,
        ...children,
        delete: true
      };
    }

    return {
      ...item,
      ...children
    };
  })
    .filter(item => {
      // Filtering out new items that are deleted,
      // the back end doesn't know about them yet
      return !item.delete || (item.delete && !item.id.toString().startsWith('new-'));
    });
};

const mapType = (types, group) => {
  return {
    ...group,
    types: group.types.map((typeId) => {
      return types.find((type) => type.id === typeId || type.id === typeId.id) || null;
    })
      .filter(Boolean)
  };
};

const attachReason = (reasons, defs) => {
  return reasons.map((item) => {
    const isObject = typeof item === 'object';
    const id = isObject ? item.id : item;
    const children = isObject ? item.children : null;
    const reason = defs?.find((r) => r.id === item.id || r === item.id);

    return {
      id,
      reason: reason?.reason,
      ...(children ? { children: attachReason(children, reason?.children) } : {})
    };
  })
    .filter((item) => {
      if (item.children) {
        return !!item.reason;
      }

      return true;
    });
};

export default {
  namespaced: true,
  state: {
    text: {
    },
    reset: [],
    reasons: [],
    active: [],
    saving: false,
    groups: [],
    group: null,
    productTypes: []
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    updateActive(state, { id }) {
      if (state.active.includes(id)) {
        state.active = state.active.filter(active => active !== id);
      } else {
        state.active = [
          ...state.active,
          id
        ];
      }
    },
    updateOrder(state, newOrder) {
      state.reasons = reasonsRequest.updateOrder(state.reasons, newOrder);
    },
    updateReason(state, reason) {
      if (reason.parent) {
        state.reasons = state.reasons.map(item => {
          if (reason.parent === item.id) {
            const children = updateReason(item.children, reason);
            return Object.assign({}, item, { children });
          }
          return item;
        });
      } else {
        state.reasons = updateReason(state.reasons, reason);
      }
    },
    deleteReason(state, reason) {
      state.reasons = deleteReason(state.reasons, reason);
    },
    resetReasons(state) {
      state.reasons = _.cloneDeep(state.reset);
      state.randomize.value = state.randomize.initial;
    },
    updateRandomize(state, randomize) {
      state.randomize.value = randomize;
    },
    setGroup(state, group) {
      const withTypes = mapType(state.productTypes, group);
      state.group = _.cloneDeep({
        ...withTypes,
        reasons: attachReason(withTypes.reasons, state.reasons)
      });
    }
  },
  actions: {
    async getPageData({ commit }/* , route */) {
      const [res, groups, types] = await Promise.all([
        reasonsRequest.get(),
        reasonsRequest.groups.get(),
        Products.getTypes()
      ]);
      const reasons = sortReasons(res.data);

      commit('setProperty', {
        key: 'reset',
        value: _.cloneDeep(reasons)
      });
      commit('setProperty', {
        key: 'reasons',
        value: _.cloneDeep(reasons)
      });
      commit('setProperty', {
        key: 'productTypes',
        value: _.cloneDeep(types)
      });
      commit('setProperty', {
        key: 'groups',
        value: _.cloneDeep(groups.map((group) => mapType(types, group)))
      });

      return Promise.resolve();
    },
    async getGroupData({ commit, state }, route) {
      const [res, groups, types] = await Promise.all([
        reasonsRequest.get(),
        reasonsRequest.groups.get(),
        Products.getTypes()
      ]);
      const reasons = sortReasons(res.data);
      commit('setProperty', {
        key: 'reasons',
        value: _.cloneDeep(reasons)
      });
      commit('setProperty', {
        key: 'productTypes',
        value: _.cloneDeep(types)
      });
      commit('setProperty', {
        key: 'groups',
        value: _.cloneDeep(groups.map((group) => mapType(types, group)))
      });

      if (route.params.id) {
        const group = state.groups.find((group) => group.id === +route.params.id);

        if (group) {
          commit('setGroup', group);
        }
      }

      return Promise.resolve();
    },
    async save({ commit, getters }) {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'saving', value: true });

      try {
        const res = await reasonsRequest.save(getters.modifiedItems);
        const reasons = sortReasons(res.data);
        commit('setProperty', { key: 'reasons', value: _.cloneDeep(reasons) });
        commit('setProperty', { key: 'reset', value: _.cloneDeep(reasons) });
        commit('setProperty', { key: 'saving', value: false });

        commit('setToast', {
          message: 'Reasons saved successfully',
          type: 'success',
        }, { root: true });
      } catch (error) {
        console.error(error);
        commit('setProperty', { key: 'saving', value: false });
        commit('setToast', {
          message: `Whoops, we couldn't save these reasons`,
          type: 'error',
        }, { root: true });
      }
    }
  },
  getters: {
    items(state) {
      return state.reasons
        .filter(reason => !reason.delete)
        .sort((a, b) => a.order - b.order)
        .map(reason => {
          return formatItem(reason, state.active);
        });
    },
    flattenedItems(state) {
      return state.reasons
        .reduce((acc, val) => {
          return [
            ...acc,
            val,
            ...val.children
          ];
        }, []);
    },
    getById: (state, getters) => (id) => {
      return getters['flattenedItems']
        .filter(reason => !reason.delete)
        .map(reason => {
          return {
            ...reason,
            children: reason.children ? reason.children.filter(child => !child.delete) : []
          };
        })
        .find(val => val.id === id);
    },
    modifiedItems(state, getters) {
      return getters['flattenedItems']
        .filter(item => {
          return item.update || item.delete;
        });
    }
  }
};
