export const feeTypes = {
  FLAT_FEE: 'flat_fee',
  PERCENTAGE_OF_LABEL: 'percentage_of_label',
  PRODUCT_TAG: 'product_tag',
  PERCENTAGE_OF_RETURN: 'return_value'
};

export const feeOutcomes = {
  CREDIT: 'credit',
  REFUND: 'refund',
  EXCHANGE: 'exchange',
  SHOP_LATER: 'shop_later',
};
