import env from '@/env';

/**
 * Initializes the Intercom beacon if the intercom key is set
 * @param {User} user
 */
export const initIntercom = ({ name, email }) => {
  if (env('VITE_INTERCOM_KEY')) {
    // Global stuff
    window.intercomSettings = {
      app_id: env('VITE_INTERCOM_KEY'),
      alignment: 'right',
      name,
      email,
    };
    // Intercom init
    const APP_ID = env('VITE_INTERCOM_KEY');
    (function () { var w = window; var ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + APP_ID; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; l(); } })();
  }
};
