import axios from 'axios';
import changeCase from 'change-object-case';
changeCase.options = { recursive: true, arrayRecursive: true };

export default {
  async get(workflowGroupId) {
    const res = await axios.get(`/workflow-groups/${workflowGroupId}`);
    return changeCase.toCamel(res.data);
  },
  async getByType(type) {
    const res = await axios.get(`/workflow-groups/type/${type}`);
    return changeCase.toCamel(res.data);
  },
  async create(workflowGroup) {
    const res = await axios.post(`/workflow-groups/`, changeCase.toSnake(workflowGroup));
    return changeCase.toCamel(res.data);
  },
  async update(workflowGroup) {
    const res = await axios.patch(`/workflow-groups/${workflowGroup.id}`, changeCase.toSnake(workflowGroup));
    return changeCase.toCamel(res.data);
  },
  async delete(workflowGroupId) {
    const res = await axios.delete(`/workflow-groups/${workflowGroupId}`);
    return changeCase.toCamel(res.data);
  },
  async activate(workflowGroupId) {
    const res = await axios.patch(`/workflow-groups/${workflowGroupId}/activate`);
    return changeCase.toCamel(res.data);
  },
  async deactivate(workflowGroupId) {
    const res = await axios.patch(`/workflow-groups/${workflowGroupId}/deactivate`);
    return changeCase.toCamel(res.data);
  },
  async getPaginated(
    page = 1, 
    type = null, 
    active = null, 
    term = null
  ) {
    const response = await axios.get(`/workflow-groups`, {
      params: {
        page,
        type,
        active,
        term,
      }
    });
    return changeCase.toCamel(response.data);
  }
};
