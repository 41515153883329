<template>
  <div
    class="skeleton-detail"
    aria-hidden="true"
  >
    <skeleton-text
      class="skeleton-detail__heading"
      :lines="1"
      type="heading"
    />
    <page-layout align="left">
      <base-card class="skeleton-detail__card">
        <skeleton-text :lines="5" />
      </base-card>
      <base-card class="skeleton-detail__card">
        <skeleton-text :lines="4" />
      </base-card>
      <template #sidebar>
        <base-card
          class="skeleton-detail__sidebar"
          type="subdued"
        />
      </template>
    </page-layout>
  </div>
</template>

<script>
import SkeletonText from '@/components/skeletons/SkeletonText';
import PageLayout from '@/components/structure/PageLayout';

export default {
  components: {
    SkeletonText,
    PageLayout,
  }
};
</script>

<style lang="scss" scoped>
$block: '.skeleton-detail';

#{$block} {
  width: 100%;

  &__heading {
    margin: 2rem 0;
  }

  &__card {
    margin-bottom: 1rem;
  }

  &__sidebar {
    min-height: 400px;
  }
}
</style>
