// Shared
const SBL_DOMESTIC_SLUG = 'ship-by-loop';
const SBL_DOMESTIC_STORE_NAME = 'domestic';
const SBL_WORLDWIDE_SLUG = 'ship-by-loop-worldwide';
const SBL_WORLDWIDE_STORE_NAME = 'worldwide';

// Domestic: Ship By Loop EasyPost
const USPS_PLATFORM = 'usps-platform';
const FEDEX_PLATFORM = 'fedex-platform';
const FEDEX_OWN = 'fedex';
const UPS_OWN = 'ups';
const AUS_OWN = 'australia-post';
const FEDEX_SMARTPOST_OWN = 'fedex-smartpost';
const UPS_MI_OWN = 'ups-mail-innovations';

const USPS_SLUG = 'usps';
const FEDEX_SLUG = 'fedex';
const UPS_SLUG = 'ups';
const AUS_SLUG = 'australia-post';
const FEDEX_SMARTPOST_SLUG = 'fedex-smartpost';
const UPS_MI_SLUG = 'ups-mail-innovations';

export default {
  // Shared
  SBL_DOMESTIC_SLUG,
  SBL_DOMESTIC_STORE_NAME,
  SBL_WORLDWIDE_SLUG,
  SBL_WORLDWIDE_STORE_NAME,
  INTEGRATIONS: [
    SBL_DOMESTIC_SLUG,
    SBL_WORLDWIDE_SLUG,
  ],
  SBL_INSTANCE_MAP: {
    [SBL_DOMESTIC_SLUG]: {
      storeName: SBL_DOMESTIC_STORE_NAME,
      title: 'Ship By Loop',
    },
    [SBL_WORLDWIDE_SLUG]: {
      storeName: SBL_WORLDWIDE_STORE_NAME,
      title: 'Ship By Loop Worldwide',
    },
  },

  // Domestic: Ship By Loop EasyPost
  USPS_SLUG,
  FEDEX_SLUG,
  UPS_SLUG,
  AUS_SLUG,
  FEDEX_SMARTPOST_SLUG,
  UPS_MI_SLUG,
  USPS_PLATFORM,
  FEDEX_PLATFORM,
  FEDEX_OWN,
  UPS_OWN,
  AUS_OWN,
  FEDEX_SMARTPOST_OWN,
  UPS_MI_OWN,
  ACCOUNT_TYPE: {
    [USPS_PLATFORM]: 'Platform Account',
    [USPS_SLUG]: 'Platform Account',
    [FEDEX_PLATFORM]: 'Platform Account',
    [FEDEX_OWN]: 'Company Account',
    [UPS_OWN]: 'Company Account',
    [AUS_OWN]: 'Company Account',
    [FEDEX_SMARTPOST_OWN]: 'Company Account',
    [UPS_MI_OWN]: 'Company Account',
  },
  CARRIERS: {
    [USPS_SLUG]: 'USPS',
    [USPS_PLATFORM]: 'USPS',
    [FEDEX_SLUG]: 'FedEx',
    [FEDEX_PLATFORM]: 'FedEx',
    [UPS_SLUG]: 'UPS',
    [UPS_OWN]: 'UPS',
    [AUS_SLUG]: 'Australia Post',
    [AUS_OWN]: 'Australia Post',
    [FEDEX_SMARTPOST_SLUG]: 'FedEx Ground Economy',
    [FEDEX_SMARTPOST_OWN]: 'FedEx Ground Economy',
    [UPS_MI_SLUG]: 'UPS Mail Innovations',
    [UPS_MI_OWN]: 'UPS Mail Innovations',
  },
  FF_CARRIERS_2: [
    AUS_SLUG,
    FEDEX_SMARTPOST_SLUG,
    UPS_MI_SLUG,
  ],
  getCredentialForm(carrier) {
    if (carrier === FEDEX_SLUG) {
      return [
        {
          key: 'accountNumber',
          name: 'Account number',
          type: 'text',
          rules: 'required',
        },
      ];
    } else if (carrier === AUS_SLUG) {
      return [
        {
          key: 'accountNumber',
          name: 'Account number',
          type: 'text',
          rules: 'required',
        },
        {
          key: 'apiKey',
          name: 'API key',
          type: 'text',
          rules: 'required|alpha_num',
        },
        {
          key: 'apiSecret',
          name: 'API secret',
          type: 'text',
          rules: 'required|alpha_num',
        },
        {
          key: 'printAsYouGo',
          name: 'Print as you go',
          type: 'radio',
          options: [
            {
              name: 'Yes',
              value: true,
            },
            {
              name: 'No',
              value: false,
            }
          ],
        },
        {
          key: 'testAccountNumber',
          name: 'Test account number',
          type: 'text',
          rules: 'required',
        },
        {
          key: 'testApiKey',
          name: 'Test API key',
          type: 'text',
          rules: 'required|alpha_num',
        },
        {
          key: 'testApiSecret',
          name: 'Test API secret',
          type: 'text',
          rules: 'required|alpha_num',
        },
        {
          key: 'testPrintAsYouGo',
          name: 'Test print as you go',
          type: 'radio',
          options: [
            {
              name: 'Yes',
              value: true,
            },
            {
              name: 'No',
              value: false,
            }
          ],
        },
      ];
    } else if (carrier === FEDEX_SMARTPOST_SLUG) {
      return [
        {
          key: 'accountNumber',
          name: 'Account number',
          type: 'text',
          rules: 'required',
        },
        {
          key: 'hubId',
          name: 'Hub ID',
          type: 'text',
          rules: 'required',
        },
      ];
    } else if (carrier === UPS_MI_SLUG) {
      return [
        {
          key: 'accountNumber',
          name: 'Account number',
          type: 'text',
          rules: 'required',
        },
        {
          key: 'userId',
          name: 'User ID',
          type: 'text',
          rules: 'required'
        },
        {
          key: 'password',
          name: 'Password',
          type: 'password',
          rules: 'required',
        },
      ];
    } else {
      return [];
    }
  },
  getModuleFromSlug(slug) {
    if (slug === SBL_WORLDWIDE_SLUG) {
      return SBL_WORLDWIDE_STORE_NAME;
    } else {
      return SBL_DOMESTIC_STORE_NAME;
    }
  },
  
};
