import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { Module } from 'vuex';
// @ts-ignore
import request from '@/util/api/customizations';
import {
  getEmails,
  getSchema,
  type NotificationsSchema,
} from '@/util/api/notifications';
import { removeDefaults } from '@/util/helpers/customizations';

const toBoolean = (value: boolean): boolean => {
  if (typeof value === 'string') {
    return value === 'yes';
  }
  return value;
};

const fromBoolean = (value: boolean): string => {
  if (typeof value === 'boolean') {
    return value ? 'yes' : 'no';
  }
  return value;
};

type State = {
  schema: any;
  emails: [],
  disabled: Record<string, any>;
  globalVariables: string[];
  placeholders: Record<string, any>;
  customizations: any;
  newCustomizations: any;
  saving: boolean;
};

const notificationsModule: Module<State, any> = {
  namespaced: true,
  state: {
    schema: null,
    emails: [],
    disabled: {},
    globalVariables: [],
    placeholders: {},
    customizations: {},
    newCustomizations: {},
    saving: false,
  },
  mutations: {
    update(state: any, payload: any): void {
      state.newCustomizations = payload;
    },
    reset(state: any): void {
      state.newCustomizations = {};
    },
    revert(state: any, payload: any): void {
      state.newCustomizations = payload;
    },
    updateDisabled(state: any, { key, value }: { key: any; value: any }): void {
      if (state.disabled[key]) {
        state.disabled[key] = fromBoolean(value);
      } else {
        state.disabled = {
          ...state.disabled,
          [key]: fromBoolean(value),
        };
      }
    },
    setProperty(state: any, { key, value }: { key: any; value: any }): void {
      state[key] = value;
    },
  },
  actions: {
    async getPageData({ commit, state, dispatch }): Promise<void> {
      request.get()
        .then(
          (
            res: {
              customizations: { email: any };
              disabled: any;
            },
          ): void => {
            commit('setProperty', {
              key: 'customizations',
              value: res.customizations.email,
            });
            if (!Array.isArray(res.disabled)) {
              commit('setProperty', {
                key: 'disabled',
                value: res.disabled,
              });
            }
          },
        )
        .then(() => getSchema())
        .then((schema: NotificationsSchema): void => {
          const schemaProperty = merge({}, state.schema, schema);
          delete schemaProperty.placeholders;

          commit('setProperty', {
            key: 'schema',
            value: schemaProperty,
          });
          commit('setProperty', {
            key: 'globalVariables',
            value: Array.from(new Set([...state.globalVariables, ...schema.globalVariables])),
          });
          commit('setProperty', {
            key: 'placeholders',
            value: merge({}, state.placeholders, schema.placeholders),
          });
        }),
      getEmails()
        .then((emails: any): void => {
          commit('setProperty', {
            key: 'emails',
            value: emails,
          });
        });
    },
    async save({ commit, getters }): Promise<boolean> {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'saving', value: true });

      try {
        const res = await request.set({ email: getters.updatedCustomizations });
        commit('setProperty', {
          key: 'customizations',
          value: res.email,
        });
        commit('setProperty', { key: 'saving', value: false });
        commit('setToast', {
          message: 'Customization saved successfully',
          type: 'success',
        }, { root: true });
        return Promise.resolve(true);
      } catch (error) {
        console.error(error);

        commit('reset');
        commit('setProperty', { key: 'saving', value: false });
        commit('setToast', {
          mesasge: `Whoops, we couldn't save this`,
          type: 'error',
        }, { root: true });
        return Promise.resolve(false);
      }
    },
  },
  getters: {
    placeholderData(state, getters, rootState) {
      const { support_email, name } =
        rootState.userData && rootState.userData.shop
          ? rootState.userData.shop
          : { support_email: '', name: '' };
      const { image, colorPrimary } = merge(
        {},
        getters.emailSchema.styles,
        state.newCustomizations.styles,
      );
      const active = {
        font: 'Source Sans Pro',
        image: image || null,
        color_primary: colorPrimary || '#000000',
      };

      const computed = {
        support_email,
        shop_name: name,
        primary: active.color_primary,
        font_stack: active.font,
        image: active.image
          ? `<img src="${active.image}" alt="${name}">`
          : name,
      };

      return Object.assign({}, state.placeholders, computed);
    },
    items(state, getters, rootState) {
      if (!state.emails || !rootState.userData) {
        return [];
      }

      return state.emails
        .map(
          (
            item: {
              customization: string;
              path: string;
              variables: string[];
            },
          ) => {
            const disabled =
              state.disabled[item.path.toLowerCase()] !== undefined
                ? {
                  disabled: toBoolean(
                    state.disabled[item.path.toLowerCase()],
                  ),
                }
                : { disabled: false };
            return {
              ...item,
              template:
                state.schema?.templates?.en[item.customization]?.content || '',
              subject:
                state.schema?.templates?.en[item.customization]?.subject || '',
              variables: [
                ...state.globalVariables,
                ...item.variables,
              ],
              ...disabled,
              name: item.path,
              path: `/settings/notifications/edit/${item.customization}`,
            };
          },
        );
    },
    emailSchema(state) {
      return merge(
        {},
        cloneDeep(state.schema?.emails || {}),
        { groups: cloneDeep(state.schema?.groups || []) },
        cloneDeep(state.customizations),
      );
    },
    updatedCustomizations(state: any) {
      return removeDefaults(state.newCustomizations, {
        ...(state.schema?.emails || {}),
        groups: (state.schema?.groups || [])
      });
    },
    hasChanges(state: any, getters: any) {
      return !isEqual(state.customizations, getters.updatedCustomizations);
    },
  },
};

export default notificationsModule;
