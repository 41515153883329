<template>
  <div
    class="skeleton-list"
    aria-hidden="true"
  >
    <skeleton-text
      class="skeleton-list__heading"
      :lines="1"
      type="heading"
    />
    <base-card class="skeleton-list__card">
      <skeleton-text
        class="skeleton-list__tabs"
        :lines="2"
        type="tabs"
      />
      <skeleton-input class="skeleton-list__filter" />
      <skeleton-text :lines="10" />
    </base-card>
  </div>
</template>

<script>
import SkeletonText from '@/components/skeletons/SkeletonText';
import SkeletonInput from '@/components/skeletons/SkeletonInput';

export default {
  components: {
    SkeletonText,
    SkeletonInput,
  }
};
</script>

<style lang="scss" scoped>
$block: '.skeleton-list';

#{$block} {
  width: 100%;

  &__heading {
    margin: 2rem 0;
  }

  &__card {
    margin-top: 1rem;
  }

  &__tabs {
    margin-bottom: 1rem;
  }

  &__filter {
    margin-bottom: 1rem;
  }
}
</style>
