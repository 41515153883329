export default [
  {
    path: '/discover/',
    component: () => import('@/pages/Discover/Discover'),
    name: 'Discover',
    meta: {
      layout: 'app',
      title: 'Discover',
    }
  }
];
