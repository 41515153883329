<template>
  <section
    class="page-layout"
    :class="[align]"
  >
    <div class="page-layout__content">
      <slot></slot>
    </div>
    <aside
      v-if="$slots.sidebar"
      class="page-layout__sidebar"
    >
      <slot name="sidebar"></slot>
    </aside>
  </section>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      required: false,
      default: 'right',
      validator: value => ['left', 'right'].includes(value)
    }
  }
};
</script>

<style lang="scss">
.page-layout {
  $parent: &;

  display: flex;
  width: 100%;

  &.right {
    flex-direction: row-reverse;

    #{$parent}__sidebar {
      margin-left: 0;
      margin-right: 4rem;
    }
  }

  &__content {
    flex: 1 1 44rem;
    //overflow: hidden;
  }

  &__sidebar {
    flex: 1 1 16rem;
    margin-left: 1.25rem;
  }
}

@media screen and (width <= 960px) {
  .page-layout {
    $parent: &;

    flex-direction: column;

    &.right {
      flex-direction: column-reverse;

      #{$parent}__sidebar {
        margin-right: 0;
        margin-bottom: 1.25rem;
        margin-top: 0;
      }
    }

    &__content {
      width: 100%;
      flex-shrink: 0;
      flex-basis: 0;
    }

    &__sidebar {
      width: 100%;
      flex-shrink: 0;
      flex-basis: 0;
      margin-left: 0;
      margin-top: 1.25rem;
    }
  }
}
</style>
