import env from '@/env';
import Router from '@/router';
import Store from '@/store';
import { ref } from 'vue';

/**
 * Initializes google analytics if the GA key is set
 */

export const gtag = function () {
  window.dataLayer?.push(arguments);
};

export const initGA = () => {
  if (env('VITE_GA_KEY')) {
    // eslint-disable-next-line no-unexpected-multiline
    (function (i, s, o, g, r, a, m) { i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments); }, i[r].l = 1 * new Date(); a = s.createElement(o), m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m); })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    window.dataLayer = window.dataLayer || [];
  } else {
    window.ga = window.ga || function () {
      (ga.q = ga.q || []).push(arguments);
    };
    window.dataLayer = window.dataLayer || [];
  }

  gtag('js', new Date());
  gtag('config', env('VITE_GA_KEY'));
  ga('create', env('VITE_GA_KEY'), 'auto');
};

/**
 * Track an event to google analytics
 * @param {string} event
 * @param {object} fields
 */
export const trackEvent = (event, fields = {}) => {
  const Route = ref(Router);
  const category = Route.value.currentRoute.name ?? '';
  const label = Store.getters.shop.name;
  const shopId = Store.getters.shop.id;
  gtag('event', event, {
    event_category: category,
    event_label: label,
    shop_id: shopId,
    ...fields
  });
};
