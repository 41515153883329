import Customizations from '@/util/api/customizations';

const languages = {
  namespaced: true,
  state: {
    availableLanguages: null,
    shopLanguage: null
  },
  mutations: {
    updateLanguages(state, languages) {
      state.availableLanguages = languages;
    },
    updateShopLanguage(state, language) {
      state.shopLanguage = language;
    }
  },
  actions: {
    async get({ commit, state }) {
      if (state.shopLanguage) {
        return {
          lang: state.shopLanguage,
          languages: state.availableLanguages
        };
      }

      try {
        const { customizations } = await Customizations.get();
        const { lang, content } = customizations;
        const languages = Object.keys(content);

        commit('updateShopLanguage', lang);
        commit('updateLanguages', languages);

        return {
          lang,
          languages
        };
      } catch (error) {
        console.error(error);
      }
    }
  }
};

export default languages;
