import Store from '@/store';
import SkeletonList from '@/components/skeletons/SkeletonList';
import SkeletonDetail from '@/components/skeletons/SkeletonDetail';
import { shopSettings } from '@/util/schemas/ShopSettings';

export default [
  {
    path: '/returns/',
    component: () => import('@/pages/Returns/ReturnsList'),
    name: 'ReturnsList',
    props: route => Object.assign({}, route.query, route.params),
    meta: {
      layout: 'app',
      layoutType: 'full',
      title: 'Returns',
      skeleton: () => SkeletonList,
    }
  },
  {
    path: '/returns/warranties',
    component: () => import('@/pages/Returns/ReturnsList'),
    name: 'WarrantyList',
    props: route => Object.assign({ isWarranty: true }, route.query, route.params),
    meta: {
      layout: 'app',
      layoutType: 'full',
      title: 'Warranty Returns',
      skeleton: () => SkeletonList,
    },
    beforeEnter: (to, from, next) => {
      if (!Store.getters.getSetting('WARRANTIES_ENABLED')) {
        next('/returns');
      } else {
        next();
      }
    },
  },
  {
    path: '/returns/feed',
    component: () => import('@/pages/Returns/ReturnsFeed'),
    name: 'ReturnsFeed',
    meta: {
      layout: 'app',
      title: 'Return events feed',
      loader: false
    }
  },
  {
    path: '/returns/find-order',
    component: () => import('@/pages/Returns/ReturnsFindOrder'),
    name: 'ReturnsFindOrder',
    meta: {
      layout: 'app',
      title: 'Find an order',
      skeleton: () => SkeletonList,
    }
  },
  {
    path: '/returns/:id(\\d+)',
    component: () => import('@/pages/Returns/ReturnView'),
    name: 'ReturnDetail',
    meta: {
      layout: 'app',
      title: 'Returns',
      skeleton: () => SkeletonDetail,
      action: 'returns/detail/getPageData'
    }
  },
  {
    path: '/returns/warranties/:id(\\d+)',
    component: () => import('@/pages/Returns/ReturnView'),
    name: 'WarrantyClaimDetails',
    meta: {
      layout: 'app',
      title: 'Warranty',
      skeleton: () => SkeletonDetail,
      action: 'returns/detail/getPageData'
    },
    beforeEnter: (to, from, next) => {
      if (!Store.getters.getSetting('WARRANTIES_ENABLED')) {
        next('/returns');
      } else {
        next();
      }
    }
  },
  {
    path: '/returns/:id(\\d+)/line-item/:id(\\d+)',
    component: () => import('@/pages/LineItemGradingDisposition/LineItemGradingDispositionView'),
    name: 'LineItemGradingAndDisposition',
    meta: {
      layout: 'app',
      title: 'Line Item',
      skeleton: () => SkeletonDetail
    },
    beforeEnter: (to, from, next) => {
      if (
        !Store.getters.getSetting(shopSettings.LINE_ITEM_GRADING_AND_DISPOSITION) ||
        !Store.getters['returns/detail/lineItemData'](to.params.id)
      ) {
        next(`/returns/${to.fullPath.split('/')[2]}`);
      }
      next();
    },
  }
];
