import { isEqual, isObject, mergeWith, isArray } from 'lodash';

// Takes an object and an object of defaults and removes any keys where the value is a default
export const removeDefaults = (obj, defaults) => {
  return Object.entries(obj).reduce((accumulator, [key, value]) => {
    let newValue = {};

    if (isObject(value) && (value.type && value.type === 'doc')) {
      // Wysiwyg docs
      newValue = !isEqual(defaults?.[key], value) ? { [key]: value } : {};
    } else if (Array.isArray(value)) {
      newValue = !isEqual(value, defaults?.[key]) ? { [key]: value } : {};
    } else if (isObject(value)) {
      const removed = removeDefaults(value, defaults?.[key]);
      newValue = removed && !!Object.entries(removed).length ? { [key]: removed } : {};
    } else {
      const defaultValue = defaults ? defaults?.[key] : null;
      newValue = value !== defaultValue ? { [key]: value } : {};
    }
    return {
      ...accumulator,
      ...newValue
    };
  }, {});
};

var stripEscapes = (str) =>{
  str = str.replace(/\\/g, '');
  return str;
};

export const stripObjectEscapes = (obj) =>{
  Object.keys(obj).forEach((section)=>{
    Object.keys(obj[section]).forEach((item)=>{
      if (typeof obj[section][item] === 'string') {
        obj[section][item] = stripEscapes(obj[section][item]);
      }
    });
  });
  return obj;
};

/**
 * Calculate actual text length from a tiptap doc object
 *
 * @param {Object} doc - a tiptap doc
 */
export const calculateWysiwygLength = (doc) => {
  // eslint-disable-next-line no-prototype-builtins
  if (isObject(doc) && doc.hasOwnProperty('type') && doc.hasOwnProperty('content')) {
    return doc.content.reduce((accumulator, current) => {
      if (current.text) {
        return accumulator + current.text.length;
      }

      return accumulator + calculateWysiwygLength(current);
    }, 0);
  }

  return 0;
};

/**
 * Takes two objects and merges them deeply
 * @param {object} first
 * @param {object} second
 * @returns {object} - The two objects merged
 */
export const mergeDeep = (first, second) => {
  return mergeWith({}, first, second, (obj, src) => {
    if (isArray(obj)) {
      return src;
    }
  });
};
