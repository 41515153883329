import env from '@/env';

const loadFrontLib = async () => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.setAttribute('src', 'https://chat-assets.frontapp.com/v1/chat.bundle.js');
  script.addEventListener('load', resolve);
  script.addEventListener('error', reject);
  document.body.append(script);
});

export const initFrontChat = async () => {
  await loadFrontLib();
  window.FrontChat('init', { chatId: env('VITE_FRONTCHAT_KEY'), useDefaultLauncher: true });
};
