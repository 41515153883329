/**
 * Request and cache reasons and reasons related data. The data here should mirror the API and be updated as updates are made.
 */
import Reasons from '@/util/api/reasons';
import Products from '@/util/api/products';
import addOrUpdate from '@/util/helpers/addOrUpdate';

const mapType = (types, group) => {
  return {
    ...group,
    types: group.types.map((typeId) => {
      return types.find((type) => type.id === typeId || type.id === typeId.id) || null;
    })
      .filter(Boolean)
  };
};

export default {
  namespaced: true,
  state: {
    library: [],
    groups: [],
    productTypes: [],
    language: 'en',
  },
  mutations: {
    setLibrary(state, library) {
      state.library = library;
    },
    setProductTypes(state, productTypes) {
      state.productTypes = productTypes;
    },
    setGroups(state, groups) {
      state.groups = groups;
    },
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    /**
         * Get reasons data. Will return a cached value if it has one
         * @param {object} opts
         * @param {boolean} opts.forceUpdate - Force the store to re-request the reasons
         * @returns {Promise}
         */
    async getLibrary({ state, commit }, opts) {
      if (!state.library.length || opts?.forceUpdate) {
        const library = await Reasons.get(state.language);
        commit('setLibrary', library);
      }

      return Promise.resolve(state.library);
    },
    /**
         * Get product type data. Will return a cached value if it has one
         * @param {object} opts
         * @param {boolean} opts.forceUpdate - Force the store to re-request the product types
         * @returns {Promise}
         */
    async getProductTypes({ state, commit }, opts) {
      if (!state.productTypes.length || opts?.forceUpdate) {
        const productTypes = await Products.getTypes();
        commit('setProductTypes', productTypes);
      }

      return Promise.resolve(state.productTypes);
    },
    /**
         * Get groups data. Will return a cached value if it has one
         * @param {object} opts
         * @param {boolean} opts.forceUpdate - Force the store to re-request the groups
         * @returns {Promise}
         */
    async getGroups({ state, commit, dispatch }, opts) {
      if (!state.groups.length || opts?.forceUpdate) {
        const [groups, types] = await Promise.all([
          Reasons.groups.get(),
          dispatch('getProductTypes')
        ]);
        commit('setGroups', groups.map((group) => mapType(types, group)));
      }

      return Promise.resolve(state.groups);
    },
    /**
         * Get a single group. Will return a cached value if it has one
         * @param {object} opts
         * @param {boolean} opts.id - The ID of the group to get
         * @returns {Promise}
         */
    async getGroup({ dispatch }, { id }) {
      const groups = await dispatch('getGroups');
      return Promise.resolve(groups.find((group) => group.id === id));
    },
    async setLibrary({ commit }, reasons) {
      const library = await Reasons.save(reasons);
      commit('setLibrary', library);

      return Promise.resolve(library);
    },
    async setGroup({ commit, state }, group) {
      const action = group.id ? 'update' : 'add';
      const updated = await Reasons.groups[action](group);
      const withTypes = mapType(state.productTypes, updated);
      commit('setGroups', addOrUpdate(state.groups, withTypes));

      return Promise.resolve(withTypes);
    },
    async removeGroup({ commit, state }, group) {
      try {
        await Reasons.groups.remove(group);

        commit('setGroups', state.groups
          .filter((item) => {
            return item.id !== group.id;
          })
        );
        return Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }

    },
    async updateReason({ commit, state }, updated) {
      const { parentId, ...reason } = updated;
      if (parentId) {
        const reasons = state.library.map((item) => {
          if (item.id === parentId) {
            return {
              ...item,
              children: addOrUpdate(item.children ?? [], reason)
            };
          }

          return item;
        });
        const library = await Reasons.save(reasons);
        const newItem = findNewItem(
          getChildren(state.library, parentId),
          getChildren(library, parentId)
        );
        commit('setLibrary', library);

        return Promise.resolve([{ ...newItem, parentId }, library]);
      }

      const reasons = addOrUpdate(state.library, reason);
      const library = await Reasons.save(reasons);
      const newItem = findNewItem(state.library, library);
      commit('setLibrary', library);

      return Promise.resolve([newItem, library]);
    }
  },
  getters: {}
};

const findNewItem = (oldList, newList) => {
  const filtered = newList.filter((item) => {
    return !oldList.find((i) => i.id === item.id);
  });
  return filtered.length ? filtered[0] : null;
};

const getChildren = (library, parentId) => {
  const parent = library.find((item) => item.id === parentId);
  return parent?.children || [];
};
