import axios from 'axios';
import { createId } from '@/util/helpers/ids';
import { replaceWithQueryString } from '@/util/helpers/urls';

// info is an object that sets type and group
// items is an array of keys that will be what we set as names
const shopnow = function () {
  // This is what the batch endpoint expects
  /* const requestTemplate = {
        // id: 1,
        //type: '',
        //group: '',
        //name: '',
        //content: ''
    } */
};

// Mapping function to take api output and convert to
// format that Vue likes.
const mapFromApi = (items) => {
  const parents = items.filter(item => item.parent_id <= 0);
  const children = items.filter(item => item.parent_id > 0);
  const toReturn = parents.length ? parents : items;

  return toReturn
    .map(item => {
      const options = children.filter(child => child.parent_id === item.id);
      return {
        id: createId('v-'),
        active: false,
        collection: item,
        title: item.tab_name,
        order: item.tab_order,
        options: options ? mapFromApi(options) : []
      };
    })
    .sort((a, b) => a.order - b.order);
};

// Mapping function to take vue output and convert to
// format that the api likes
const mapToApi = (items) => {
  const fakeCollection = {
    id: 'new',
    title: 'Menu Item',
    sort_order: 'best-selling'
  };
  return items.map(item => {
    let collection = fakeCollection;
    if (item.collection) {
      // eslint-disable-next-line no-unused-vars
      const { body_html, ...restOfCollection } = item.collection;
      collection = restOfCollection;
    }

    return {
      ...collection,
      tab_name: item.title,
      tab_order: item.order,
      parent_id: item.parent ? item.parent : 0,
      children: item.options ? mapToApi(item.options) : []
    };
  });
};

shopnow.prototype = {
  get() {
    return new Promise((resolve, reject) => {
      axios.get('storefront')
        .then(res => {
          resolve({
            data: {
              ...res.data,
              selected: mapFromApi(res.data.selected)
            }
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // GET
  filter(options = {}) {
    return new Promise((resolve, reject) => {
      axios.post('storefront/filter', options)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  import() {
    return new Promise((resolve, reject) => {
      axios.get('storefront/collections/import')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // SET
  save(items) {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(items)) {
        console.error('API: shopnow.save() requires an array of shopnow objects as an input.');
        reject();
      }

      axios.post('storefront/batch', mapToApi(items))
        .then(res => {
          resolve({
            data: mapFromApi(res.data)
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  oauth(link) {
    const fullUrl = link.includes('shipbob') ? replaceWithQueryString(link) : link;
    return new Promise((resolve, reject) => {
      axios.get(fullUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // DELETE
  /* remove(item) {
        return new Promise((resolve, reject) => {
            if (!setting.id) {
                console.error('API: shopnow.remove() requires an object that includes an id property.');
                reject();
            }

            axios.delete('customization/' + setting.id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    } */
};

export default shopnow;
