export default {
  namespaced: true,
  state: {
    text: {
    },
    customizations: window.customizations || [],
    notification: ''
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  }
};
