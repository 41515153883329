import ListingApi from '@/util/api/listing';

const listingRequest = new ListingApi({
  per_page: window.pageData && window.pageData.filters && window.pageData.filters.per_page || 25
});

export default {
  namespaced: true,
  state: {
    text: {
    },
    updating: false,
    saving: false,
    deleting: false,
    blocklists: window.pageData && window.pageData.listings || [],
    allowlists: [],
    type: 'blocklists', // ['blocklists', 'allowlists']
    page: window.pageData && window.pageData.filters && window.pageData.filters.page || 0,
    pageAmount: window.pageData && window.pageData.filters && window.pageData.filters.per_page || 25,
    maxPages: {
      blocklists: 0,
      allowlists: 0
    },
    hasItems: false,
    searches: {
      blocklists: null,
      allowlists: null
    }
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    setMaxPages(state, { key, value }) {
      state.maxPages[key] = value;
    },
    saveListing(state, { type, listing, listings }) {
      const newListings = listing ? [listing] : listings;
      const updated = listingRequest.updateExisting(state[type], newListings);

      // Adding this has pushed us into adding a new page
      if (updated.length > state.pageAmount && state.maxPages[type] === state.page) {
        state.maxPages[type] = state.maxPages[type] + 1;
      }

      // Trim current page to be the coorect amount
      state[type] = updated.slice(0, state.pageAmount);
    },
    removeListing(state, { type, listing }) {
      state[type] = listingRequest.removeExisting(state[type], [listing]);
    },
    saveSearch(state, { search, type }) {
      state.searches[type] = search.length ? search : null;
    }
  },
  actions: {
    getPageData({ dispatch }) {
      return dispatch('getList');
    },
    getList({ state, commit }, options = {}) {
      const meta = options.meta || {};
      const type = meta.type || state.type;
      if (!meta.silent) {
        // Set saving state, this lets us update the page to show spinners, etc
        commit('setProperty', { key: 'updating', value: true });
      }

      return new Promise((resolve, reject) => {
        listingRequest.getList(type, options)
          .then(res => {
            commit('saveSearch', { type, search: options.search || '' });

            if (res.data.listings.length > 0) {
              commit('setProperty', { key: 'hasItems', value: true });
              commit('setProperty', { key: type, value: res.data.listings });
              if (!meta.silent) {
                commit('setProperty', { key: 'page', value: res.data.filters.page });
              }
            } else {
              commit('setProperty', { key: type, value: res.data.listings });
              commit('setMaxPages', { key: type, value: state.page });
            }
            if (!meta.silent) {
              commit('setProperty', { key: 'updating', value: false });
            }
            resolve(res);
          })
          .catch((err) => {
            if (!meta.silent) {
              commit('setProperty', { key: 'updating', value: false });
            }
            reject(err);
          });
      });
    },
    save({ commit }, { type, listing }) {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'saving', value: true });

      listingRequest.save(type, listing)
        .then(res => {
          if (res.data.errors) {
            throw res.data.errors;
          }
          commit('saveListing', { type, listing: res.data });
          commit('setProperty', { key: 'saving', value: false });
          commit('setToast', {
            message: 'Listing saved successfully',
            type: 'success',
          }, { root: true });
        })
        .catch(error => {
          const errorMsg = error[0] || '';
          commit('setProperty', { key: 'saving', value: false });
          if (errorMsg.includes('duplicate')) {
            commit('setToast', {
              message: 'Whoops, this entry already exists',
              type: 'error',
            }, { root: true });
          } else {
            commit('setToast', {
              message: `Whoops, we couldn't save this listing`,
              type: 'error',
            }, { root: true });
          }
        });
    },
    saveBulk({ commit }, { type, input }) {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'saving', value: true });

      listingRequest.saveBulk(type, { input })
        .then(res => {
          if (res.data.errors) {
            throw res.data.errors;
          }
          commit('saveListing', { type, listings: res.data });
          commit('setProperty', { key: 'saving', value: false });
          commit('setToast', {
            message: 'Listing saved successfully',
            type: 'success',
          }, { root: true });
        })
        .catch(() => {
          commit('setProperty', { key: 'saving', value: false });
          commit('setToast', {
            message: `Whoops, we couldn't save this listing`,
            type: 'error',
          }, { root: true });
        });
    },
    remove({ state, commit, dispatch }, { type, listing }) {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'deleting', value: listing.id });

      listingRequest.remove(type, listing)
        .then(() => {
          commit('removeListing', { type, listing });
          commit('setProperty', { key: 'deleting', value: false });
          commit('setToast', {
            message: `Listing "${listing.value}" deleted`,
            type: 'success',
          }, { root: true });

          // If we've deleted the last item on this page
          if (state[type].length < 1 && state.page > 1) {
            dispatch('getList', { page: state.page - 1 });
            commit('setMaxPages', { key: type, value: state.page - 1 });
          }
        })
        .catch(() => {
          commit('setProperty', { key: 'deleting', value: false });
          commit('setToast', {
            message: `Whoops, we couldn't delete this listing`,
            type: 'error',
          }, { root: true });
        });
    }
  },
  getters: {
    getPagination(state) {
      return {
        page: state.page,
        previous: {
          disabled: state.page === 1
        },
        next: {
          disabled: state[state.type].length < state.pageAmount || state.maxPages[state.type] === state.page
        }
      };
    }
  }
};
