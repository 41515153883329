import Cookies from 'js-cookie';
import Echo from 'laravel-echo';
import store from '@/store';
import env from '@/env';
import { websocketEvents } from '@/util/schemas/websocketEvents';
import Pusher from 'pusher-js';
import { ldFlagVariation } from '@/util/lib/launchDarkly';
import { featureFlags } from '@/util/schemas/featureFlags';

if (await ldFlagVariation(featureFlags.AUTH0_SESSIONS_ENABLED)) {
  // Only enable withCredentials if sessions are enabled
  Pusher.Runtime.createXHR = function () {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    return xhr;
  };
}

window.Pusher = Pusher;

let client = null;
const channels = {
  user: {},
  shop: {},
  loop: {},
};

const getClient = () => {
  if (client) {
    return client;
  }

  client = new Echo({
    broadcaster: 'pusher',
    key: env('VITE_PUSHER_APP_KEY'),
    cluster: env('VITE_PUSHER_APP_CLUSTER'),
    forceTLS: true,
    authEndpoint: `${env('VITE_API_URL')}/api/admin/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${Cookies.get('loop-admin-token')}`,
        'Accept': 'application/json'
      }
    },
  });

  return client;
};

const getUserChannel = (userId) => {
  if (channels.user[userId]) {
    return channels.user[userId];
  }

  const channel = getClient()
    .private(`App.Models.Access.User.${userId}`);

  return channel;
};

const getLoopChannel = (returnId) => {
  if (channels.loop[returnId]) {
    return channels.loop[returnId];
  }

  const channel = getClient()
    .private(`App.Models.Loop.${returnId}`);


  return channel;
};

const getShopChannel = (shopId) => {
  if (channels.shop[shopId]) {
    return channels.shop[shopId];
  }

  const channel = getClient()
    .private(`App.Models.Shop.${shopId}`);

  return channel;
};

/**
 * Join a presence channel
 * @param {string} name - The channel to join
 * @returns {Echo} Echo channel instance
 */
export const joinChannel = (name) => {
  if (channels[name]) {
    return channels[name];
  }

  const channel = getClient()
    .join(name);

  return channel;
};

/**
 * Leave a channel
 * @param {string} name - The channel to leave
 */
export const leaveChannel = (name, parentName) => {
  if (!!parentName && channels[parentName] && channels[parentName][name]) {
    channels[parentName][name] = undefined;
  }

  if (channels[name]) {
    channels[name] = undefined;
  }

  getClient().leave(name);
};

export const listen = ({ userId, shopId, returnId }, eventName, callback) => {
  let method = null;
  let id = null;

  if (returnId) {
    method = getLoopChannel;
    id = returnId;
  } else {
    method = userId ? getUserChannel : getShopChannel;
    id = userId ?? shopId;
  }

  method(id)
    .listen(eventName, (e) => {
      callback(e);
    });

  return this;
};

/**
 * Initializes notifications via Pusher if the pusher env variables are set
 * @param {User} user
 */
export const initNotifications = (user) => {
  if (env('VITE_PUSHER_APP_KEY') && env('VITE_PUSHER_APP_CLUSTER')) {
    // Listen for user websocket events.
    listen({ userId: user.id }, websocketEvents.COLLECTIONS_IMPORTED, () => {
      store.commit('setToast', {
        message: 'Collections imported!',
        type: 'success',
      });
    });
    listen({ userId: user.id }, websocketEvents.BUNDLES_IMPORTED, () => {
      store.commit('setToast', {
        message: 'Bundles imported!',
        type: 'success',
      });
    });
    listen({ userId: user.id }, websocketEvents.BUNDLES_IMPORT_FAILED, () => {
      store.commit('setToast', {
        message: 'Data in the CSV is missing or incompatible. Please review your CSV for formatting errors and try again.',
        type: 'warning',
      });
    });
    listen({ shopId: user.shop.id }, websocketEvents.RETURN_EVENT_CREATED, ({ event }) => {
      store.commit('returns/feed/setUpdate', event);
    });
  } else {
    console.warn('Unable to initialize notifications. Make sure you have your pusher ENV variables set correctly.');
  }
};
