export default {
  namespaced: true,
  state: {
    text: {
    },
    saving: false,
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    }
  },
  actions: {
    save({ commit }) {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'saving', value: true });
    }
  },
  getters: {
  }
};
