import axios from 'axios';

const alloyApi = {
  async getAlloyToken() {
    try {
      const response = await axios.get('/alloy/token');
      return response.data?.token;
    } catch (error) {
      return error;
    }
  }
};

export default alloyApi;
