import SkeletonLayout from '@/components/skeletons/SkeletonLayout';
import SkeletonDetail from '@/components/skeletons/SkeletonDetail';

export default [
  {
    path: '/advanced-exchanges/',
    component: () => import('@/pages/AdvancedExchanges/ExchangesList'),
    name: 'exchangesList',
    meta: {
      layout: 'app',
      title: 'Advanced Exchanges',
      skeleton: () => SkeletonLayout,
      action: 'advancedExchanges/getPageData'
    }
  },
  {
    path: '/advanced-exchanges/new',
    component: () => import('@/pages/AdvancedExchanges/ExchangesItem'),
    name: 'exchangesAdd',
    meta: {
      layout: 'app',
      title: 'Add Exchange',
      skeleton: () => SkeletonDetail,
      action: 'advancedExchanges/getPageData'
    }
  },
  {
    path: '/advanced-exchanges/edit/:id',
    component: () => import('@/pages/AdvancedExchanges/ExchangesItem'),
    name: 'exchangesEdit',
    meta: {
      layout: 'app',
      title: 'Edit Exchange',
      skeleton: () => SkeletonDetail,
      action: 'advancedExchanges/getPageData'
    }
  }
];
