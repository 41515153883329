import env from '@/env';
import store from '@/store';
import { cleanupUser } from '@/util/helpers/users';
import { auth0 } from '@/util/auth0';
import { ldFlagVariation } from '@/util/lib/launchDarkly';
import { featureFlags } from '@/util/schemas/featureFlags';
import Cookies from 'js-cookie';

export default [
  {
    path: '/login/',
    component: () => import('@/pages/Auth/LoginPage.vue'),
    name: 'Login',
    meta: {
      title: 'Login',
      layout: 'none',
      loader: false,
      user: false
    },
    //check if the lookup page is the default login page
    async beforeEnter() {
      if (env('VITE_MODE') !== 'test') {
        if (await ldFlagVariation(featureFlags.AUTH0_SESSIONS_ENABLED)) {
          // ensure the cookie used to bypass auth for install is removed
          Cookies.remove('loop-install-cookie');

          window.location.href = `${env('VITE_API_URL')}/login`;

          return false; // cancel the navigation on window redirect
        }

        return ({ name: 'SSO' });
      }
    }
  },
  {
    path: '/switch-shop',
    name: 'SwitchShop',
    meta: {
      title: 'Switch Shop',
      layout: 'none',
      loader: false,
      user: false,
    },
    beforeEnter() {
      window.location.href = `${env('VITE_API_URL')}/api/admin/authenticate/sso/switch-shop`;

      return false; // cancel the navigation on redirect
    }
  },
  {
    path: '/login/sso',
    component: () => import('@/pages/Auth/LoginPage.vue'),
    name: 'SSO',
    meta: {
      title: 'Login',
      layout: 'none',
      loader: false,
      user: false,
    },
    async beforeEnter() {
      // todo this route should be removable when AUTH0_SESSIONS_ENABLED enabled for all.
      if (await ldFlagVariation(featureFlags.AUTH0_SESSIONS_ENABLED)) {
        return ({ name: 'Login' });
      }
    }
  },
  {
    // todo I think we can remove this
    path: '/login/oauth',
    component: () => import('@/pages/Auth/Auth0LoginPage.vue'),
    name: 'OAuth',
    meta: {
      title: 'Login',
      layout: 'none',
      loader: false,
      user: false,
    },
    async beforeEnter() {
      // todo this route should be removable when AUTH0_SESSIONS_ENABLED enabled for all.
      if (await ldFlagVariation(featureFlags.AUTH0_SESSIONS_ENABLED)) {
        return ({ name: 'Login' });
      }
    }
  },
  {
    // todo I think we can remove this
    path: '/oauth/callback',
    component: () => import('@/pages/States/LoadingPage.vue'),
    name: 'Login Callback',
    meta: {
      title: 'Login Callback',
      layout: 'none',
      loader: false,
      user: false
    },
    beforeEnter(to) {
      //cancel the login if there is an error
      if (to.query.error) {
        store.commit('setOauthError', to.query.error);
        return ({ name: 'Login Error' });
      }
    }
  },
  {
    path: '/oauth/error',
    component: () => import('@/pages/Auth/Auth0ErrorPage.vue'),
    name: 'Login Error',
    meta: {
      title: 'Login Error',
      layout: 'none',
      loader: false,
      user: false
    },
  },
  {
    path: '/logout/',
    name: 'Logout',
    meta: {
      layout: 'app'
    },
    component: () => import('@/pages/States/LoadingPage.vue'),
    async beforeEnter() {
      cleanupUser();

      if (await ldFlagVariation(featureFlags.AUTH0_SESSIONS_ENABLED)) {
        window.location.href = `${env('VITE_API_URL')}/logout`;

        return false; // cancel the navigation on window redirect
      } else {
        await auth0.logout({
          logoutParams: {
            returnTo: `${env('VITE_ADMIN_ORIGINS')}/login`
          }
        });
      }
    }
  },
  {
    path: '/password/reset/:token',
    component: () => import('@/pages/Auth/PasswordReset.vue'),
    name: 'PasswordReset',
    meta: {
      title: 'Password Reset',
      layout: 'none',
      loader: true,
      user: false
    }
  },
  {
    path: '/sso/authorize',
    component: () => import('@/pages/Auth/LoginPage.vue'),
    name: 'SSO Login',
    meta: {
      title: 'SSO Login',
      layout: 'none',
      loader: false,
      user: false
    }
  },
  {
    path: '/shops',
    component: () => import('@/pages/Auth/InstanceSelector.vue'),
    name: 'InstanceSelector',
    meta: {
      title: 'Choose your Shop',
      layout: 'none',
      loader: false,
      user: false,
    }
  },
];
