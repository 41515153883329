import { default as axios, type AxiosResponse } from 'axios';
import changeCase from 'change-object-case';
import { BillingAddress } from '@/store/modules/settings/shipByLoop/worldwide';
import { Credit } from '@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/Types';

export interface ShipByLoopWorldwideConfig {
  id?: string;
  name: string;
  email: string;
  stripePublicKey?: string;
  primaryPaymentMethod?: any;
  paymentSourceId?: string;
  hasSelectedCarriers?: boolean;
  defaultCategory?: string;
}

interface CreateCarrierShopRequest {
  carrierId: string|number,
  carrier: string,
  formData: object;
}

interface RechargeResponse {
  success: 'true'|'false';
  credit: Credit;
}

export type PartialShipByLoopWorldwideConfig = Partial<ShipByLoopWorldwideConfig>;

export default {
  getConfig(shippingSettingId: string): Promise<AxiosResponse<ShipByLoopWorldwideConfig>> {
    return axios.get(`/shipping/integrations/ship-by-loop-worldwide/configs/${shippingSettingId}`);
  },

  postConfig(data: ShipByLoopWorldwideConfig): Promise<AxiosResponse<ShipByLoopWorldwideConfig>> {
    return axios.post('/shipping/integrations/ship-by-loop-worldwide/configs', data);
  },

  patchConfig(shippingSettingId: string, data: PartialShipByLoopWorldwideConfig): Promise<AxiosResponse<ShipByLoopWorldwideConfig>> {
    return axios.patch(`/shipping/integrations/ship-by-loop-worldwide/configs/${shippingSettingId}`, data);
  },

  deleteConfig(shippingSettingId: string) {
    return axios.delete(`/shipping/integrations/ship-by-loop-worldwide/configs/${shippingSettingId}`);
  },

  updateBillingAddress(shippingSettingId: string, data: BillingAddress) {
    return axios.post(`/shipping/integrations/ship-by-loop-worldwide/address/${shippingSettingId}`, data);
  },

  recharge(shippingSettingId: string, data: { paymentSourceId: string, amount: number }): Promise<AxiosResponse<RechargeResponse>> {
    return axios.post(`/shipping/integrations/ship-by-loop-worldwide/${shippingSettingId}/credit/add`, changeCase.toSnake(data));
  },

  getCarrierAccounts(): Promise<AxiosResponse> {
    return axios.get(`/shipping/integrations/ship-by-loop-worldwide/carrier-shops`);
  },

  addCarrierAccount(data: CreateCarrierShopRequest): Promise<AxiosResponse> {
    return axios.post(`/shipping/integrations/ship-by-loop-worldwide/carrier-shops`, data);
  },

  deleteCarrierAccount(carrierShop: string): Promise<AxiosResponse> {
    return axios.delete(`/shipping/integrations/ship-by-loop-worldwide/carrier-shops/${carrierShop}`);
  },

  getCarriers(integrationId: string): Promise<AxiosResponse> {
    return axios.get(`/shipping/integrations/${integrationId}/carriers`);
  },

  confirmRecharge(shippingSettingId: string, data: { intentId: string }): Promise<AxiosResponse<RechargeResponse>> {
    return axios.post(`/shipping/integrations/ship-by-loop-worldwide/${shippingSettingId}/credit/confirm`, changeCase.toSnake(data));
  },

  getPendingCarrierAccounts(): Promise<AxiosResponse> {
    return axios.get(`/shipping/integrations/ship-by-loop-worldwide/pending-accounts`);
  },
};
