import _ from 'lodash';
import Accounts from '@/util/api/account';
import router from '@/router';

const defaultNew = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  roles: [],
  job_title: '',
};
const defaultEdit = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  roles: [],
  job_title: '',
};

export default {
  namespaced: true,
  state: {
    users: [],
    newUser: _.clone(defaultNew),
    editUser: _.clone(defaultEdit),
    currentUser: _.clone(defaultEdit),
    saving: false,
    deleting: false
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    setUser(state, user) {
      state.editUser = Object.assign({}, defaultEdit, user);
      state.currentUser = Object.assign({}, defaultEdit, user);
    },
    updateUser(state, { type, key, value }) {
      state[type][key] = value;
    },
    resetUser(state, { type, user }) {
      if (type === 'newUser') {
        state.newUser = _.clone(defaultNew);
      }
      if (type === 'editUser') {
        state.currentUser = _.clone(user);
        state.editUser = _.clone(user);
      }
    },
    saveUser(state, user) {
      state.users = Accounts.updateExisting(state.users, [user]);
    },
    removeUser(state, user) {
      state.users = Accounts.removeExisting(state.users, [user]);
    },
  },
  actions: {
    async getPageData({ commit }/* , route */) {
      const { users } = await Accounts.get();
      commit('setProperty', {
        key: 'users',
        value: users
      });
    },
    async getUserData({ commit, dispatch }, route) {
      const data = await Accounts.getUser({ id: +route.params.id });
      commit('setUser', data);
      dispatch('getPageData');
    },
    async save({ state, commit }, { type }) {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'saving', value: true });

      try {
        const res = await Accounts.save(state[type]);
        if (res.errors) {
          throw res.errors;
        }
        const messaging = type === 'newUser' ? 'created' : 'saved';

        // api returns a user
        commit('saveUser', res);
        commit('resetUser', { type, user: res });
        commit('setProperty', { key: 'saving', value: false });
        commit('setToast', {
          message: `Account ${messaging} successfully`,
          type: 'success',
        }, { root: true });

        if (type === 'newUser') {
          router.push(`/settings/account`);
        }
      } catch (error) {
        const errors = Object.values(error.response?.data?.errors ?? {}).flat();
        const message = Array.isArray(errors) && errors.length ? errors.join(', ') : `Whoops, we couldn't save this user`;
        commit('setProperty', { key: 'saving', value: false });
        commit('setToast', {
          message,
          type: 'error',
        }, { root: true });
      }
    },
    async remove({ commit }, user) {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'deleting', value: true });

      try {
        const res = await Accounts.remove(user);

        // api returns a user
        commit('removeUser', res);
        commit('resetUser', { type: 'editUser', user: _.clone(defaultEdit) });
        commit('setProperty', { key: 'deleting', value: false });
        commit('setToast', {
          message: `Account "${res.name}" deleted`,
          type: 'success',
        }, { root: true });
        router.push('/settings/account');
      } catch (error) {
        console.error(error);
        commit('setProperty', { key: 'deleting', value: false });
        commit('setToast', {
          message: `Whoops, we couldn't save this user`,
          type: 'error',
        }, { root: true });
      }
    },
    resendInvite({ commit }, user) {
      try {
        Accounts.resendInvite(user);
        commit('setToast', {
          message: `Invite resent to ${user.email}`,
          type: 'success',
        }, { root: true });
      } catch (error) {
        console.error(error);
        commit('setToast', {
          message: `Whoops, we couldn't resend this invite`,
          type: 'error',
        }, { root: true });
      }
    }
  },
  getters: {
    hasChanges(state) {
      const newUserUpdated = Object.values(state.newUser).some(item => item.length > 0);
      const editUserUpdated = Object.entries(state.editUser).some(([key, value]) => state.currentUser[key] !== value);
      return newUserUpdated || editUserUpdated;
    },
  }
};
