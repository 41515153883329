import { operators, rangeOperators, dateOperators } from '@/util/schemas/workflow-rules';
import store from '@/store';
import utils from '@/util/mixins/adminUtil';
import { format } from 'date-fns';
import { conditions } from '@/pages/Workflows/WorkflowsDetail/conditions';

export const formatForDisplay = (value, shopCurrency) => {
  return utils.methods.money(
    utils.methods.simplifyMoneyFromUserInput(value ? value : 0),
    shopCurrency
  );
};

const getDisplayForTotals = (operator, value) => {
  const currency = store.getters.shop.currency;
  const minValue = formatForDisplay(value?.min, currency);

  if (operator === operators.GREATER_THAN) {
    return `greater than ${minValue}`;
  }
  if (operator === operators.LESS_THAN) {
    return `less than ${minValue}`;
  }
  if (operator === operators.BETWEEN) {
    const maxValue = formatForDisplay(value?.max, currency);
    return `between ${minValue} - ${maxValue}`;
  }
};

const getDisplayForPercentage = (operator, value) => {
  if (operator === operators.GREATER_THAN) {
    return `greater than ${value?.min ?? 0}%`;
  }
  if (operator === operators.LESS_THAN) {
    return `less than ${value?.min ?? 0}%`;
  }
  if (operator === operators.BETWEEN) {
    return `between ${value?.min ?? 0}% - ${value?.max ?? 0}%`;
  }
};

const getDisplayForNumber = (operator, value) => {
  if (operator === operators.GREATER_THAN) {
    return `greater than ${value?.min ?? 0}`;
  }
  if (operator === operators.LESS_THAN) {
    return `less than ${value?.min ?? 0}`;
  }
  if (operator === operators.BETWEEN) {
    return `between ${value?.min ?? 0} - ${value?.max ?? 0}`;
  }
};

const getDisplayForRangeValues = (operator, value, field) => {
  if (field.includes('total')
      || field.includes('value')
      || (field.includes('credit') && !field.includes('customer.partial_return_credit_limit'))
  ) {
    return getDisplayForTotals(operator, value);
  }

  if (field.includes('percentage')) {
    return getDisplayForPercentage(operator, value);
  }

  if (field.includes('count')
      || field.includes('customer.returns')
      || field.includes('customer.keep_items')
      || field.includes('customer.ix_charge_declines')
      || field.includes('customer.partial_return_credit_limit')
  ) {
    return getDisplayForNumber(operator, value);
  }
};

const getDisplayForDateValues = (operator, value) => {
  if (operator === operators.AFTER_DATE || operator === operators.BEFORE_DATE) {
    const operatorText = operator === operators.AFTER_DATE ? 'after' : 'on or before';
    return `was placed ${operatorText} ${format(new Date(value), 'MMM d, yyyy')}`;
  }
  if (operator === operators.BETWEEN_DATE) {
    return `was placed between ${format(new Date(value?.start), 'MMM d, yyyy')} - ${format(new Date(value?.end), 'MMM d, yyyy')}`;
  }
};

export const getDisplayForRule = (operator, value, field) => {
  if (Object.values(rangeOperators).includes(operator)) {
    return getDisplayForRangeValues(operator, value, field);
  }

  if (Object.values(dateOperators).includes(operator)) {
    return getDisplayForDateValues(operator, value, field);
  }

  if (field === conditions.RETURN_REASON) {
    return getDisplayForReturnReasons(operator, value);
  }
  return null;
};

const getDisplayForReturnReasons = (operator, value) => {
  const reasons = store.state.reasons.library;
  const flatReasons = reasons.flatMap((reason) => {
    if (reason.children.length) {
      return [...reason.children, reason];
    }

    return reason;
  });

  return flatReasons.reduce((acc, reason) => {
    if (value?.includes(reason.id)) {
      acc.push(reason.reason);
    }
    return acc;
  }, []);
};
