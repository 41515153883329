/**
 * Compares two objects by their id property
 * @param {object} existing
 * @param {object} item
 * @returns {boolean} Returns true if the id properties match
 */
const validateById = (existing, item) => {
  return existing.id === item.id;
};

/**
 * This lets you insert an item into an array, either in place of an existing one or adding it if an existing one is not found
 * @param {object[]} list - The list to add to or update
 * @param {object} item - The item to add/update
 * @param {function} validator - A validator function. See validateById for how to implement this
 * @returns {object[]} The list with the item added or replacing the existing item
 */
const addOrUpdate = (list, item, validator) => {
  const fn = validator || validateById;
  const existingItem = list.find((existing) => fn(existing, item));

  if (existingItem) {
    return list.map((existing) => {
      if (fn(existing, item)) {
        return item;
      }

      return existing;
    });
  }

  return [
    ...list,
    item
  ];
};

export default addOrUpdate;
