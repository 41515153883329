import _ from 'lodash';
import ShopnowApi from '@/util/api/shopnow';
import SettingsRequest from '@/util/api/policies';

const request = new ShopnowApi();

//@TODO: Deprecate file when we are sure nothing in here is used

const mergeItemData = (current, toMerge) => {
  // Fold in new option data if the id matches
  if (current.id === toMerge.id) {
    return {
      ...current,
      ...toMerge,
      title: toMerge.name
    };
  }

  // Recursively do the same for any children
  return {
    ...current,
    options: current.options ? current.options.map(child => mergeItemData(child, toMerge)) : []
  };
};

const normalizeActive = item => {
  return {
    ...item,
    active: false
  };
};

export default {
  namespaced: true,
  state: {
    text: {
    },
    stripe: {},
    existingNavigation: [],
    navigation: [],
    collections: [],
    enabled: false,
    perPage: 25,
    saving: false
  },
  mutations: {
    setProperty(state, { key, value, namespace }) {
      if (namespace) {
        state[namespace][key] = value;
      } else {
        state[key] = value;
      }
    },
    updateItem(state, { item }) {
      state.navigation = state.navigation.map(nav => {
        return mergeItemData(nav, item);
      });
    },
    updateOrder(state, { navigation }) {
      state.navigation = navigation;
    },
    updateActive(state, { toToggle }) {
      state.navigation = state.navigation.map(option => {
        return {
          ...option,
          active: option.id === toToggle.id ? !option.active : option.active
        };
      });
    },
    addItem(state, { item }) {
      if (item.parent) {
        state.navigation = state.navigation.map(nav => {
          if (item.parent === nav.id) {
            return {
              ...nav,
              options: [
                ...nav.options,
                {
                  title: item.name,
                  order: nav.options.length,
                  options: [],
                  ...item
                }
              ]
            };
          }
          return nav;
        });
      } else {
        state.navigation = [
          ...state.navigation,
          {
            title: item.name,
            order: state.navigation.length,
            options: [],
            ...item
          }
        ];
      }
    },
    removeItem(state, { id }) {
      state.navigation = state.navigation
        .filter(nav => nav.id !== id)
        .map(nav => {
          return {
            ...nav,
            options: nav.options.filter(child => child.id !== id)
          };
        });
    },
    updateItems(state, { items }) {
      state.navigation = items;
      state.existingNavigation = items;
    }
  },
  actions: {
    getPageData({ commit }/* , route */) {
      return new Promise((resolve, reject) => {
        request.get()
          .then(res => {
            commit('setProperty', {
              key: 'stripe',
              value: {
                active: res.data.stripe_active,
                link: res.data.stripe_link,
                response: res.data.stripe_response,
              }
            });
            commit('setProperty', {
              key: 'navigation',
              value: res.data.selected
            });
            commit('setProperty', {
              key: 'existingNavigation',
              value: res.data.selected
            });
            commit('setProperty', {
              key: 'collections',
              value: res.data.collections.collections
            });
            commit('setProperty', {
              key: 'perPage',
              value: res.data.collections.filters.per_page
            });
            commit('setProperty', {
              key: 'enabled',
              value: res.data.storefront
            });
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    searchCollections({ commit }, { query }) {
      const options = {
        search: query
      };
      request.filter(options)
        .then(res => {
          commit('setProperty', { key: 'collections', value: res.data.collections });
        })
        .catch(err => {
          console.error(err);
        });
    },
    addOrChangeItem({ commit, getters }, { item }) {
      const existing = getters['allOptions'].find(nav => nav.id === item.id);
      if (existing) {
        commit('updateItem', { item });
      } else {
        commit('addItem', { item });
      }
    },
    reset({ state, commit }) {
      commit('setProperty', { key: 'navigation', value: _.cloneDeep(state.existingNavigation) });
      commit('setProperty', { key: 'changes', value: false });
    },
    save({ state, commit, dispatch, rootState, rootGetters }) {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'saving', value: true });

      request.save(state.navigation)
        .then(res => {
          // Save sales channel setting
          const Settings = new SettingsRequest();
          const preDiscountCreditSetting = rootState.userData.shop.settings.find(setting => setting.key === 'PRE_DISCOUNT_CREDIT');
          Settings.save([
            {
              ...preDiscountCreditSetting,
              value: rootGetters.getSetting('PRE_DISCOUNT_CREDIT') ? 'yes' : 'no'
            }
          ], false);

          // Make sure we retain which navs are open
          const activeMap = state.navigation.reduce((accumulator, current, index) => {
            return {
              ...accumulator,
              [index]: current.active
            };
          }, {});

          commit('updateItems', {
            items: res.data
              .map((item, index) => {
                return {
                  ...item,
                  active: activeMap[index] ? activeMap[index] : false
                };
              })
          });
          commit('setProperty', { key: 'saving', value: false });
          commit('setToast', {
            message: 'Menu saved successfully',
            type: 'success',
          }, { root: true });
        })
        .catch(() => {
          dispatch('reset');
          commit('setProperty', { key: 'saving', value: false });
          commit('setToast', {
            message: `Whoops, we couldn't save this`,
            type: 'error',
          }, { root: true });
        });
    }
  },
  getters: {
    allOptions(state) {
      // Get a list of all parents and children
      return state.navigation.reduce((accumulator, current) => {
        return [
          ...accumulator,
          current,
          ...current.options
        ];
      }, []);
    },
    changes(state) {
      // Normalize all active states, we don't care about that for saving
      // Then convert to string and compare to see if there are changes
      const existing = JSON.stringify(state.existingNavigation.map(normalizeActive));
      const current = JSON.stringify(state.navigation.map(normalizeActive));

      return existing !== current;
    },
    currentNavigation(state) {
      return state.navigation.length;
    },
    onstoreEnabled(state, getters, rootState) {
      const setting = rootState.userData ? rootState.userData.shop.settings.find(item => item.key === 'ON_STORE_API_ENABLED') : null;
      return setting && setting.pivot.value === 'yes';
    }
  },
};
