<template>
  <button
    class="styled-button"
    :class="[{ activated, slim, large }, type]"
    :type="role"
    v-bind="$attrs"
  >
    <slot></slot>
  </button>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'primary',
      validator: value => ['primary', 'outline', 'basic', 'destructive', 'plain'].includes(value)
    },
    activated: {
      type: Boolean,
      required: false,
      default: () => false
    },
    slim: {
      type: Boolean,
      required: false,
      default: () => false
    },
    large: {
      type: Boolean,
      required: false,
      default: () => false
    },
    role: {
      type: String,
      required: false
    }
  }
};
</script>

<style lang="scss">
.styled-button {
  @include button;

  // Sizes
  &.slim {
    @include button-slim;
  }

  &.large {
    @include button-large;
  }

  &.primary {
    @include button-primary;
  }

  // Styles
  &.outline {
    @include button-outline;
  }

  &.basic {
    @include button-basic;
  }

  &.destructive {
    @include button-destructive;
  }

  &.plain {
    @include button-plain;
  }
}
</style>
