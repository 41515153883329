export default [
  {
    path: '/multi-instance/export-settings',
    component: () => import('@/pages/MultiInstance/ExportSettings'),
    name: 'Export Shop Settings',
    meta: {
      layout: 'app',
      title: 'Export Shop Settings',
    }
  },
];
