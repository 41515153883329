import Workflows from '@/util/api/workflows';
import { moduleTypes } from '@/util/schemas/workflow-rules';
import { templates } from '@/util/schemas/workflows';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    modules: [],
  },
  mutations: {
    setModules(state, modules) {
      state.modules = modules;
    }
  },
  actions: {
    async getPageData({ commit }, route) {
      const modules = await Workflows.getModules();
      commit('setModules', modules);
      const templateSlug = route.query?.template;

      if (templateSlug) {
        const template = templates.find(t => t.slug === templateSlug);
        if (!template.availableIf()) {
          router.push({ name: 'WorkflowsList' });
        }
      }
    }
  },
  getters: {
    conditions(state) {
      return state.modules
        .filter(module => module.type === moduleTypes.CONDITION);
    },
    actions(state) {
      return state.modules
        .filter(module => module.type === moduleTypes.ACTION);
    }
  }
};
