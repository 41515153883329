import axios from 'axios';

const webhooks = {
  async getData() {
    try {
      const res = await axios.get(`webhooks/options`);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getList() {
    try {
      const res = await axios.get(`webhooks`);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getSecret() {
    try {
      const res = await axios.post(`webhooks/secret`);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async create(trigger, topic, url) {
    if (!trigger || !topic || !url) {
      console.error('API: webhooks.create() requires an trigger, topic, and a url as inputs.');
      return false;
    }

    try {
      const res = await axios.post(`webhooks`, {
        trigger,
        topic,
        url
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async update(id, options) {
    if (!id) {
      console.error('API: webhooks.update() requires an id as an input.');
      return false;
    }
        
    try {
      const res = await axios.put(`webhooks/${id}`, options);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async remove(id) {
    if (!id) {
      console.error('API: webhooks.remove() requires an id as an input.');
      return false;
    }
        
    try {
      const res = await axios.delete(`webhooks/${id}`);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async test(id) {
    if (!id) {
      console.error('API: webhooks.test() requires an id as an input.');
      return false;
    }

    try {
      const res = await axios.get(`webhooks/${id}/test`);
      if (res.data.errors) {
        return false;
      }
            
      return res.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async getWebhookAttemptsByReturnId(id) {
    try {
      const res = await axios.get(`returns/${id}/webhooks`);
      return res.data;
    } catch (error) {
      return error;
    }
  }
};

export default webhooks;
