import ReturnMethods from '@/util/api/returnMethods';

export default {
  namespaced: true,
  state: {
    returnMethods: [],
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    }
  },
  actions: {
    async getReturnMethods({ commit }) {
      const returnMethods = await ReturnMethods.getAll();

      commit('setProperty', {
        key: 'returnMethods',
        value: returnMethods
      });
    }
  },
  getters: {
  },
};
