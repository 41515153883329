import ReturnsApi from '@/util/api/returns';

const returnsRequest = new ReturnsApi({
  per_page: 25,
  sort: 'desc'
});

const toObject = (arr) => {
  return arr.reduce((acc, { key, value }) => {
    // If we've already added this key
    // eslint-disable-next-line no-prototype-builtins
    if (acc.hasOwnProperty(key)) {
      return Object.assign({}, acc, {
        // Readd property, if the current property is a string, convert to array
        // otherwise, add to existing array
        [key]: typeof acc[key] === 'string' ? [acc[key], value] : [...acc[key], value]
      });
    }
    return Object.assign({}, acc, { [key]: value });
  }, {});
};

const mapFilters = (arr) => {
  const asObject = toObject(arr.map(item => {
    return {
      key: item.filter.key,
      value: item.value.key
    };
  }));

  // Convert these into arrays, the backend can only handle an array
  ['type', 'status', 'label_status', 'return_method'].forEach(item => {
    // eslint-disable-next-line no-prototype-builtins
    if (asObject.hasOwnProperty(item) && typeof asObject[item] === 'string') {
      Object.assign(asObject, {
        [item]: [asObject[item]]
      });
    }
  });

  return asObject;
};

export default {
  namespaced: true,
  state: {
    text: {
    },
    initialLoad: true,
    updating: false,
    tab: 'all',
    search: '',
    items: [],
    filters: [],
    filtering: false,
    page: 0,
    pageAmount: 25,
    maxPages: 0,
    labelData: null,
    usesRestock: '',
    autoRestock: '',
    finished: true
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    addFilter(state, filter) {
      const findMatch = item => item.filter === filter.filter && item.value === filter.value;
      const existing = state.filters.find(findMatch);

      if (existing && filter.replace) {
        state.filters = state.filters
          .map(item => {
            if (findMatch(item)) {
              return filter;
            }
            return item;
          });
      } else {
        state.filters = [
          ...state.filters,
          filter
        ];
      }
    },
    removeFilter(state, filter) {
      const findMatch = item => item.filter.key === filter.filter.key && item.value.key === filter.value.key;
      state.filters = state.filters
        .filter(item => {
          return !findMatch(item);
        });
    }
  },
  actions: {
    getPageData({ state, commit, dispatch }, route) {
      const tab = route.params.status || 'all';
      const page = route.params.page ? +route.params.page : 1;

      if (state.initialLoad) {
        commit('setProperty', { key: 'initialLoad', value: false });

        // Set filter data by loading it in from url query params
        // We're checking to see if the query object has keys added
        if (Object.keys(route.query).length > 0) {
          const items = Object.entries(route.query);
          const filters = items
          // Remove search, we handle that separately
            .filter(([key]) => key !== 'search')
          // Build filter array
            .reduce((acc, [key, value]) => {
              // Split on commas since we might have multiple filters, then merge into
              // the final filters array

              // There's just a key, we're going to just move along
              if (!value) {
                return acc;
              }
              const values = value.split(',').map(item => {
                return {
                  filter: {
                    key
                  },
                  value: {
                    key: item
                  }
                };
              });

              return [
                ...acc,
                ...values
              ];
            }, []);

          commit('setProperty', { key: 'filters', value: filters });

          // Set search if needed
          const search = items.find(([key]) => key === 'search');
          if (search) {
            // search is an array of ['key', 'value'] from url params
            commit('setProperty', { key: 'search', value: search[1] });
          }
        }

        return Promise.all([
          dispatch('getTab', { tab, page }),
          returnsRequest.getPage()
            .then(res => {
              commit('setProperty', { key: 'stateData', value: res.data.states }, { root: true });
              commit('setProperty', { key: 'provinceData', value: res.data.provinces }, { root: true });
              commit('setProperty', { key: 'labelData', value: res.data.uses_labels });
              commit('setProperty', { key: 'usesRestock', value: res.data.uses_restock });
              commit('setProperty', { key: 'autoRestock', value: res.data.auto_restock });
            })
        ]);
        // We're actively filtering, so resolve since we're handling this elsewhere
      } else if (state.filtering) {
        return Promise.resolve();
      } else {
        return dispatch('getTab', { tab, page });
      }
    },
    getList({ state, commit }, options = {}) {
      // If tab is all, don't send a status to filter by
      const tab = state.tab !== 'all' && state.tab !== '' ? { status: [state.tab] } : {};
      const search = state.search.length > 0 ? { search: state.search } : {};
      const page = { page: state.page };
      commit('setProperty', { key: 'finished', value: false });

      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'updating', value: true });

      // Reset current items
      window.scrollTo(200, 0);
      window.setTimeout(() => {
        if (!state.finished) {
          commit('setProperty', { key: 'items', value: [] });
        }
      }, 500);

      return new Promise((resolve, reject) => {
        returnsRequest.getList(Object.assign({}, tab, options, search, page))
          .then(res => {
            if (res.data.returns.length > 0) {
              commit('setProperty', { key: 'finished', value: true });
              commit('setProperty', { key: 'items', value: res.data.returns });
            } else {
              commit('setProperty', { key: 'page', value: state.page - 1 });
              commit('setProperty', { key: 'maxPages', value: state.page });
              commit('setProperty', { key: 'items', value: [] });
            }
            commit('setProperty', { key: 'updating', value: false });
            resolve(res);
          })
          .catch(err => {
            commit('setProperty', { key: 'updating', value: false });
            reject(err);
          });
      });
    },
    updateSearch({ commit, getters, dispatch }, { value }) {
      commit('setProperty', { key: 'filtering', value: true });
      commit('setProperty', { key: 'page', value: 1 });
      commit('setProperty', { key: 'maxPages', value: 0 });
      commit('setProperty', { key: 'search', value });
      dispatch('getList', getters['getFilterData'])
        .then(() => {
          commit('setProperty', { key: 'filtering', value: false });
        });
    },
    addFilter({ commit, getters, dispatch }, newFilter) {
      commit('setProperty', { key: 'filtering', value: true });
      commit('setProperty', { key: 'page', value: 1 });
      commit('setProperty', { key: 'maxPages', value: 0 });
      commit('addFilter', newFilter);
      dispatch('getList', getters['getFilterData'])
        .then(() => {
          commit('setProperty', { key: 'filtering', value: false });
        });
    },
    removeFilter({ commit, getters, dispatch }, oldFilter) {
      commit('setProperty', { key: 'filtering', value: true });
      commit('setProperty', { key: 'page', value: 1 });
      commit('setProperty', { key: 'maxPages', value: 0 });
      commit('removeFilter', oldFilter);
      dispatch('getList', getters['getFilterData'])
        .then(() => {
          commit('setProperty', { key: 'filtering', value: false });
        });
    },
    getTab({ commit, getters, dispatch }, { tab, page }) {
      commit('setProperty', { key: 'tab', value: tab });
      commit('setProperty', { key: 'page', value: page });
      commit('setProperty', { key: 'maxPages', value: 0 });
      return dispatch('getList', getters['getFilterData']);
    },
    getPage({ commit, getters, dispatch }, { page }) {
      commit('setProperty', { key: 'filtering', value: true });
      commit('setProperty', { key: 'page', value: page });
      dispatch('getList', getters['getFilterData'])
        .then(() => {
          commit('setProperty', { key: 'filtering', value: false });
        });
    },
  },
  getters: {
    getPagination(state) {
      return {
        page: state.page,
        previous: {
          disabled: state.page === 1
        },
        next: {
          disabled: state.items.length < state.pageAmount || state.maxPages === state.page
        }
      };
    },
    getFilterData(state) {
      return mapFilters(state.filters);
    }
  }
};
