import webhooks from '@/util/api/webhooks';
import apiKeys from '@/util/api/apiKeys';

export default {
  namespaced: true,
  state: {
    webhookData: {
      topics: {},
      triggers: {}
    },
    webhooks: [],
    apiKeys: [],
    apiKeyScopes: []
  },
  mutations: {
    setProperty(state, { key, value, namespace }) {
      if (namespace) {
        state[namespace][key] = value;
      } else {
        state[key] = value;
      }
    },
    setWebhookData(state, data) {
      state.webhookData = data;
    },
    setWebhooks(state, webhooks) {
      state.webhooks = webhooks;
    },
    setApiKeys(state, apiKeys) {
      state.apiKeys = apiKeys;
    },
    setApiKeyScopes(state, scopes) {
      state.apiKeyScopes = scopes;
    }
  },
  actions: {
    async getPageData({ commit }/* , route */) {
      try {
        const [webhookData, webhookList, apiKeyList, apiKeyScopes] = await Promise.all([
          webhooks.getData(),
          webhooks.getList(),
          apiKeys.getList(),
          apiKeys.getScopes(),
        ]);

        commit('setWebhookData', webhookData);
        commit('setWebhooks', webhookList);
        commit('setApiKeys', apiKeyList);
        commit('setApiKeyScopes', apiKeyScopes);

        return {
          webhooks: webhookList,
          apiKeys: apiKeyList,
          apiKeyScopes
        };
      } catch (error) {
        return {
          error
        };
      }
    },
    async createWebhook({ commit, state }, { trigger, topic, url }) {
      try {
        const webhook = await webhooks.create(trigger, topic, url);

        commit('setWebhooks', [
          ...state.webhooks,
          webhook
        ]);

        return {
          webhook
        };
      } catch (error) {
        return {
          error
        };
      }
    },
    async updateWebhook({ commit, state }, { id, data }) {
      try {
        const webhook = await webhooks.update(id, data);

        commit('setWebhooks', state.webhooks.map(item => {
          if (item.id === id) {
            return webhook;
          }
          return item;
        }));

        return {
          webhook
        };
      } catch (error) {
        return {
          error
        };
      }
    },
    async removeWebhook({ commit, state }, { id }) {
      try {
        await webhooks.remove(id);

        commit('setWebhooks', state.webhooks.filter(item => item.id !== id));

        return {
          removed: true
        };
      } catch (error) {
        return {
          error
        };
      }
    },
    async createApiKey({ commit, state }, { scopes, name }) {
      try {
        const apiKey = await apiKeys.create(scopes, name);

        commit('setApiKeys', [
          ...state.apiKeys,
          apiKey
        ]);

        return {
          apiKey
        };
      } catch (error) {
        return {
          error
        };
      }
    },
    async updateApiKey({ commit, state }, { id, scopes, name }) {
      try {
        const apiKey = await apiKeys.update(id, scopes, name);

        commit('setApiKeys', state.apiKeys.map(item => {
          if (item.id === id) {
            return apiKey;
          }
          return item;
        }));

        return {
          apiKey
        };
      } catch (error) {
        return {
          error
        };
      }
    },
    async removeApiKey({ commit, state }, { id }) {
      try {
        await apiKeys.remove(id);

        commit('setApiKeys', state.apiKeys.filter(item => item.id !== id));

        return {
          removed: true
        };
      } catch (error) {
        return {
          error
        };
      }
    },
  },
  getters: {
  }
};
