import SkeletonList from '@/components/skeletons/SkeletonList';

export default [
  {
    path: '/listings/',
    component: () => import('@/pages/Listings/ListingList'),
    name: 'ListingList',
    meta: {
      layout: 'app',
      title: 'Blocklist & allowlist',
      skeleton: () => SkeletonList,
      action: 'listings/getPageData'
    }
  },
  /*     {
            path: '/add',
            component: require('./ListingAdd/ListingAdd.vue'),
            name: 'ListingAdd',
            meta: {
                layout: 'app',
                title: 'Add listing'
            }
        } */
];
