import axios from 'axios';

const customFeatures = function (type) {
  // This is what the endpoint expects
  /* const requestTemplate = {
        threshold: '',
        immediate: '',
        active: '',
        exclusions: '' // not required
    } */

  this.type = type;
};

customFeatures.prototype = {
  get(type) {
    return new Promise((resolve, reject) => {
      const getType = type || this.type;

      if (typeof type !== 'string') {
        console.error('API: customFeatures.get() requires a string for type and an object for feature as an input.');
        reject();
      }

      if (getType === 'keep-item') {
        axios.get(`keep-item`)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      }
    });
  },
  save(feature) {
    return new Promise((resolve, reject) => {
      const type = this.type;

      if (typeof type !== 'string' || typeof feature !== 'object' || feature === null) {
        console.error('API: customFeatures.save() requires a string for type and an object for feature as an input.');
        reject();
      }

      if (type === 'keep-item') {
        // eslint-disable-next-line no-prototype-builtins
        if (feature.hasOwnProperty('id')) {
          axios.patch('keep-item', feature)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        } else {
          const defaults = {
            active: false,
            immediate: false,
            threshold: 0,
            exclusions: []
          };
          axios.post('keep-item', Object.assign({}, defaults, feature))
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        }
      }

      if (type === 'prepaid-labels') {
        // Assuming actual input reference for now
        if (!feature.input) {
          console.error('API: customFeatures.save() with type of labels requires a reference to a form input.');
          reject();
        }

        if (feature.input) {
          // Just get first file in list
          const csv = feature.input.files[0];
          const formData = new FormData();
          formData.append('prepaid_csv', csv);

          axios.post(`prepaid-labels`, formData)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        }
      }
    });
  }
};

export default customFeatures;
