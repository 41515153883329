import _ from 'lodash';
import axios from 'axios';

const account = {
  get() {
    return axios.get(`users`)
      .then((res) => res.data);
  },
  getUser({ id }) {
    if (!id) {
      console.error('API: account.getUser() requires an object with a property "id".');
      Promise.reject();
    }

    return axios.get(`users/${id}`)
      .then((res) => {
        return res.data;
      });
  },
  updateExisting(existingItems, newItems) {
    let updated = _.cloneDeep(existingItems);

    newItems.forEach(item => {
      // Check if we already have this saved in our list
      const existing = updated.some(upd => upd.id === item.id);

      if (existing) {
        updated = updated.map(upd => {
          if (upd.id === item.id) {
            return item;
          }
          return upd;
        });
      } else {
        // Add on the the end if we didn't have it before
        updated = [
          ...updated,
          item
        ];
      }
    });
    return updated;
  },
  removeExisting(existingItems, newItems) {
    let updated = _.cloneDeep(existingItems);

    newItems.forEach(item => {
      updated = updated.filter(upd => upd.id !== item.id);
    });
    return updated;
  },
  save(user) {
    if (typeof user !== 'object' || user === null) {
      console.error('API: account.save() requires an object as an input.');
      Promise.reject();
    }

    const verb = user.id ? 'put' : 'post';
    const endpoint = user.id ? `users/${user.id}` : 'users';

    return axios[verb](endpoint, user)
      .then((res) => res.data);
  },
  remove(user) {
    if (!user || typeof user !== 'object' || !user.id) {
      console.error('API: account.remove() requires an object as an input.');
      Promise.reject();
    }

    return axios.put(`users/${user.id}/toggle`, user)
      .then((res) => res.data);
  },
  enableSSO() {
    return axios.post('users/enable-sso')
      .then(res => res.data)
      .catch(err => {
        throw err;
      });
  },
  resendInvite(user) {
    return axios.post(`users/${user.id}/resend-invite`)
      .then((res) => res.data)
      .catch(err => {
        throw err;
      });
  },
  enableSwitchShop(shopId) {
    return axios.post('users/enable-switch-shop', { shop_id: shopId })
      .then(res => res.data)
      .catch(err => {
        throw err;
      });
  }
};

export default account;
