import WorkflowGroups from '@/util/api/workflowGroups';
import { staticTemplateGroups } from '@/util/schemas/workflow-groups';
import { capitalize } from '@/util/helpers/formatting';

export default {
  namespaced: true,
  state: {
    static: {
      groups: {},
      initial: {},
      draft: {},
      groupsWithDraftChanges: [],
      state: 'unloaded'
    },
    warranty: {
      groups: {},
      draft: null,
      state: 'unloaded'
    }
  },
  mutations: {
    setGroupsByType(state, { type, groups }) {
      state[type].groups = groups;
    },
    setGroupTypeState(state, { type, groupTypeState }) {
      state[type].state = groupTypeState;
    },
    setGroup(state, { group }) {
      state[group.type].groups[group.id] = group;
    },
    deleteGroup(state, { group }) {
      delete state[group.type].groups[group.id];
    },
    setInitialStaticWorkflows(state, workflows) {
      state.static.initial = workflows;
    },
    setDraftStaticWorkflows(state, workflows) {
      state.static.draft = workflows;
    },
    clearStaticGroupsWithDraftChanges(state) {
      state.static.groupsWithDraftChanges = [];
    },
    setStaticGroupHasDraftChanges(state, { group, changed }) {
      if (changed) {
        state.static.groupsWithDraftChanges = [...new Set([...state.static.groupsWithDraftChanges, group])];
      } else {
        state.static.groupsWithDraftChanges = state.static.groupsWithDraftChanges.filter(x => x !== group);
      }
    },
    setDraftWorkflowGroupByType(state, { group, type }) {
      state[type].draft = group;
    }
  },
  actions: {
    updateGroupTypeState({ commit }, { type, groupTypeState }) {
      commit('setGroupTypeState', { type, groupTypeState });
    },
    async getGroupsByType({ commit, dispatch }, { type }) {
      commit('setGroupTypeState', { type, groupTypeState: 'loading' });
      try {
        const groups = await WorkflowGroups.getByType(type) ?? [];
        dispatch('loadGroupsByType', { type, groups });
      } catch (error) {
        commit('setToast', {
          message: `Unable to get ${type} Workflow Groups`,
          type: 'error',
        }, { root: true });
        commit('setGroupTypeState', { type, groupTypeState: 'error' });
      }
    },
    loadGroupsByType({ commit, dispatch }, { type, groups }) {
      groups = groups.reduce((obj, group) => {
        obj[group.id] = group;
        return obj;
      }, {});
      commit('setGroupsByType', { type, groups });

      switch (type) {
      case 'static':
        dispatch('loadStaticWorkflowsToggleableEditState', { type, groups: Object.values(groups) });
        break;
      default:
        break;
      }

      commit('setGroupTypeState', { type, groupTypeState: 'loaded' });
    },
    async toggleGroupActive({ commit }, { group, active }) {
      try {
        const group = active ? await WorkflowGroups.activate(group?.id) : await WorkflowGroups.deactivate(group?.id);
        commit('setGroup', { group });
      } catch (error) {
        commit('setToast', {
          message: `Unable to ${active ? 'activate' : 'deactivate'} ${group?.type} Workflow Group`,
          type: 'error',
        }, { root: true });
      }
    },
    async deleteGroup({ commit }, { group }) {
      try {
        await WorkflowGroups.delete(group?.id);
        commit('deleteGroup', { group });
      } catch (error) {
        commit('setToast', {
          message: `Unable to delete ${group?.type} Workflow Group`,
          type: 'error',
        }, { root: true });
      }
    },
    loadStaticWorkflowsToggleableEditState({ commit }, { groups }) {
      const emptyGroups = Object.values(staticTemplateGroups).reduce((acc, group) => {
        acc[group.name] = {};
        return acc;
      }, {});

      const staticWorkflows = groups.reduce((x, group) => {
        x['initial'][group.name] = group.workflows.reduce((y, workflow) => {
          y[workflow.name] = true;

          return y;
        }, {});
        x['draft'][group.name] = { ...x['initial'][group.name] };

        return x;
      }, { 'initial': { ...emptyGroups }, 'draft': { ...emptyGroups } });

      commit('setInitialStaticWorkflows', staticWorkflows['initial']);
      commit('setDraftStaticWorkflows', staticWorkflows['draft']);
      commit('clearStaticGroupsWithDraftChanges');
    },
    toggleStaticWorkflowsInDraft({ commit, state, dispatch }, { group, workflow }) {
      const workflows = { ...state.static.draft[group] };

      if (workflows[workflow]) {
        delete workflows[workflow];
      } else {
        workflows[workflow] = true;
      }

      commit('setDraftStaticWorkflows', { ...state.static.draft, [group]: workflows });
      dispatch('assessIfStaticWorkflowGroupHasChanges', { group });
    },
    assessIfStaticWorkflowGroupHasChanges({ commit, state }, { group }) {
      const initial = Object.keys(state.static.initial[group]);
      const draftLength = Object.keys(state.static.draft[group]).length;

      if (initial.length !== draftLength || (initial.some((workflow) => !state.static.draft[group][workflow]))) {
        commit('setStaticGroupHasDraftChanges', { group, changed: true });
        return;
      }

      commit('setStaticGroupHasDraftChanges', { group, changed: false });
    },
    async saveStaticWorkflowGroups({ commit, state, dispatch }) {
      commit('setGroupTypeState', { type: 'static', groupTypeState: 'saving' });

      try {
        const staticGroups = Object.keys(state.static.draft).reduce((x, group) => {
          x[group] = new Set(Object.keys(state.static.draft[group]));
          return x;
        }, {});
        const updatedStaticGroups = [];

        for (const groupName of state.static.groupsWithDraftChanges) {
          const group = Object.values(staticTemplateGroups)
            .find(group => group.name === groupName);

          const filteredTemplates = group.templates
            .filter(template => staticGroups[group.name].has(template.heading))
            .map(template => {
              return {
                name: template.heading,
                description: template.description,
                trigger: template.trigger,
                rules: JSON.stringify(template.rules)
              };
            });

          const existingGroup =
            Object.values(state.static.groups).find((x) => x.name === group.name)
            || updatedStaticGroups.find((g) => g.name === group.name);
          const request = {
            id: existingGroup?.id,
            name: group.name,
            type: 'static',
            workflows: filteredTemplates,
            parameters: existingGroup?.parameters ?? []
          };

          const newGroup = existingGroup
            ? await WorkflowGroups.update(request)
            : await WorkflowGroups.create(request);

          updatedStaticGroups.push(newGroup);
        }

        dispatch('loadGroupsByType', { type: 'static', groups: [ ...Object.values(state.static.groups), ...updatedStaticGroups ] });
        commit('setToast', {
          message: 'Static Workflow Groups saved',
          type: 'success',
        }, { root: true });
        commit('setGroupTypeState', { type: 'static', groupTypeState: 'loaded' });
      } catch (error) {
        commit('setToast', {
          message: 'Unable to save static Workflow Groups',
          type: 'error',
        }, { root: true });
        commit('setGroupTypeState', { type: 'static', groupTypeState: 'error' });
      }
    },
    async setAndSaveDraftWorkflowGroupByType({ commit, dispatch }, { group, type }) {
      commit('setDraftWorkflowGroupByType', { group, type });
      return await dispatch('saveDraftWorkflowGroupByType', { type });
    },
    async saveDraftWorkflowGroupByType({ commit, state, dispatch }, { type }) {
      const group = state[type].draft;
      if (!group) {
        return;
      }

      commit('setGroupTypeState', { type, groupTypeState: 'saving' });
      const existingGroup = Object.values(state[type].groups).find((x) => x.id === group.id);
      try {
        const newGroup = existingGroup
          ? await WorkflowGroups.update(group)
          : await WorkflowGroups.create(group);

        dispatch('loadGroupsByType', { type, groups: [ ...Object.values(state[type].groups), newGroup ] });
        commit('setToast', {
          message: `${capitalize(type)} saved`,
          type: 'success',
        }, { root: true });
        commit('setDraftWorkflowGroupByType', { group: null, type });
        commit('setGroupTypeState', { type: group.type, groupTypeState: 'loaded' });
        return newGroup;

      } catch (error) {
        commit('setToast', {
          message: `Unable to save ${type}`,
          type: 'error',
        }, { root: true });
        commit('setGroupTypeState', { type: group.type, groupTypeState: 'error' });
      }
    }
  },
  getters: {
    groupsByType: (state) => (type) => {
      return state[type].groups;
    },
    groupTypeState: (state) => (type) => {
      return state[type].state;
    },
    groupById: (state) => ({ type, id }) => {
      return state[type].groups[id];
    },
    groupTypeGroupsWithDraftChanges: (state) => (type) => {
      return state[type].groupsWithDraftChanges;
    },
    groupTypeDraft: (state) => (type) => {
      return state[type].draft;
    }
  }
};
