import SkeletonList from '@/components/skeletons/SkeletonList';
import SkeletonDetail from '@/components/skeletons/SkeletonDetail';

export default [
  {
    path: '/rules/',
    component: () => import('@/pages/Rules/RulesList'),
    name: 'RulesList',
    meta: {
      layout: 'app',
      title: 'Rules',
      skeleton: () => SkeletonList,
      action: 'rules/getPageData'
    }
  },
  {
    path: '/rules/rule/create',
    component: () => import('@/pages/Rules/RulesPage'),
    name: 'CreateRule',
    meta: {
      layout: 'app',
      title: 'Create Policy Rule',
      skeleton: () => SkeletonDetail,
      action: 'policyRulesDetail/getPageData'
    }
  },
  {
    path: '/rules/rule/:rule',
    component: () => import('@/pages/Rules/RulesPage'),
    name: 'RulesDetail',
    meta: {
      layout: 'app',
      title: 'Edit Rule',
      skeleton: () => SkeletonDetail,
      action: 'policyRulesDetail/getPageData'
    }
  }
];
