// import Vue from 'vue';
import SetupCalendar from 'v-calendar';

// Vue.use(VCalendar, {
//   componentPrefix: 'vc'
// });

export const usePlugins = (app) => {
  app.use(SetupCalendar, {
    componentPrefix: 'vc'
  });
};
