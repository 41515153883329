import Cookies from 'js-cookie';
import store from '@/store';

export const cleanupUser = function () {
  // Delete cookie so they don't stay logged in
  Cookies.remove('loop-admin-token');

  // Remove all user data from store
  store.commit('setUser', null);
};
