import onboardingApi from '@/util/api/onboarding';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    text: {},
    loading: false,
    reinstall: false,
    initialLoad: true,
    user: null,
    shopId: null,
    store: {
      storeName: '',
      loopUrl: '',
      urlVerified: null,
      email: '',
      senderName: '',
      replyTo: '',
    },
    errors: [],
  },
  mutations: {
    setProperty(state, { key, value, namespace }) {
      if (namespace) {
        state[namespace][key] = value;
      } else {
        state[key] = value;
      }
    },

    setUser(state, user) {
      state.user = {
        ...state.user,
        ...user,
      };
    },

    setStore(state, store) {
      state.store = {
        ...state.store,
        ...store,
      };
    },

    setErrors(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async getPageData({ commit }, route) {
      const token = route.query.token || null;

      try {
        const res = await onboardingApi.get(token);

        commit('setProperty', { key: 'shopId', value: res.data?.shop?.id ?? null });

        commit('setProperty', { key: 'initialLoad', value: false });
      } catch (e) {
        commit('setErrors', e.response?.data?.errors || ['An unknown error occurred']);
        await router.replace({ name: 'InstallError' });
      }
    },
  },
  getters: {
    getErrors(state) {
      return state.errors;
    }
  },
};
