import RulesApi from '@/util/api/rules';

const request = new RulesApi();

export default {
  namespaced: true,
  state: {
    text: {
    },
    rule: {
      name: '',
      conditions: [],
      outcomes: [],
    },
    loading: true
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    updateRule(state, rule) {
      state.rule = {
        ...state.rule,
        ...rule
      };
    },
    resetRule(state) {
      state.rule = {
        name: '',
        conditions: [],
        outcomes: [],
      };
    }
  },
  actions: {
    getPageData({ rootState, commit }, route) {
      if (rootState.ignoreLoader) {
        commit('setProperty', { key: 'ignoreLoader', value: false }, { root: true });
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        if (route.params.rule) {
          request.getRule(route.params.rule)
            .then(res => {
              commit('updateRule', res.data);
              commit('setProperty', {
                key: 'loading',
                value: false
              });
              resolve();
            });
        } else {
          commit('setProperty', {
            key: 'loading',
            value: false
          });
          resolve();
        }
      });
    }
  },
  getters: {
  }
};
