import Store from '@/store';
import env from '@/env';

const getPortalUrl = () => {
  return env('VITE_PORTAL_URL') ?? Store.getters['customerPortalLink'];
};

/**
 * Send a message to an iframe
 * @param {string} frame
 * @param {object} message
 * @param targetOrigin
 * @param {Action} message.action
 * @param {object} message.data
 * @returns
 */
export const sendMessage = (frame, message, targetOrigin = getPortalUrl()) => {

  //message is not coming in correctly, so we need to stringify the data.data
  if (message !== undefined && message.data !== undefined) {
    message.data = JSON.parse(JSON.stringify(message.data));
  }

  if (!frame || frame?.tagName !== 'IFRAME') {
    console.error('You need to pass in an iframe to send a message to it.');
    return;
  }

  //catch errors if the iframe is not loaded
  try {
    frame.contentWindow.postMessage(message, targetOrigin);
  } catch (e) {
    console.error('error sending message to iframe', e);
  }
};

/**
 * Watch for messages from an iframe
 * @param {function} callback
 */
export const watchMessages = (callback, origin) => {
  const url = origin || getPortalUrl();
  window.addEventListener('message', (event) => {
    if (event.origin === url) {
      callback(event.data);
    }
  }, false);
};

/**
 * Check to see if the current user is seeing the admin in an iframe
 * @returns {boolean}
 */
export const inIframe = () => {
  return window.location !== window.parent.location;
};

export { actions } from '@/util/schemas/messageActions';
