import axios from 'axios';

export default {
  async getAll(assigneeId, assigneeType, params = {}) {
    try {
      const response = await axios.get('assignments', {
        params: {
          assigneeId,
          assigneeType,
          ...params
        }
      });

      return response.data.map((assignment) => {
        return {
          ...assignment,
        };
      });
    } catch (error) {
      throw new TaskError(error);
    }
  },

  async start(assigneeId, assigneeType, assignment) {
    try {
      const response = await axios.put(`assignments/${assignment.id}/start`, {
        assigneeId,
        assigneeType,
      });

      return response.data;
    } catch (error) {
      throw new TaskError(error);
    }
  },

  async update(assigneeId, assigneeType, assignment, payload) {
    try {
      const response = await axios.put(`assignments/${assignment?.id}/update`, {
        assigneeId,
        assigneeType,
        ...payload,
      }).then(res => res.data);

      return response.data;
    } catch (error) {
      throw new TaskError(error);
    }
  },

  async complete(assigneeId, assigneeType, assignment, payload) {
    try {
      const response = await axios.post(`assignments/${assignment?.id}/complete`, {
        assigneeId,
        assigneeType,
        ...payload,
      });

      return response.data;
    } catch (error) {
      throw new TaskError(error);
    }
  },

  async restart(assigneeId, assigneeType, assignment, payload) {
    try {
      const response = await axios.post(`assignments/${assignment?.id}/restart`, {
        assigneeId,
        assigneeType,
        ...payload,
      });

      return response.data;
    } catch (error) {
      throw new TaskError(error);
    }
  }
};

export class TaskError extends Error {
  constructor(error) {
    super(error.response?.data?.message || error.message || 'Something Went Wrong');
    this.name = 'TaskError';
    this.errorBag = error.response?.data.errors || {};
    this.status = error.response?.status || 500;
  }

  isServerError() {
    return this.status >= 500;
  }

  isValidationError() {
    return this.status === 422;
  }

  firstErrorFor(errorKey) {
    return this.errorBag?.[errorKey]?.[0] ?? null;
  }
}
