import axios from 'axios';
import Cookies from 'js-cookie';
import env from '@/env';
import store from '@/store';
import router from '@/router';
import { ldFlagVariation } from '@/util/lib/launchDarkly';
import { featureFlags } from '@/util/schemas/featureFlags';


const setAxiosDefaults = async () => {

  const base = env('VITE_API_URL');
  const route = 'api/admin';
  axios.defaults.baseURL = `${base}/${route}`;

  if (await ldFlagVariation(featureFlags.AUTH0_SESSIONS_ENABLED)) {
    axios.defaults.withCredentials = true;
  } else { // Use token if sessions is not enabled
    axios.interceptors.request.use((config) => {
      const token = store.state.token || Cookies.get('loop-admin-token');
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    });
  }

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (error) => {
      // Errors
      if (
        error.response?.status === 403 &&
        !['/not-authorized', '/install'].includes(router.currentRoute.value.fullPath)
      ) {
        router.push('/not-authorized');
      }

      console.error('Error in axios interceptor', error);
      if (error.response?.request?.responseURL) {
        console.error('AxiosInterceptor: API path:', error.response.request.responseURL);
      }

      // Do something with response error
      return Promise.reject(error);
    });
};

export default setAxiosDefaults;
