import useMultiSelect from '@/util/composables/multiselect';
const { flattenNestedArray } = useMultiSelect();

/**
 * Maps products from the api response 
 * Matches product types on the label
 * @param {object} options
 * @returns {array}
 */
export const mapProductTypesFromApi = ({
  allTypes,
  selectedTypes,
}) => {
  return selectedTypes.map(item => {
    return allTypes.find(type => type.label == item);
  });
};

/**
 * Maps regions from the api response 
 * Matches regions on the id
 * @param {object} options
 * @returns {array}
 */
export const mapRegionsFromApi = ({
  allRegions,
  selectedRegions,
}) => {
  const flatRegions = flattenNestedArray(allRegions);
  return selectedRegions.map(regionId => 
    flatRegions.find(item => item.id === regionId));
};

/**
 * Maps regions for the api request
 * Returns a list of product type strings
 * @param {object} options
 * @returns {array}
 */
export const mapProductTypesToApi = ({
  allTypes,
  selectedTypes
}) => {
  return selectedTypes.map(item => 
    allTypes.find(i => i.id == item || i.id == item.id).label);
};

/**
 * Maps regions for the api request
 * Returns a list of region ids
 * @param {array} selectedRegions
 * @returns {array}
 */
export const mapRegionsToApi = selectedRegions => {
  return selectedRegions.map(i => i?.id ? i.id : i);
};

/**
 * Sort field errors captured by vee-validate according to the order in the wararnty details page layout
 * Filters out unrelated selector errors based on the selected state of the selectors
 * Filters out unrelated section errors based on the toggle state of the section
 * Returns a list of sorted field names
 * @param {array} fieldNames
 * @param {string} selectedProductEligibilityType
 * @param {string} selectedHandlingFeeType
 * @param {boolean} handlingFeeEnabled
 * @returns {array}
 */
export const sortFieldNames = ({
  fieldNames,
  selectedProductEligibilityType,
  selectedHandlingFeeType,
  handlingFeeEnabled
}) => {
  const productTypes = {
    'Product IDs': 'productIds',
    'Product SKUs': 'productSkus',
    'Product tags': 'productTags',
    'Product types': 'productTypes',
  };
  const productEligibilityFields = {
    productIds: 'productEligibilityIds',
    productSkus: 'productEligibilitySkus',
    productTags: 'productEligibilityTags',
    productTypes: 'productTypesSelected',
  };

  const feeTypes = {
    'flat-rate': 'handlingFeeFlatRate',
    'label-cost': 'handlingFeePercentageLabelCost',
    'return-value': 'handlingFeePercentageReturnValue',
  };

  const fieldsLayoutOrder = new Map([
    'Warranty Name',
    'productTypesSelected',
    'productEligibilityIds',
    'productEligibilitySkus',
    'productEligibilityTags',
    'beforeDate',
    'afterDate',
    'warrantyWindowYears',
    'warrantyWindowDays',
    'selectedRegions',
  ].map((field, index) => [field, index]));

  const activeProductEligibilityError = fieldNames.find((field) => (Object.values(productEligibilityFields).includes(field)
    && productEligibilityFields[productTypes[selectedProductEligibilityType]] === field)
  );
  const activeHandlingFeeError = fieldNames.find((field) => handlingFeeEnabled && feeTypes[selectedHandlingFeeType] === field);
  const otherErrors = fieldNames.filter((field) => ![...Object.values(productEligibilityFields), ...Object.values(feeTypes)].includes(field));

  return [...otherErrors, activeProductEligibilityError, activeHandlingFeeError]
    .filter((r) => r)
    .sort((a, b) => fieldsLayoutOrder.get(a) - fieldsLayoutOrder.get(b));
};
