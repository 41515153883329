import axios from 'axios';

const shop = {
  get(field) {
    return axios.get(`/shop/${field}`);
  },
  save(options) {
    return axios.post(`/shop`, options);
  }
};

export default shop;
