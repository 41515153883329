<template>
  <component
    :is="component"
    v-if="component"
    :user-data="userData"
    :type="layoutType"
  >
    <router-view v-if="!loader" />
    <loading-page
      v-else
      :loader="loader"
    />
    <toast-notification
      :message="toast.message"
      :type="toast.type"
      :duration="toast.duration"
      @hidden="resetToast"
    />
  </component>
  <div
    v-if="!component"
    class="app__none"
  >
    <router-view v-slot="{ Component }">
      <transition
        name="fade-quick"
        mode="out-in"
      >
        <component
          :is="Component"
          v-if="!loader"
          :key="$route.meta?.key ?? $route.fullPath"
        />

        <loading-page v-else />
      </transition>
    </router-view>
    <toast-notification
      :message="toast.message"
      :type="toast.type"
      :duration="toast.duration"
      @hidden="resetToast"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminLayout from '@/components/layout/AdminLayout';
import MultiProductLayout from '@/components/layout/MultiProduct/MultiProductLayout';
import LoadingPage from '@/pages/States/LoadingPage';
import { ToastNotification } from '@loophq/ui';
import { featureFlags } from '@/util/schemas/featureFlags';

export default {
  components: {
    LoadingPage,
    ToastNotification,
    AdminLayout,
    MultiProductLayout
  },
  computed: {
    ...mapState({
      toast: state => state.toast,
      loader: state => state.loader,
      userData: state => state.userData,
      layout: state => state.layout,
      layoutType: state => state.layoutType,
      component: (state, getters) => {
        if (state.layout === 'app' && !getters.hasFeature(featureFlags.MULTI_PRODUCT_NAV_ENABLED)) {
          return 'AdminLayout';
        } else if (state.layout === 'app' && getters.hasFeature(featureFlags.MULTI_PRODUCT_NAV_ENABLED)) {
          return 'MultiProductLayout';
        }

        return null;
      }

    }),
  },
  methods: {
    resetToast() {
      this.$store.commit('setToast', {});
    }
  }
};
</script>


<style lang="scss">
#app {
  display: flex;
  flex-wrap: wrap;
  min-height: 100%;
}

.app__none {
  width: 100%;
  min-height: 100%;
}
</style>
