import { initIntercom } from '@/util/helpers/intercom';
import { initFrontChat } from '@/util/helpers/frontchat';
import { initNotifications } from '@/util/helpers/websocket';
import { inIframe } from '@/util/helpers/messages';
import { initPendo } from '@/util/helpers/pendo';

/**
 * Initializes anything that needs to be initialized at app load but does not need initialized immediately
 * Also used to initialize anything that relies on having user data
 * @param {object} route - The current Vue route
 * @param {object} store - The Vuex store
 */
export const initDeferred = async (route, store) => {
  const user = store.state.userData;
  // Track custom user dimensions.
  ga('set', 'dimension1', user.id);
  ga('set', 'dimension2', user.roles.join(', '));

  if (!inIframe()) {
    initIntercom({
      name: user.name,
      email: user.email,
    });
    await initFrontChat();
  }

  initNotifications(user);
  initPendo(user, store);
};
