import axios from 'axios';

//eslint-disable-next-line
const advanced = function () {
};

advanced.prototype = {
  get() {
    return new Promise((resolve, reject) => {
      axios.get(`advanced-exchanges/`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`advanced-exchanges/${id}`)
        .then(res => {
          if (res.data.errors) {
            reject(res);
          }

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateOrCreate(payload) {
    return new Promise((resolve, reject) => {
      axios.post('advanced-exchanges', payload)
        .then(res => {
          if (res.data.errors) {
            reject(res);
          }

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default advanced;
