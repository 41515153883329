import axios from 'axios';

const territories = {
  get() {
    return axios.get('/customs_territories')
      .then((res) => {
        if (res.data?.errors) {
          throw res.data.errors;
        }

        return res.data;
      });
  },
};

export default territories;
