import store from '@/store';
import currencyMap from '../../currencyMap';
import currency from '../../currency.js';
const currencyOutput = currency.data();
const currencyList = currencyOutput.currency;

const formatMoney = (value, countryCode) => {
  const code = countryCode || 'USD';
  let currencyData = currencyMap.get(code);

  if (currencyData === undefined) {
    currencyData = {
      'constant': 100,
      'decimals': 2
    };
  }

  if (value !== undefined) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: code }).format(value / currencyData.constant);
  }
  return value;
};

export default {

  methods: {

    //Change Text Color to light or dark based on background color
    textColorCheck: function(rgb) {
      var o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) /1000);
      if (o > 125) {
        return 'black';
      } else {
        return 'white';
      }
    },

    //Change normal or shorthand hex to RGB format
    hexToRgb: function(hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
      });

      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },

    generateLink: function(type, identity) {
      const userData = this.$store.state.userData;

      if (!userData?.shop) {
        return '';
      }

      if (userData.shop.provider === 'bigcommerce') {
        if (type === 'products') {
          type = 'products/edit';
        }

        return `https://${userData.shop.provider_domain}/manage/${type}/${identity}`;
      }

      const shopKey = userData.shop.provider_domain.slice(0, userData.shop.provider_domain.indexOf('.'));

      return `https://admin.shopify.com/store/${shopKey}/${type}/${identity}`;
    },

    loadImage: function(imageLink, size) {
      if (imageLink != undefined) {
        if (imageLink != null && imageLink != '') {
          return this.miniImage(imageLink, size);
        }
      }
      return '/img/icons/no-image.png';
    },

    imageBackup: function(e) {
      e.target.src = '/img/icons/no-image.png';
    },

    miniImage: function(link, size) {
      var spot,
        newLink;
      if (link === undefined || link === '') {
        return '/img/icons/no-image.png';
      }

      if (!link.includes('cdn.shopify.com')) {
        return link;
      }

      if (size === undefined) {
        size = 300;
      }

      spot = link.split('.');
      //If there is an extra period in there it would fail to parse, return link
      if (spot.length > 4) {
        return link;
      }

      //construct new link
      newLink = spot[0] + '.' + spot[1] + '.' + spot[2] + '_' + size + 'x.' + spot[3];
      return newLink;
    },
    getStore: function (storeName) {
      var key = storeName;
      if (localStorage[key] != undefined) {
        return JSON.parse(localStorage.getItem(key || null));
      }
    },
    setStore: function (storeName, storeData) {
      if (storeData != undefined) {
        localStorage.setItem(storeName, JSON.stringify(storeData));
      }
    },
    killStore: function (storeName) {
      localStorage.removeItem(storeName);
    },
    deKebab: function (value) {
      if (value === undefined) {
        return value;
      }
      if (!isNaN(value)) {
        value = value.toString();
      }
      while (value.indexOf('-') > -1) {
        value = value.replace('-', ' ');
        value = value.replace('and', '&');
      }
      return value;
    },
    chopFirstLetter: function(value) {
      return value.substring(0, 1);
    },
    deSlug: function(value) {
      if (value === undefined) {
        return value;
      }
      if (!isNaN(value)) {
        value = value.toString();
      }
      while (value.indexOf('_') > -1) {
        value = value.replace('_', ' ');
      }
      return value;
    },
    money(value, countryCode) {
      return formatMoney(value, countryCode);
    },
    simplifyMoneyFromUserInput(value, countryCode) {
      const code = countryCode || 'USD';
      let currencyData = currencyMap.get(code);

      if (currencyData === undefined) {
        currencyData = {
          'constant': 100,
          'decimals': 2
        };
      }
      return value * currencyData.constant;
    },
    specialMoney: function(value) {
      // TODO: refactor filter to be pure
      var currencyType = store.state.userData ? currencyList[store.state.userData.shop.currency] : null;

      if (currencyType === undefined || !currencyType) {
        currencyType = '$';
      }

      if (value !== undefined) {
        value = value.toString();
        if (value.length >= 4) {
          var firstHalf = value.slice(0,(value.length - 3));
          var hundred = value.substring(firstHalf.length, (value.length - 2));

          return currencyType + firstHalf + '.' + hundred + 'k';

        } else {
          return currencyType + value;
        }
      }
    }
  }
};
