import axios from 'axios';
import { stripObjectEscapes, removeDefaults, mergeDeep } from '@/util/helpers/customizations';

/**
 * An object representation of multiple types of customizations
 */
type Customizations = {
  theme?: Object,
  content?: Object,
  email?: Object,
}

type Item = {
  type: string,
  lang: string,
  content: any,
}

/**
 *
 * @param {Customizations} customizations
 * @param {String} type
 * @returns {Promise}
 */
const set = async (customizations: Customizations, type: String = 'style') => {
  if (typeof customizations !== 'object') {
    console.error('API: customizations.set() requires an object as an input.');
    return Promise.reject();
  }

  // Fire off requests in parallel
  const promises = Object.entries(customizations).map(([key, value]) => {
    return axios.post(`customization`, {
      lang: 'en',
      type: key === 'theme' ? type : key,
      content: JSON.stringify(value)
    });
  });

  // Remap returns back into an object
  return await Promise.all(promises)
    .then(responses => {
      return responses.reduce((accumulator: { errors?: any }, res) => {
        if (res.data.errors) {
          return {
            ...accumulator,
            errors: accumulator.errors ? [...accumulator.errors, ...res.data.errors] : res.data.errors
          };
        }

        if (res.data.customization) {
          const key = res.data.customization.type === 'style' ? 'theme' : res.data.customization.type;
          const parsed = JSON.parse(res.data.customization.content);
          return {
            ...accumulator,
            [key]: parsed
          };
        }

        return accumulator;
      }, {});
    });
};

export type CustomizationsApi = {
  addLanguage: (languageCode: any) => Promise<any>,
  content: {
    get: (lang: string) => Promise<any>,
    update: (existing: Object, updates: Object, lang: string) => Promise<any>,
  }
  get: () => Promise<any>,
  save: (customizations: Customizations) => Promise<any>,
  set: (customizations: Customizations, type: String) => Promise<any>,
  setContent: (content: Object) => Promise<any>,
}

const customizations: CustomizationsApi = {
  get() {
    return axios.get(`customization`)
      .then(res => {
        const { customizations } = res.data;
        const content = customizations
          .filter((item: Item) => item.type === 'content')
          .reduce((accumulator: Record<string, any>, current: { lang: string, content: any }) => {
            return {
              ...accumulator,
              [current.lang]: stripObjectEscapes(JSON.parse(current.content))
            };
          }, {});
        const theme = customizations.find((item: Item) => item.type === 'style');
        const tracking_theme = customizations.find((item: Item) => item?.type === 'style_tracking');
        const email = customizations.find((item: Item) => item.type === 'email');

        const formattedTheme = theme ? stripObjectEscapes(JSON.parse(theme.content)) : {};
        const formattedTrackingTheme = tracking_theme ? stripObjectEscapes(JSON.parse(tracking_theme?.content)) : {};

        return {
          ...res.data,
          customizations: {
            content,
            theme: formattedTheme,
            trackingTheme: formattedTrackingTheme,
            lang: customizations.find((item: Item) => item.type === 'content')?.lang,
            email: email ? JSON.parse(email.content) : {}
          }
        };
      });
  },
  set,
  setContent(content: Object) {
    if (typeof content !== 'object') {
      console.error('API: customizations.set() requires an object as an input.');
      return Promise.reject();
    }

    // Fire off requests in parallel
    const promises = Object.entries(content).map(([key, value]) => {
      return axios.post(`customization`, {
        lang: key,
        type: 'content',
        content: JSON.stringify(value)
      });
    });
    return Promise.all(promises)
      .then(responses => {
        return responses.reduce((accumulator: { errors?: any }, res) => {
          if (res.data.errors) {
            return {
              ...accumulator,
              errors: accumulator.errors ? [...accumulator.errors, ...res.data.errors] : res.data.errors
            };
          }

          if (res.data.customization) {
            const parsed = JSON.parse(res.data.customization.content);
            return {
              ...accumulator,
              [res.data.customization.lang]: parsed
            };
          }

          return accumulator;
        }, {});
      });
  },
  // Alias for policy rules page
  save: set,
  content: {
    /**
     * Get content customizations for a shop
     * @param {string} lang - The language to get, defaults to en
     * @returns {object}
     */
    get(lang = 'en') {
      return axios.get(`customization`)
        .then(({ data }) => {
          const row = data.customizations
            .find((item: Item) => item.type === 'content' && item.lang === lang);

          // Shops don't always have a content customization row,
          // if we don't we want to just return an empty object
          // and create a row if the user adds a customization
          return stripObjectEscapes(row?.content ? JSON.parse(row.content) : {});
        });
    },
    /**
     * Update a language with a set of changes
     * @param {object} existing - The existing content
     * @param {object} updates - The changes to make
     * @param {string} lang - The language to change
     * @returns {Promise}
     */
    update(existing: Object, updates: Object, lang = 'en') {
      if (!existing || !updates) {
        console.error('API: customizations.content.update() is missing required inputs.');
        return Promise.reject();
      }

      const defaults = import(`../../schemas/content/${lang}.json`);
      const merged = mergeDeep(existing, updates);
      const withoutDefaults = removeDefaults(merged, defaults);

      return axios.post(`customization`,
        {
          lang,
          type: 'content',
          content: JSON.stringify(withoutDefaults)
        })
        .then(({ data }) => {
          if (data.errors) {
            throw new Error(data.errors);
          }

          return JSON.parse(data.customization.content);
        });
    }
  },
  addLanguage(languageCode: any): Promise<any> {
    return axios.post('customizations/language', { languageCode })
      .then(res => res.data);
  }
};

export default customizations as CustomizationsApi;
