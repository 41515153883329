import { isSameDay, differenceInCalendarDays } from 'date-fns';
import { format } from 'date-fns-tz';
import currencyMap from '@/currencyMap';

export const capitalize = (value, allWords = false) => {
  if (!value) return '';
  value = value.toString();
  if (allWords) {
    return value.replace(/\b\w/g, l => l.toUpperCase());
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const relativeTime = (value) => {
  if (!value) {
    return '';
  }

  const convertedDate = new Date(`${value.replace(/\s+/g, 'T')}Z`);
  // Format for current day
  if (isSameDay(new Date(), convertedDate)) {
    return `Today at ${format(convertedDate, 'h:mm a')}`;
  }

  // Format for previous day
  if (differenceInCalendarDays(new Date(), convertedDate) === 1) {
    return `Yesterday at ${format(convertedDate, 'h:mm a')}`;
  }

  // Format for previous week
  if (differenceInCalendarDays(new Date(), convertedDate) < 7) {
    return `${format(convertedDate, 'EEEE')} at ${format(convertedDate, 'h:mm a')}`;
  }

  // Default formatting
  return format(convertedDate, 'LLL d, y, h:mm a z');
};


export const date = (value, dateFormat = 'LLL d, y') => {
  if (!value) {
    return '';
  }

  const convertedDate = new Date(`${value.replace(/\s+/g, 'T')}Z`);
  return format(convertedDate, dateFormat);
};

export const formatMoney = (value, countryCode = 'USD', options) => {
  const currencyData = currencyMap.get(countryCode);
  value = options && options.skipConversion ? value : value / currencyData.constant;
  if (value !== undefined && value !== '' && !Number.isNaN(+value)) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: countryCode, ...options }).format(+value);
  }
  return value;
};

export const currency = (value, countryCode) => {
  return formatMoney(value, countryCode);
};

export const currencyMajor = (value, countryCode) => {
  return formatMoney(value, countryCode, { maximumSignificantDigits: 1 });
};

export const currencyAbbreviated = (value, countryCode) => {
  const formatted = formatMoney(value, countryCode);
  // We're naively assuming locale is en-US since the currency formatter is
  const noCents = formatted.split('.')[0];

  // Has a thousands place
  if (noCents.length >= 5) {
    return `${noCents.slice(0, -4)}.${Math.round(noCents.slice(-3) / 100)}k`;
  }

  return noCents;
};

export const formatNumber = (value) => {
  if (!value && value !== 0) return '';
  if (typeof value !== 'number') return value;

  return value.toLocaleString();
};
