<template>
  <li class="navigation-item">
    <span
      v-if="item.disabled"
      :class="['navigation-item__item', { 'disabled': item.disabled }]"
    >
      <span class="navigation-item__content">
        <svg-icon
          v-if="icon && !isSubitem"
          class="navigation-item__icon"
          aria-hidden="true"
          :name="icon"
        />
        <svg-icon
          v-if="isSubitem && active"
          class="navigation-item__icon"
          aria-hidden="true"
          name="bar"
        />
        <span
          v-if="isSubitem && !active"
          class="navigation-item__faux-icon"
          aria-hidden="true"
        />
        <span class="navigation-item__name">{{ item.name }}</span>
      </span>
      <svg-icon
        v-if="item.items?.length"
        class="navigation-item__icon"
        aria-hidden="true"
        :name="chevron"
      />
    </span>
    <template v-else-if="item.items?.length">
      <base-button
        :class="['navigation-item__item', { 'active': active, 'disabled': item.disabled }]"
        type="tertiary"
        :disabled="active"
        @click="updateActiveItem(item)"
      >
        <span class="navigation-item__content">
          <svg-icon
            class="navigation-item__icon"
            aria-hidden="true"
            :name="icon"
          />
          <span class="navigation-item__name">{{ item.name }}</span>
          <status-badge
            v-if="item.bubble"
            type="notification"
          >{{ item.bubble }}</status-badge>
        </span>
        <svg-icon
          class="navigation-item__icon"
          aria-hidden="true"
          :name="chevron"
        />
      </base-button>
      <transition-expand>
        <ul
          v-if="active"
          class="navigation-item__subitems"
        >
          <template
            v-for="subitem in item.items"
            :key="subitem.name"
          >
            <navigation-item
              :item="subitem"
              :is-subitem="true"
              :active="activeSubitem == subitem.name"
              @update="updateActiveItem(item)"
              @update-subitem="updateSubitem"
            />
          </template>
        </ul>
      </transition-expand>
    </template>

    <template v-else>
      <base-button
        :class="['navigation-item__item', { 'active': active, 'sub-item': isSubitem }]"
        type="tertiary"
        @click="updateActiveItem(item)"
      >
        <span class="navigation-item__content">
          <svg-icon
            v-if="icon && !isSubitem"
            class="navigation-item__icon"
            aria-hidden="true"
            :name="icon"
          />
          <svg-icon
            v-if="isSubitem && active"
            class="navigation-item__icon"
            aria-hidden="true"
            name="bar"
          />
          <span
            v-if="isSubitem && !active"
            class="navigation-item__faux-icon"
            aria-hidden="true"
          />
          <span class="navigation-item__name">{{ item.name }}</span>
          <status-badge
            v-if="item.bubble"
            type="notification"
          >{{ item.bubble }}</status-badge>
        </span>
      </base-button>
    </template>
  </li>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { BaseButton, StatusBadge, SvgIcon } from '@loophq/ui';
import { useRoute, useRouter } from 'vue-router';
import { track, identify } from '@/util/helpers/segment';
import { trackingEvents, trackingEventProperties } from '@/constants/segment';

const route = useRoute();
const router = useRouter();

const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  item: {
    type: Object,
    required: true,
  },
  isSubitem: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['update', 'update-subitem', 'update-subitem-parent']);

const store = useStore();

const isOpen = ref(false);

const activeSubitem = ref('');

const chevron = computed(() => {
  return props.active ? 'chevron-up' : 'chevron-down';
});

const icon = computed(() => {
  if (!props.item.icon) return '';
  return props.item.bubble ? props.item.iconNew : props.item.icon;
});

onMounted(() => {
  // Get the active subitem on page load
  getActiveSubitem();
});

const getActiveSubitem = () => {
  const path = window.location.pathname;
  const routePath = path.endsWith('/') ? path.slice(0, -1) : path;

  if (!props.isSubitem && props.item.items?.length) {
    // Find the active subitem based off the route
    const subitem = props.item.items.find(item => item.url === routePath);
    if (subitem) {
      activeSubitem.value = subitem?.name;
      emit('update-subitem-parent', subitem);
    }
  }
};

// Collapse the navigation item if it's not active
watch(() => props.active, (newValue) => {
  if (!newValue) {
    isOpen.value = false;
  }
});

// Watch for route changes, e.g. when the user clicks the back button,
// or when a page re-routes the user, or user clicks on breadcrumb link
watch(
  () => route.path,
  async () => {
    const routePath = route.path.endsWith('/') ? route.path.slice(0, -1) : route.path;
    if (props.isSubitem && props.item.url === routePath) {
      activeSubitem.value = props.item.name;
      emit('update-subitem', props.item);
    }
    if (!props.isSubitem) {
      if (props.item.url === routePath) {
        emit('update', props.item);
      }
      if (props.item.items?.length) {
        const subitem = props.item.items.find(item => item.url === routePath);
        if (subitem) {
          activeSubitem.value = subitem?.name;
          emit('update-subitem-parent', subitem);
        }
      }
    }
  }
);

const updateActiveItem = (item) => {
  if (props.isSubitem) {
    activeSubitem.value = item.name;
    emit('update-subitem', item);
  } else {
    isOpen.value = !isOpen.value;
    emit('update', item);
    emit('update-subitem-parent', '');
    if (item.items?.length) {
      activeSubitem.value = '';
      const subitem = props.item.items.find(item => item.url === props.item.url);
      activeSubitem.value = subitem?.name;
      emit('update-subitem-parent', subitem);
    }
  }

  identify(store.state.userData, store.state.userData.shop);
  track(trackingEvents.NAV_ITEM_CLICKED, {
    navItem: item.name,
    isSubitem: props.isSubitem,
    version: trackingEventProperties.ADMIN_NAV_UPDATED_V1,
  });

  if (item.external) {
    window.open(item.url, '_blank');
  } else {
    router.push(item.url);
  }
};

const updateSubitem = (subitem, routePath = '') => {
  if (routePath) {
    const activeItem = props.item.items.find(item => item.url === routePath);
    activeSubitem.value = activeItem?.name;
    emit('update-subitem-parent', activeItem, routePath);
  } else {
    activeSubitem.value = subitem?.name;
    emit('update-subitem-parent', subitem);
  }
};
</script>

<style lang="scss" scoped>
$block: '.navigation-item';
$navItemNeutral: #d8d8df;
$backgroundAlpha4: rgba(255, 255, 255, 4%);
$backgroundAlpha8: rgba(255, 255, 255, 8%);
$backgroundAlpha12: rgba(255, 255, 255, 12%);

#{$block} {
  &__item {
    width: 100% !important;
    display: flex;
    justify-content: space-between;

    &.disabled {
      border-radius: 8px;
      color: var(--color-neutral-400);
      padding: var(--spacing-8);
      cursor: not-allowed;
    }
  }

  :deep(.base-button__text) {
    color: $navItemNeutral;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: var(--spacing-12);
  }

  &__name {
    font-family: Inter, var(--font-system);
    font-size: 14px;
    text-align: left;
  }

  :deep(.base-button.tertiary) {
    padding: var(--spacing-8);
    border-radius: 8px;

    &:hover,
    &:focus-visible {
      background-color: $backgroundAlpha4;
    }

    &:active {
      background-color: $backgroundAlpha8;
    }
  }

  :deep(.base-button.tertiary).active:not(.sub-item) {
    background-color: $backgroundAlpha4;
    cursor: auto;

    .base-button__text {
      color: #fff;
    }
  }

  :deep(.base-button.tertiary).sub-item {
    border-radius: 8px;
    font-weight: 500;
    padding: var(--spacing-8);
    color: var(--color-neutral-300);
    line-height: 20px;

    &.disabled {
      color: var(--color-neutral-400);
      cursor: not-allowed;
    }

    &.active {
      color: $navItemNeutral;
      padding-left: var(--spacing-8);

      &:hover {
        color: #fff;
      }
    }

    &:hover,
    &:focus-visible {
      background-color: $backgroundAlpha4;
      color: $navItemNeutral;
    }
  }

  :deep(.svg-icon).rotate-90 {
    transform: rotate(90deg);
  }

  &__faux-icon {
    width: 20px;
    height: 20px;
  }

  &__subitems {
    padding-top: var(--spacing-8);
  }

  :deep(.status-badge.notification) {
    background: var(--color-bloop-500);
  }

  @media screen and (width <= 1440px), screen and (width <= 1024px) {
    &__name {
      font-size: 12px;
    }
  }
}
</style>
