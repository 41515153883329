export const creditBonusTypes = {
  FLAT_FEE: 'flat_fee',
  RETURN_VALUE: 'return_value'
};

export const creditBonusOutcomes = {
  STORE_CREDIT: 'store_credit',
  UPSELL: 'upsell',
  SHOP_LATER: 'shop_later'
};

export const creditBonusOutcomeShopLater = {
  amount: '0.00',
  isActive: false,
  maxAmount: '0.00',
  maxAmountEnabled: false,
  outcome: creditBonusOutcomes.SHOP_LATER,
  type: creditBonusTypes.FLAT_FEE,
};

export const creditBonusMaxAmounts = {
  FLAT_FEE: 200,
  RETURN_VALUE: 100
};

