import axios from 'axios';
import changeCase from 'change-object-case';

const bundles = {
  get({ page = 1, search = '' }) {
    return axios.get(`bundles`, {
      params: {
        page,
        search
      }
    })
      .then((res) => res.data);
  },
  save({ type, bundle }) {
    bundle = changeCase.snakeKeys(bundle);
    const url = type === 'edit' ? `bundles/${bundle.id}` : 'bundles';
    const verb = type === 'edit' ? 'put' : 'post';
    return axios[verb](url, bundle)
      .then((res) => res.data);
  },
  getById(id) {
    return axios.get(`bundles/${id}`)
      .then((res) => res.data);
  },
  delete(id) {
    return axios.delete(`bundles/${id}`)
      .then((res) => res.data);
  },
  getProducts(term) {
    const url = term ? `products?filter=${term}` : `products`;
    return axios.get(url)
      .then((res) => res.data);
  },
  importBulk(csv) {
    const formData = new FormData();
    formData.append('csv', csv);
    return axios.post('bundles/import', formData)
      .then((res) => res.data);
  },
  export() {
    return axios.get('bundles/export')
      .then((res) => res.data);
  }
};

export default bundles;
