<template>
  <div class="navigation-wrapper">
    <div
      class="navigation-wrapper__backdrop"
      aria-hidden="true"
      :class="{ active: menuActive }"
      @click="menuActive = !menuActive"
    ></div>
    <navigation-sidebar
      ref="sidebar"
      class="navigation-wrapper__sidebar"
      :active="menuActive"
      :class="{ active: menuActive }"
    />
    <navigation-header
      ref="header"
      class="navigation-wrapper__bar"
    >
      <template #menubutton>
        <button
          v-if="userData"
          type=""
          class="navigation-wrapper__menu-trigger"
          @click="menuHandler"
        >
          <svg
            v-if="!menuActive"
            class="navigation-wrapper__menu-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line
              x1="3"
              y1="12"
              x2="21"
              y2="12"
            />
            <line
              x1="3"
              y1="6"
              x2="21"
              y2="6"
            />
            <line
              x1="3"
              y1="18"
              x2="21"
              y2="18"
            />
          </svg>
          <svg-icon
            v-else
            name="close"
            class="navigation-wrapper__menu-icon"
          />
        </button>
      </template>
    </navigation-header>
  </div>
</template>

<script setup>
import { SvgIcon } from '@loophq/ui';
import { onMounted, ref } from 'vue';
import NavigationHeader from './NavigationHeader.vue';
import NavigationSidebar from './NavigationSidebar.vue';

defineProps({
  userData: {
    type: Object,
    required: false
  }
});

const menuActive = ref(false);
const sidebar = ref(null);
const header = ref(null);

const resizeHandler = () => {
  if (sidebar.value?.$el?.clientWidth) {
    document.documentElement.style.setProperty('--nav-width-new-admin', sidebar.value?.$el?.clientWidth + 'px');
  }
};

const menuHandler = () => {
  menuActive.value = !menuActive.value;

  if (menuActive.value) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
};


onMounted(() => {
  if (header.value?.$el?.clientHeight) {
    document.documentElement.style.setProperty('--nav-bar-height', header.value?.$el?.clientHeight + 'px');
  }

  resizeHandler();
  window.addEventListener('resize', () => {
    resizeHandler();
  });
});
</script>

<style lang="scss" scoped>
$sticky-nav-height: 3.5rem;

.navigation-wrapper {
  $parent: &;

  position: fixed;
  z-index: 1001;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  &__backdrop {
    display: none;
  }

  &__bar {
    width: 100%;
    position: sticky;
    top: 0;
    right: 0;
    height: 72px;
    background: var(--color-nav-background);
    pointer-events: all;
  }

  &__header {
    flex-shrink: 0;
    width: 100%;
    height: $sticky-nav-height;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
  }

  &__logo {
    width: auto;
    height: auto;
    max-width: 88px;
  }

  &__sidebar {
    width: 260px;
  }

  &__close {
    display: none;
  }

  &__list {
    padding: 0.75rem;
  }

  &__menu-icon {
    color: white;
  }

  &__skeleton {
    padding: 0.75rem 1.5rem;
  }
}

@media screen and (width <= 960px) {
  .navigation-wrapper {
    $parent: &;

    &__backdrop {
      display: block;
      position: absolute;
      top: 56px;
      z-index: 5;
      width: 100%;
      height: 100%;
      background: rgba(74, 78, 90, 50%);
      opacity: 0;
      transition: opacity 0.16s cubic-bezier(0.55, 0.085, 0.68, 0.53);
      pointer-events: none;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &__rounded-corner-wrapper {
      display: none;
    }

    &__bar {
      width: 100%;
      padding: var(--spacing-12);
    }

    &__sidebar {
      overflow: hidden;
      overflow-y: auto;
      width: 260px;
      z-index: 10;
      opacity: 0;
      backface-visibility: hidden;
      perspective: 1000px;
      will-change: transform, opacity;
      transform: translate3d(-100%, 0, 0);
      transition: transform 0.16s cubic-bezier(0.55, 0.085, 0.68, 0.53), opacity 0.16s cubic-bezier(0.55, 0.085, 0.68, 0.53);

      &.active {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    &__x {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
}

@media screen and (width <= 1440px) {
  .navigation-wrapper {
    &__sidebar {
      width: 240px;
    }
  }
}

//if the sidebar is active, append overflow hidden to the body
.navigation-wrapper__sidebar.active {
  :deep(body) & {
    overflow: hidden;
  }
}

</style>
