/**
 * TODO: Deprecated, remove when return reason improvements is live
 */
import _ from 'lodash';
import axios from 'axios';

const reasons = function () {
  // This is what the endpoint expects
  /* const requestTemplate = {
		id: '', // If updating an existing reason
		reason: '',
		order: ''
	} */
};

reasons.prototype = {
  get() {
    return new Promise((resolve, reject) => {
      axios.get(`reasons/`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateOrder(items, mappedItems) {
    return items.map((item, itemIndex) => {
      const index = mappedItems.findIndex(mapped => mapped.id === item.id);
      let children = {};
      let update = {};
      if (item.children && item.children.length || mappedItems[index] && mappedItems[index].options && mappedItems[index].options.length) {
        children = {
          children: this.updateOrder(item.children, mappedItems[index].options)
        };
      }

      // Set update flag if the new index doesn't match the current order number
      if (itemIndex !== mappedItems[index].order) {
        update = {
          update: true
        };
      }
      return Object.assign({}, item, { order: index }, children, update);
    });
  },
  updateExisting(existingItems, newItems) {
    let updated = _.cloneDeep(existingItems);

    newItems.forEach(item => {
      // Check if we already have this saved in our list
      const existing = updated.some(upd => upd.id === item.id);

      if (existing) {
        updated = updated.map(upd => {
          if (upd.id === item.id) {
            return item;
          }
          return upd;
        });
      } else {
        // Add on the the end if we didn't have it before
        updated = [
          ...updated,
          item
        ];
      }
    });
    return updated;
  },
  removeExisting(existingItems, newItems) {
    let updated = _.cloneDeep(existingItems);

    newItems.forEach(item => {
      updated = updated.filter(upd => upd.id !== item.id);
    });
    return updated;
  },
  save(reasons) {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(reasons)) {
        console.error('API: reasons.save() requires an array as an input.');
        reject();
      }

      const mapNew = (item) => {
        const { reason, order, parent_id, children, comments, comments_required } = item;
        const newItem = {
          reason,
          order,
          parent_id,
          comments,
          comments_required,
          delete: item.delete // No destructuring because delete is a reserved word
        };

        if (children.length > 0) {
          return {
            ...newItem,
            children: children.map(mapNew)
          };
        }

        return newItem;
      };

      const toSave = reasons
        .filter(item => !item.parent_id.toString().startsWith('new-'))
        .map(item => {
          // Existing reason, go ahead and add id so we can update
          if (!item.id.toString().startsWith('new-')) {
            return item;
          }

          // Map new items
          return mapNew(item);
        });

      axios.post('reasons/batch', toSave)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  groups: {
    mapToApi({ name, randomize, reasons, types }) {
      return {
        title: name,
        randomize,
        product_types: types.map((type) => type.id),
        reasons: reasons.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.id]: cur.children.map((child) => child.id)
          };
        }, {})
      };
    },
    mapFromApi(group) {
      const { id, title, randomize, product_types, ordered_reasons } = group;

      return {
        id,
        name: title,
        randomize,
        default: group.default,
        types: product_types,
        reasons: Object.keys(ordered_reasons)
          .map((parentId) => {
            return {
              id: +parentId,
              children: ordered_reasons[parentId]
            };
          })
      };
    },
    get() {
      return axios.get('reason-groups')
        .then((res) => {
          return res.data.map(this.mapFromApi);
        });
    },
    add(group) {
      const mapped = this.mapToApi(group);

      return axios.post('reason-groups', mapped)
        .then((res) => res.data);
    },
    update(group) {
      if (!group || !group.id) {
        console.error('reasons.groups.update(): Missing group object or group object does not have id.');
        return Promise.reject();
      }
      const mapped = this.mapToApi(group);

      return axios.patch(`reason-groups/${group.id}`, mapped)
        .then((res) => this.mapFromApi(res.data));
    },
    remove(group) {
      if (!group || !group.id) {
        console.error('reasons.groups.remove(): Missing group object or group object does not have id.');
        return Promise.reject();
      }

      return axios.delete(`reason-groups/${group.id}`)
        .then((res) => res.data);
    }
  }
};

export default reasons;
