import env from '@/env';

/**
 * Initializes Pendo if the Pendo key is set
 */
export const initPendo = (user, store) => {

  if (env('VITE_PENDO_KEY')) {

    // pendo init
    (function(apiKey) {
      (function(p,e,n,d,o) {
        var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for (w=0,x=v.length;w<x;++w)(function(m) {
          o[m]=o[m]||function() {o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};
        })(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);
      })(window,document,'script','pendo');
    })(env('VITE_PENDO_KEY'));

    // in your authentication promise handler or callback
    // eslint-disable-next-line no-undef
    pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        shopId: store.getters.shop.id,
        jobTitle: user.job_title,
      },
      account: {
        id: store.getters.shop.id,
        name: store.getters.shop.name,
        creationDate: store.getters.shop.created_at,
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
        shopType: store.getters.shop.type,
        sandbox: store.getters.shop.sandbox,
        installSource: store.getters.shop.source
      }
    });

  }
};
