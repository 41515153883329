import axios from 'axios';
import store from '@/store/index';

const sortReasons = (reasons) => {
  return reasons
    .sort((a, b) => a.order - b.order)
    .map(item => {
      if (item.children) {
        return {
          ...item,
          children: sortReasons(item.children)
        };
      }

      return item;
    });
};

export const mapReasonFromApi = (item) => {
  const language = store.state.reasons.language ?? 'en';
  const {
    id,
    order,
    copy,
    children,
    comments,
    comments_required,
    provider_reason,
    has_smart_recommendation,
    smart_recommendation,
  } = item;

  return {
    id,
    order,
    reason: copy[language] ?? copy.en,
    comments: {
      active: comments,
      required: comments_required
    },
    ...(children ? { children: children.map(mapReasonFromApi) } : {}),
    provider_reason,
    has_smart_recommendation,
    smart_recommendation,
  };
};

/**
 * Check if an id is for a new reason
 * @param {string|number} str - The id to evaluate
 * @returns {boolean}
 */
const isNewId = (str) => {
  return str.toString().startsWith('new-');
};

const mapReasonToApi = (item) => {
  const language = store.state.reasons.language ?? 'en';
  const {
    id,
    reason,
    comments,
    children,
    provider_reason,
    smart_recommendation,
    has_smart_recommendation,
  } = item;

  return {
    ...(!isNewId(id) ? { id } : {}),
    reason,
    comments: comments?.active,
    comments_required: comments?.required,
    provider_reason,
    language,
    ...(children?.length ? { children: children.map(mapReasonToApi) } : {}),
    has_smart_recommendation,
    smart_recommendation,
  };
};

export default {
  get() {
    return axios.get(`/reasons`)
      .then((res) => {
        if (res.data.errors) {
          throw res.data.errors;
        }

        return res.data
          .map(mapReasonFromApi);
      });
  },
  save(reasons) {
    if (!Array.isArray(reasons)) {
      console.error('API: reasons.save() requires an array as an input.');
      Promise.reject();
    }

    return axios.post('reasons/batch', reasons.map(mapReasonToApi))
      .then((res) => {
        if (res.data.errors) {
          throw res.data.errors;
        }

        return res.data
          .map(mapReasonFromApi);
      });
  },
  groups: {
    mapToApi({ name, randomize, reasons, types }) {
      return {
        title: name,
        randomize,
        product_types: types.map((type) => type.id),
        ordered_reasons: reasons
      };
    },
    mapFromApi(group) {
      const { id, title, randomize, product_types, ordered_reasons } = group;

      return {
        id,
        name: title,
        randomize,
        default: group.default,
        types: product_types,
        reasons: sortReasons(ordered_reasons)
      };
    },
    get() {
      return axios.get('reason-groups')
        .then((res) => {
          return res.data.map(this.mapFromApi);
        });
    },
    add(group) {
      const mapped = this.mapToApi(group);

      return axios.post('reason-groups', mapped)
        .then((res) => this.mapFromApi(res.data));
    },
    update(group) {
      if (!group || !group.id) {
        console.error('reasons.groups.update(): Missing group object or group object does not have id.');
        return Promise.reject();
      }
      const mapped = this.mapToApi(group);

      return axios.patch(`reason-groups/${group.id}`, mapped)
        .then((res) => this.mapFromApi(res.data));
    },
    remove(group) {
      if (!group || !group.id) {
        console.error('reasons.groups.remove(): Missing group object or group object does not have id.');
        return Promise.reject();
      }

      return axios.delete(`reason-groups/${group.id}`)
        .then((res) => {
          if (res.data) {
            return res.data;
          }

          throw new Error('Delete failed');
        });
    }
  }
};
