import domestic from './domestic'; // ship by loop - easypost
import worldwide from './worldwide'; // ship by loop - easyship
import SBL from '@/constants/shipByLoop';

export default {
  namespaced: true,
  state: {
    title: null,
    slug: null,
    isNewIntegration: null,
    isLoading: false,
    isConnecting: false,
  },
  mutations: {
    setSlug(state, slug) {
      state.slug = slug;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setIsNewIntegration(state, isNewIntegration) {
      state.isNewIntegration = isNewIntegration;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsConnecting(state, isConnecting) {
      state.isConnecting = isConnecting;
    }
  },
  getters: {
    slug: state => state.slug,
    title: state => state.title,
    isNewIntegration: state => state.isNewIntegration,
    isLoading: state => state.isLoading,
    isConnecting: state => state.isConnecting,
  },
  actions: {
    configureInstance({ commit }, { id, slug, isNewIntegration }) {
      commit('setSlug', slug);
      commit('setTitle', SBL.SBL_INSTANCE_MAP[slug].title);
      commit('setIsNewIntegration', isNewIntegration);
      commit(`settings/shipByLoop/${SBL.SBL_INSTANCE_MAP[slug].storeName}/setShippingSettingId`, id, { root: true });
    }
  },
  modules: {
    domestic,
    worldwide,
  },
};
