import axios from 'axios';
import changeCase from 'change-object-case';
import { buildJSONFromRules, buildRulesFromJSON } from '@/util/helpers/workflows/rules';
changeCase.options = { recursive: true, arrayRecursive: true };

const mapFromApi = (workflow) => {
  return {
    active: workflow.active,
    createdAt: workflow.created_at,
    description: workflow.description,
    name: workflow.name,
    order: workflow.order,
    rules: workflow.rules,
    trigger: workflow.trigger,
    updatedAt: workflow.updated_at,
    uuid: workflow.uuid,
  };
};

export default {
  /**
	 * Get all workflows
	 * @param {object} params
	 * @param {boolean} params.active
	 * @param {number} params.page
	 * @param {string} params.sort
	 * @returns {Promise<Workflow[]>}
	 */
  async getAll(params) {
    const res = await axios.get(`/workflows`, { params: changeCase.snakeKeys(params) });
    return changeCase.toCamel(res.data);
  },
  /**
	 * Get a single workflow
	 * @param {string} uuid
	 * @returns {Promise<Workflow>}
	 */
  async getById(uuid) {
    if (!uuid) {
      return Promise.reject('API: Workflows.getById() requires a uuid to fetch.');
    }

    const res = await axios.get(`/workflows/${uuid}`);
    res.data.rules = buildRulesFromJSON(res.data?.rules.rules);
    return mapFromApi(res.data);
  },
  /**
	 * Create a workflow
	 * @param {Workflow} workflow
	 * @returns {Promise<Workflow>}
	 */
  async create(workflow) {
    const request = { ...workflow };
    request.rules = buildJSONFromRules(workflow.rules);
    const res = await axios.post(`/workflows`, request);
    res.data.rules = buildRulesFromJSON(res.data?.rules.rules);
    return mapFromApi(res.data);
  },
  /**
	 * Update a workflow
	 * @param {Workflow} workflow
	 * @returns {Promise<Workflow>}
	 */
  async update(workflow) {
    const request = { ...workflow };
    request.rules = buildJSONFromRules(workflow.rules);
    const res = await axios.patch(`/workflows/${workflow.uuid}`, request);
    res.data.rules = buildRulesFromJSON(res.data?.rules.rules);
    return mapFromApi(res.data);
  },
  async updatePriority(positionData) {
    const res = await axios.put(`/workflows/priority`, {
      newPosition: positionData.newPosition,
      oldPosition: positionData.oldPosition,
      id: positionData.id,
    });
    return changeCase.toCamel(res.data);
  },
  /**
	 * Remove a workflow
	 * @param {Workflow} workflow
	 * @returns {Promise<boolean>}
	 */
  async remove(workflow) {
    const res = await axios.delete(`/workflows/${workflow.uuid}`);
    return res.data;
  },
  /**
   * Get a list of Workflow modules
   */
  async getModules() {
    const res = await axios.get(`/workflows/modules`);
    return res.data;
  },
  /**
   * Build Workflow test trigger context
   * @param {string[]} workflowIds
   * @param {int[]} orderLineItemIds
   */
  async buildTestTriggerContext(workflowIds, orderLineItemIds) {
    const res = await axios.post(`/workflows/build-test-trigger-context`, {
      workflow_ids: workflowIds,
      order_line_item_ids: orderLineItemIds
    });
    return res.data;
  },
  /**
   * Test workflow evaluation
   * @param {string[]} workflowIds
   * @param {object} workflowTriggerContextVariables
   * @param {{}} workflowTriggerContextLineItemVariables
   * @returns {Promise<object>}
   */
  async testEvaluate(workflowIds, context) {
    const res = await axios.post(`/workflows/test-evaluate`, {
      workflow_ids: workflowIds,
      ...context
    });

    return res.data;
  }
};
