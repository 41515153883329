import { isEqual } from 'lodash';
import ShippingServices from '@/util/api/shippingServices';
import { camelCase } from 'lodash';
import moveItemToFront from '@/util/helpers/moveItemToFront';

export default {
  namespaced: true,
  state: {
    availableIntegrations: [],
    enabled: {
      integrations: [],
      carriers: [],
      serviceTypes: [],
    },
    loading: false,
    connecting: false,
    carrierAccounts: {},
    unsavedCarrierAccounts: {},
    loadingCarrierAccounts: false,
    noEnabledShippingServices: false,
    easypostShipmentOptions: [],
    errorFields: [],
    easypostShippingValidationAddresses: [],
    easypostDestinationCarrierServices: [],
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setAvailableIntegrations(state, availableIntegrations) {
      state.availableIntegrations = availableIntegrations;
    },
    setEnabled(state, enabled) {
      state.enabled = enabled;
    },
    setConnecting(state, connecting) {
      state.connecting = connecting;
    },
    setCarriers(state, payload) {
      Object.entries(payload).forEach(([key, data]) => {
        // only modify previously initialized states
        if (!(key in state)) return;
        state[key] = data;
      });
    },
    setShipmentOptions(state, shipmentOptions) {
      state.easypostShipmentOptions = shipmentOptions;
    },
    setErrorFields(state, errorFields) {
      errorFields.map((field) => {
        field.field = camelCase(field.field);
        return field;
      });
      state.errorFields = errorFields;
    },
    setEasypostShippingValidationAddresses(state, addresses) {
      state.easypostShippingValidationAddresses = addresses;
    },
    setDestinationCarrierServices(state, carrierServices) {
      state.easypostDestinationCarrierServices = carrierServices;
    }
  },
  actions: {
    async loadShippingIntegrations({ commit }) {
      try {
        commit('setLoading', true);
        let [allIntegrations, data] = await Promise.all([
          ShippingServices.integrations.getAll(),
          ShippingServices.settings.get()
        ]);
        // Product requested to make Ship By Loop the first integration
        // Product requested to make EasyPost the second integration
        allIntegrations = moveItemToFront(allIntegrations, 'slug', 'easy-post');
        allIntegrations = moveItemToFront(allIntegrations, 'slug', 'ship-by-loop-worldwide');
        allIntegrations = moveItemToFront(allIntegrations, 'slug', 'ship-by-loop');

        const { integrations, carriers, serviceTypes, integrationSync } = data;

        if (!integrationSync) {
          commit('setToast', {
            message: 'Unable to sync carriers',
            type: 'warning',
          }, { root: true });
        }
        commit('setAvailableIntegrations', allIntegrations);
        commit('setEnabled', { integrations, carriers, serviceTypes });
        commit('setLoading', false);
      } catch (error) {
        commit('setToast', {
          message: 'Unable to load integrations',
          type: 'warning',
        }, { root: true });
        commit('setLoading', false);
        return Promise.reject(error);
      }
    },
    async connectShippingIntegration({ commit, dispatch }, { integrationId, integrationName, config }) {
      try {
        commit('setConnecting', true);
        const payload = Object
          .entries(config)
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
        await ShippingServices.integrations.connect(integrationId, payload);
        dispatch('loadShippingIntegrations');
        commit('setConnecting', false);
        commit('setErrorFields', []);
        commit('setToast', {
          mesage: `Connected ${integrationName} account, loading carriers`,
          type: 'success',
        }, { root: true });
      } catch (error) {
        commit('setErrorFields', error.response.data.fields);
        commit('setConnecting', false);
        const message = error?.response?.data?.errors?.join(', ');
        commit('setToast', {
          message: `Unable to connect to ${integrationName}. ${message ?? ''}`,
          type: 'error',
        }, { root: true });
      }
    },
    async saveShippingIntegration({ commit }, { integrationId, integrationName, changeSet, carrierId, config, integrationSlug }) {
      commit('setConnecting', true);

      if (changeSet.carriers?.length) {
        await ShippingServices.carriers.toggle(integrationId, changeSet.carriers);
      }

      if (changeSet.serviceTypes?.length) {
        const changes = changeSet.serviceTypes.map((type) => {
          return {
            id: type.id,
            toEnable: type.base
              .map((item) => {
                const change = type.changes.find((change) => item.id === change.id);
                return change ?? item;
              })
              .filter((item) => item.enabled),
            ...('preferred' in type ? { preferred: type.preferred } : {}),
            ...('shipperAddress' in type ? { shipperAddress: type.shipperAddress } : {})
          };
        });

        await ShippingServices.carriers.update(integrationId, changes);
      }

      if (changeSet.allOptions?.length) {
        await ShippingServices.easypostShipmentOptions.createOrUpdate(carrierId, changeSet.allOptions, integrationSlug);
      }

      if (config !== null) {
        await ShippingServices.integrations.update(integrationId, config);
      }

      commit('setConnecting', false);
      commit('setToast', {
        message: `Saved ${integrationName} configuration`,
        type: 'success',
      }, { root: true });
    },
    loadCarrierAccounts({ state, commit }, { policyId, shippingSettingId }) {
      commit('setCarriers', { loadingCarrierAccounts: true });
      ShippingServices.returnPolicyCarriers.getPolicyCarriers(policyId, shippingSettingId)
        .then((policyCarriers) => {
          if (!Object.keys(state.carrierAccounts).length || Number(state.carrierAccounts.id) !== Number(policyId)) {
            // set once before save (keep unsaved fallback consistent)
            commit('setCarriers', { carrierAccounts: policyCarriers });
          }
          commit('setCarriers', { unsavedCarrierAccounts: policyCarriers });
        })
        .catch((error) => {
          const msg = `Unable to retrieve carriers for policy ${policyId}. ${error}`;
          console.error(msg);
          commit('setToast', {
            message: msg,
            type: 'error',
          }, { root: true });
        })
        .finally(() => {
          commit('setCarriers', { loadingCarrierAccounts: false });
        });
    },
    updateCarrierAccounts({ state, commit }, { carrier, discardChanges }) {
      if (discardChanges) {
        return commit('setCarriers', { unsavedCarrierAccounts: state.carrierAccounts });
      }
      const carriers = [...state.unsavedCarrierAccounts.carriers];
      const indexToUpdate = carriers.findIndex(({ id, carrierAccountId }) => (
        `${id}-${carrierAccountId}` === `${carrier.id}-${carrier.carrierAccountId}`
      ));
      carriers.splice(indexToUpdate, 1, carrier);
      commit('setCarriers', { unsavedCarrierAccounts: { ...state.unsavedCarrierAccounts, carriers } });
    },
    saveCarrierAccounts({ state, commit }, { policyId }) {
      ShippingServices.returnPolicyCarriers.updatePolicyCarriers(policyId, state.unsavedCarrierAccounts)
        .then(() => commit('setCarriers', { carrierAccounts: {} }))
        .catch((error) => {
          const msg = `Unable to save carriers for policy ${policyId}. ${error}`;
          console.error(msg);
          commit('setToast', {
            message: msg,
            type: 'error',
          }, { root: true });
        });
    },
    async loadEasyPostShipmentOptions({ commit }, { carrierId, integrationSlug }) {
      try {
        commit('setLoading', true);
        const { easyPostShipmentOptions } = await ShippingServices.easypostShipmentOptions.get(carrierId, integrationSlug);

        commit('setShipmentOptions', easyPostShipmentOptions);
        commit('setLoading', false);
      } catch (error) {
        commit('setToast', {
          message: 'Unable to load EasyPost shipment options',
          type: 'warning',
        }, { root: true });
        commit('setLoading', false);
        return Promise.reject(error);
      }
    },
    async loadEasyPostShippingValidationAddresses({ commit }) {
      try {
        commit('setLoading', true);
        const { addresses } = await ShippingServices.easypostDestinationSupport.getAddresses();

        commit('setEasypostShippingValidationAddresses', addresses);
        commit('setLoading', false);
      } catch (error) {
        commit('setToast', {
          message: 'Unable to load addresses to validate shipping',
          type: 'warning',
        }, { root: true });
        commit('setLoading', false);
        return Promise.reject(error);
      }
    },
    async validateShippingSupport({ commit }, payload) {
      commit('setDestinationCarrierServices', []);

      try {
        commit('setLoading', true);
        const { carrierServices } = await ShippingServices.easypostDestinationSupport.validateShippingSupport(payload);

        commit('setDestinationCarrierServices', carrierServices);
        commit('setLoading', false);
      } catch (error) {
        let errorMessage = 'Unable to load EasyPost carrier support';
        if (error.response?.status === 422) {
          errorMessage = error.response?.data?.code === 'EASYPOST_API_KEY_REJECTED' ? 'Unable to load EasyPost carrier support. Invalid API Key' : 'Unable to load EasyPost carrier support. Invalid Request.';
        }

        commit('setToast', {
          message: errorMessage,
          type: 'error',
        }, { root: true });
        commit('setLoading', false);
        return false;
      }
    },
    async updateDefaultIntegration({ commit, dispatch }, integrationId) {
      commit('setLoading', true);

      try {
        await ShippingServices.integrations.setDefault(integrationId);
        await dispatch('loadShippingIntegrations');
      } catch {
        commit('setToast', {
          message: 'Unable to update default integration',
          type: 'error',
        }, { root: true });
        commit('setLoading', false);
      }

      commit('setLoading', false);
    }
  },
  getters: {
    availableIntegrations: state => state.availableIntegrations,
    enabled: state => state.enabled,
    carriers: state => state.carriers,
    loading: state => state.loading,
    connecting: state => state.connecting,
    carrierAccounts: state => state.carrierAccounts,
    unsavedCarrierAccounts: state => state.unsavedCarrierAccounts,
    loadingCarrierAccounts: state => state.loadingCarrierAccounts,
    noEnabledShippingServices: state => state.noEnabledShippingServices,
    hasCarrierAccountsChanges: state => !isEqual(state.carrierAccounts, state.unsavedCarrierAccounts),
    easypostShipmentOptions: state => state.easypostShipmentOptions,
    errorFields: state => state.errorFields,
    easypostShippingValidationAddresses: state => state.easypostShippingValidationAddresses,
    easypostDestinationCarrierServices: state => state.easypostDestinationCarrierServices,
    selectedIntegration: (state) => (slug) => {
      const filterEnabled = state.enabled.integrations.filter((i) => i.shippingIntegration.slug === slug);
      const filterAvailable = state.availableIntegrations.filter((i) => i.slug === slug);

      if (filterEnabled.length > 0) {
        const selectedIntegration = filterEnabled[0].shippingIntegration;
        return {
          ...selectedIntegration,
          configuration: {
            ...selectedIntegration.configuration,
            values: filterEnabled[0]?.configuration ?? null,
          },
          returnPolicyTitles: filterEnabled[0]?.returnPolicyTitles ?? [],
        };
      } else if (filterAvailable.length > 0) {
        const selectedIntegration = filterAvailable[0];
        return {
          ...selectedIntegration,
          returnPolicyTitles: [],
        };
      }

      return null;
    },
  }
};
