import { ruleTypes } from '../../workflow-rules';

export default {
  'if': [
    {
      'includes': [
        {
          'var': ''
        },
        null,
      ]
    },
    {
      'if': [
        {
          'missing': [
            ''
          ]
        },
        {
          'type': ruleTypes.USER_INPUT,
          'value': JSON.stringify({
            'id': '',
            'label': null,
            'response': {
              'isActive': false,
              'message': null
            },
            'type': 'bool',
          })
        },
        {
          'if': [
            {
              '==': [
                {
                  'var': ''
                },
                true
              ]
            },
            [{
              'type': ruleTypes.BLANK_ACTION,
              'value': JSON.stringify({}),
            }]
          ]
        }
      ]
    }
  ]
};
