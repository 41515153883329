import store from '@/store/index';
import { featureFlags } from '@/util/schemas/featureFlags';
import { returnlyTagsStaticTemplates } from '@/util/schemas/workflows';

export const staticTemplateGroups = {
  RETURNLY_TAGS: {
    name: 'Returnly tags',
    isLegacy: true,
    description: 'These static workflows replicate the functionality of Returnly\'s legacy tag-based logic and cannot be edited. To learn more about each workflow\'s functionality, <a href="https://help.loopreturns.com/article/261-workflow-playbooks-returnly-tag-logic/" target="_blank">visit our help center</a>.',
    templates: returnlyTagsStaticTemplates,
    availableIf: () => store.getters.hasFeature(featureFlags.STATIC_WORKFLOW_TEMPLATES_RETURNLY_TAGS)
  }
};
