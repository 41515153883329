export default [
  {
    path: '/not-authorized',
    component: () => import('@/pages/Errors/403'),
    name: 'ErrorsNotAuthorized',
    meta: {
      layout: 'app',
      title: 'Not Authorized',
    }
  },
];
