import * as LDClient from 'launchdarkly-js-client-sdk';
import env from '@/env';

const context = {
  kind: 'user',
  key: 'anonymous-key'
};

//initialize ld client but fail gracefully if no key is present so ap still runs locally
if (env('VITE_LAUNCHDARKLY_CLIENT_ID')) {
  var ldClient = LDClient.initialize(env('VITE_LAUNCHDARKLY_CLIENT_ID'), context);
}

//check if ld client is initialized and ready otherwise return false to prevent app from breaking
export const ldFlagVariation = async (flag) => {
  if (ldClient) {
    await ldClient.waitUntilReady();
    return ldClient.variation(flag);
  } else {
    return false;
  }
};
