import { createAuth0 } from '@auth0/auth0-vue';
import env from '@/env';
import Cookies from 'js-cookie';
import store from '@/store';

const useInMemoryCache = () => {
  const cache = {};
  return {
    get(key) {
      const cacheEntry = cache[key];
      if (!cacheEntry) return;
      return cacheEntry;
    },
    set(key, entry) {
      if (key.includes('openid profile email')) {
        if (entry.body.access_token) {
          store.commit('setToken', entry.body.access_token);
          Cookies.set('loop-admin-token', entry.body.access_token);
        }
      }
      cache[key] = entry;
    },
    remove(key) {
      delete cache[key];
    },
    allKeys() {
      return Object.keys(cache);
    }
  };
};

export const auth0 = createAuth0({
  domain: env('VITE_AUTH0_DOMAIN'),
  clientId: env('VITE_AUTH0_CLIENT_ID'),
  cache: useInMemoryCache(),
  authorizationParams: {
    redirect_uri: env('VITE_AUTH0_REDIRECT_URI'),
    audience: env('VITE_AUTH0_AUDIENCE'),
  },
});
