import OrdersApi from '@/util/api/orders';

export default {
  namespaced: true,
  state: {
    updating: false,
    search: '',
    page: 1,
    pageAmount: 5,
    maxPages: 1,
    searchResults: {
      customers: [],
      orders: [],
      page_info: {}
    },
    modalVisible: false,
    activeOrder: null,
    activeCustomer: null,
    activeOrderDeepLink: null,
    activeOrderGiftDeepLink: null,
    emailSending: false
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    attachDeeplinksToActiveOrder(state, { returnDeeplink, giftDeeplink }) {
      state.activeOrder.returnDeeplink = returnDeeplink;
      state.activeOrder.giftDeeplink = giftDeeplink;
    }
  },
  actions: {
    getPage({ commit }, { page }) {
      commit('setProperty', { key: 'page', value: page });
    },
    async initiateSearch({ state, dispatch, commit }, { query, page_info, search_key }) {
      commit('setProperty', { key: 'updating', value: true });
      commit('setProperty', { key: 'search', value: query });
      commit('setProperty', { key: 'searchKey', value: search_key });

      const searchPayload = {
        query,
        search_key,
      };

      if (page_info && Object.keys(page_info).length) {
        searchPayload.page_info = page_info;
      }

      try {
        const res = await OrdersApi.search(searchPayload);

        if (
          (res.data.customers?.length === 0 || res.data.orders?.length === 0) &&
          res.data.page_info.cursor_next
        ) {
          dispatch('initiateSearch', {
            query,
            page_info: {
              cursor_next: res.data.page_info.cursor_next
            }
          });
        } else {
          commit('setProperty', {
            key: 'updating',
            value: false
          });
          commit('setProperty', {
            key: 'searchResults',
            value: res.data
          });

          if (res.data.page_info.has_next_page) {
            commit('setProperty', {
              key: 'maxPages',
              value: state.page + 1
            });
          }
        }
      } catch (e) {
        commit('setProperty', {
          key: 'updating',
          value: false
        });
      }
    },
    toggleModalVisibility({ commit }, { visible }) {
      if (!visible) {
        // reset state when modal closes
        commit('setProperty', {
          key: 'activeOrderGiftDeepLink',
          value: null,
        });
        commit('setProperty', {
          key: 'activeOrderDeepLink',
          value: null,
        });
      }

      commit('setProperty', {
        key: 'modalVisible',
        value: visible
      });
    },
    setActiveOrder({ commit }, { order }) {
      commit('setProperty', { key: 'activeOrder', value: order });
    },
    setActiveCustomer({ commit }, { customer }) {
      commit('setProperty', { key: 'activeCustomer', value: customer });
    },
    async createDeepLinks({ commit }, { zip, order }) {
      try {
        // We resolve both of these createDeepLink promises synchronously, because
        // we would otherwise create a race condition with the order lookup process.
        const returnDeeplink = await OrdersApi.createDeepLink({
          zip,
          order,
          gift: false,
        });

        const giftDeepLink = await OrdersApi.createDeepLink({
          zip,
          order,
          gift: true,
        });

        if (!returnDeeplink.data || !giftDeepLink.data) {
          console.error('Failed to create deep link');
          return false;
        }

        commit('attachDeeplinksToActiveOrder', {
          returnDeeplink: returnDeeplink.data.url,
          giftDeeplink: giftDeepLink.data.url,
        });

        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async sendDeeplinkEmail({ commit }, { email, lookupData, gift }) {
      commit('setProperty', { key: 'emailSending', value: true });

      try {
        const deepLinkSendResponse = await OrdersApi.sendDeepLinkEmail({
          email,
          lookupData,
          gift
        });

        if (deepLinkSendResponse.data) {
          commit('setProperty', { key: 'emailSending', value: false });
          return true;
        }

        commit('setProperty', { key: 'emailSending', value: false });
        return false;
      } catch (e) {
        commit('setProperty', { key: 'emailSending', value: false });
        console.error(e);
        return false;
      }
    }
  },
  getters: {
    searchResults(state) {
      return {
        customers: state.searchResults.customers || [],
        orders: state.searchResults.orders || [],
        page_info: state.searchResults.page_info || {}
      };
    },
    updating(state) {
      return state.updating;
    },
    getPagination(state) {
      return {
        page: state.page,
        previous: {
          disabled: state.page === 1
        },
        next: {
          disabled:
            (
              state.searchResults.customers?.length < state.pageAmount ||
              state.searchResults.orders?.length < state.pageAmount
            ) ||
            state.maxPages === state.page
        }
      };
    },
    getModalVisible(state) {
      return state.modalVisible;
    },
    getActiveOrder(state) {
      return state.activeOrder;
    },
    getActiveCustomer(state) {
      return state.activeCustomer;
    },
    getSearch(state) {
      return state.search;
    }
  }
};
