export default {
  namespaced: true,
  state: {
    newReturns: 0,
    updates: []
  },
  mutations: {
    setUpdate(state, data) {
      const notReturnsList = !['/returns', '/returns/', '/returns/feed', '/returns/feed/'].includes(window.location.pathname);
      if (data.type === 'return-created' && notReturnsList) {
        state.newReturns++;
      }

      state.updates = [
        data,
        ...state.updates
      ];
    },
    resetReturns(state) {
      state.newReturns = 0;
    },
    resetEvents(state) {
      state.newReturns = 0;
      state.updates = [];
    }
  }
};
