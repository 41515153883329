const GREEN_RETURN = {
  'if': [
    {
      'and': [
        {
          'missing': [
            'order.tags'
          ]
        },
        {
          'missing': [
            'lineItem.tags'
          ]
        }
      ]
    },
    null,
    {
      'if': [
        {
          'or': [
            {
              'includes': [
                {
                  'var': 'order.tags'
                },
                [
                  'rtly-green-return'
                ]
              ]
            },
            {
              'includes': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-green-return'
                ]
              ]
            }
          ]
        },
        [
          {
            'type': 'KeepDonate',
            'field': 'keepDonate',
            'value': '{"outcome":"keep","immediate":false}',
            'display': 'Keep item'
          }
        ],
        null
      ]
    }
  ]
};

const SHIP_TO = {
  'if': [
    {
      'and': [
        {
          'missing': [
            'order.tags'
          ]
        },
        {
          'missing': [
            'lineItem.tags'
          ]
        }
      ]
    },
    null,
    {
      'if': [
        {
          'or': [
            {
              'startsWith': [
                {
                  'var': 'order.tags'
                },
                [
                  'rtly-ship-to'
                ]
              ]
            },
            {
              'startsWith': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-ship-to'
                ]
              ]
            }
          ]
        },
        [
          {
            'type': 'DestinationOverride',
            'field': 'destinationOverride',
            'value': {
              'cat': [
                '{"destinationSlugs":[',
                {
                  'getValuesListFromPrefix': [
                    [
                      {
                        'var': 'order.tags'
                      },
                      {
                        'var': 'lineItem.tags'
                      }
                    ],
                    'rtly-ship-to:'
                  ]
                },
                ']}'
              ]
            },
            'display': 'Destination override: Extract from tags'
          }
        ],
        null
      ]
    }
  ]
};

const NON_RETURNABLE_SKU = {
  'if': [
    {
      'missing': [
        'lineItem.tags',
        'lineItem.sku'
      ]
    },
    null,
    {
      'if': [
        {
          'and': [
            {
              'startsWith': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-non-returnable-sku'
                ]
              ]
            },
            {
              'includes': [
                {
                  'getValuesArrayFromPrefix': [
                    [
                      {
                        'var': 'lineItem.tags'
                      }
                    ],
                    'rtly-non-returnable-sku:'
                  ]
                },
                {
                  'var': 'lineItem.sku'
                }
              ]
            }
          ]
        },
        [
          {
            'type': 'ReviewReject',
            'field': 'reviewReject',
            'value': '{"outcome":"reject"}',
            'display': 'Reject item'
          }
        ],
        null
      ]
    }
  ]
};

const NON_RETURNABLE = {
  'if': [
    {
      'missing': [
        'lineItem.tags'
      ]
    },
    null,
    {
      'if': [
        {
          'and': [
            {
              'includes': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-non-returnable'
                ]
              ]
            }
          ]
        },
        [
          {
            'type': 'ReviewReject',
            'field': 'reviewReject',
            'value': '{"outcome":"reject"}',
            'display': 'Reject item'
          }
        ],
        null
      ]
    }
  ]
};

const POLICY_WINDOW_DAYS = {
  'if': [
    {
      'and': [
        {
          'missing': [
            'order.tags'
          ]
        },
        {
          'missing': [
            'lineItem.tags'
          ]
        }
      ]
    },
    null,
    {
      'if': [
        {
          'or': [
            {
              'startsWith': [
                {
                  'var': 'order.tags'
                },
                [
                  'rtly-policy-window-days'
                ]
              ]
            },
            {
              'startsWith': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-policy-window-days'
                ]
              ]
            }
          ]
        },
        [
          {
            'type': 'ReturnWindow',
            'field': 'returnWindow',
            'value': {
              'cat': [
                '{"returnWindow":',
                {
                  'getValueFromPrefix': [
                    [
                      {
                        'var': 'order.tags'
                      },
                      {
                        'var': 'lineItem.tags'
                      }
                    ],
                    'rtly-policy-window-days:'
                  ]
                },
                '}'
              ]
            },
            'display': 'Return Window: Extract from tags'
          }
        ],
        null
      ]
    }
  ]
};

const SHIPPING_RATE = {
  'if': [
    {
      'and': [
        {
          'missing': [
            'order.tags'
          ]
        },
        {
          'missing': [
            'lineItem.tags'
          ]
        }
      ]
    },
    null,
    {
      'if': [
        {
          'or': [
            {
              'startsWith': [
                {
                  'var': 'order.tags'
                },
                [
                  'rtly-shipping-rate!'
                ]
              ]
            },
            {
              'startsWith': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-shipping-rate!'
                ]
              ]
            }
          ]
        },
        [
          {
            'type': 'HandlingFee',
            'field': 'fees',
            'value': {
              'cat': [
                '{"amount":',
                {
                  'getValueFromPrefix': [
                    [
                      {
                        'var': 'order.tags'
                      },
                      {
                        'var': 'lineItem.tags'
                      }
                    ],
                    'rtly-shipping-rate!:'
                  ]
                },
                ',"applicationOption":"override","type":"flat"}'
              ]
            },
            'display': 'Fees: Extract from tags'
          }
        ],
        {
          'if': [
            {
              'or': [
                {
                  'startsWith': [
                    {
                      'var': 'order.tags'
                    },
                    [
                      'rtly-shipping-rate'
                    ]
                  ]
                },
                {
                  'startsWith': [
                    {
                      'var': 'lineItem.tags'
                    },
                    [
                      'rtly-shipping-rate'
                    ]
                  ]
                }
              ]
            },
            [
              {
                'type': 'HandlingFee',
                'field': 'fees',
                'value': {
                  'cat': [
                    '{"amount":',
                    {
                      'getValueFromPrefix': [
                        [
                          {
                            'var': 'order.tags'
                          },
                          {
                            'var': 'lineItem.tags'
                          }
                        ],
                        'rtly-shipping-rate:'
                      ]
                    },
                    ',"applicationOption":"add","type":"flat"}'
                  ]
                },
                'display': 'Fees: Extract from tags'
              }
            ],
            null
          ]
        }
      ]
    }
  ]
};

const GIFT_CARD = {
  'if': [
    {
      'and': [
        {
          'missing': [
            'customer.tags'
          ]
        },
        {
          'missing': [
            'order.tags'
          ]
        },
        {
          'missing': [
            'lineItem.tags'
          ]
        }
      ]
    },
    null,
    {
      'if': [
        {
          'or': [
            {
              'includes': [
                {
                  'var': 'order.tags'
                },
                [
                  'rtly-gc'
                ]
              ]
            },
            {
              'includes': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-gc'
                ]
              ]
            }
          ]
        },
        [
          {
            'type': 'ExcludeOutcome',
            'field': 'excludeOutcomes',
            'value': '{"keepItem":false,"exchange":false,"instantExchange":false,"inlineExchange":false,"shopNow":false,"advancedExchange":false,"refund":true}',
            'display': 'Exclude outcomes: Refund'
          }
        ],
        null
      ]
    }
  ]
};

const GIFT_CARD_FREE_SHIPPING = {
  'if': [
    {
      'and': [
        {
          'missing': [
            'return.outcome'
          ]
        },
        {
          'and': [
            {
              'missing': [
                'customer.tags'
              ]
            },
            {
              'missing': [
                'order.tags'
              ]
            },
            {
              'missing': [
                'lineItem.tags'
              ]
            }
          ]
        }
      ]
    },
    null,
    {
      'if': [
        {
          'and': [
            {
              'or': [
                {
                  'includes': [
                    {
                      'var': 'customer.tags'
                    },
                    [
                      'rtly-gc:offer-free-shipping'
                    ]
                  ]
                },
                {
                  'includes': [
                    {
                      'var': 'order.tags'
                    },
                    [
                      'rtly-gc:offer-free-shipping'
                    ]
                  ]
                },
                {
                  'includes': [
                    {
                      'var': 'lineItem.tags'
                    },
                    [
                      'rtly-gc:offer-free-shipping'
                    ]
                  ]
                }
              ]
            },
            {
              'includes': [
                {
                  'var': 'return.outcome'
                },
                'gift'
              ]
            }
          ]
        },
        [
          {
            'type': 'HandlingFee',
            'field': 'fees',
            'value': '{"type":"waive"}',
            'display': 'Waive all fees'
          }
        ],
        null
      ]
    }
  ]
};

const EXCHANGE_BLOCK = {
  'if': [
    {
      'and': [
        {
          'missing': [
            'customer.tags'
          ]
        },
        {
          'missing': [
            'order.tags'
          ]
        },
        {
          'missing': [
            'lineItem.tags'
          ]
        }
      ]
    },
    null,
    {
      'if': [
        {
          'or': [
            {
              'includes': [
                {
                  'var': 'customer.tags'
                },
                [
                  'rtly-exchange:block'
                ]
              ]
            },
            {
              'includes': [
                {
                  'var': 'order.tags'
                },
                [
                  'rtly-exchange:block'
                ]
              ]
            },
            {
              'includes': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-exchange:block'
                ]
              ]
            }
          ]
        },
        [
          {
            'type': 'ExcludeOutcome',
            'field': 'excludeOutcomes',
            'value': '{"exchange":true,"instantExchange":true,"inlineExchange":true,"shopNow":true,"advancedExchange":true}',
            'display': 'Exclude outcomes: Exchange, Instant Exchange, Inline Exchange, Shop Now, Advanced Exchange'
          }
        ],
        null
      ]
    }
  ]
};

const EXCHANGE_ONLY = {
  'if': [
    {
      'and': [
        {
          'missing': [
            'customer.tags'
          ]
        },
        {
          'missing': [
            'order.tags'
          ]
        },
        {
          'missing': [
            'lineItem.tags'
          ]
        }
      ]
    },
    null,
    {
      'if': [
        {
          'or': [
            {
              'includes': [
                {
                  'var': 'customer.tags'
                },
                [
                  'rtly-exchange:only'
                ]
              ]
            },
            {
              'includes': [
                {
                  'var': 'order.tags'
                },
                [
                  'rtly-exchange:only'
                ]
              ]
            },
            {
              'includes': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-exchange:only'
                ]
              ]
            }
          ]
        },
        [
          {
            'type': 'ExcludeOutcome',
            'field': 'excludeOutcomes',
            'value': '{"exchange":false,"instantExchange":false,"inlineExchange":false,"shopNow":false,"advancedExchange":false,"keepItem":true,"storeCredit":true,"refund":true}',
            'display': 'Exclude outcomes: Keep Item, Store Credit, Refund'
          }
        ],
        null
      ]
    }
  ]
};

const MANUAL_REVIEW = {
  'if': [
    {
      'and': [
        {
          'missing': [
            'customer.tags'
          ]
        },
        {
          'missing': [
            'order.tags'
          ]
        },
        {
          'missing': [
            'lineItem.tags'
          ]
        }
      ]
    },
    null,
    {
      'if': [
        {
          'or': [
            {
              'includes': [
                {
                  'var': 'customer.tags'
                },
                [
                  'rtly-manual-review'
                ]
              ]
            },
            {
              'includes': [
                {
                  'var': 'order.tags'
                },
                [
                  'rtly-manual-review'
                ]
              ]
            },
            {
              'includes': [
                {
                  'var': 'lineItem.tags'
                },
                [
                  'rtly-manual-review'
                ]
              ]
            }
          ]
        },
        [
          {
            'type': 'ReviewReject',
            'field': 'reviewReject',
            'value': '{"outcome":"review"}',
            'display': 'Manual review'
          }
        ],
        null
      ]
    }
  ]
};

export default {
  GREEN_RETURN,
  SHIP_TO,
  NON_RETURNABLE_SKU,
  NON_RETURNABLE,
  POLICY_WINDOW_DAYS,
  SHIPPING_RATE,
  GIFT_CARD,
  GIFT_CARD_FREE_SHIPPING,
  EXCHANGE_BLOCK,
  EXCHANGE_ONLY,
  MANUAL_REVIEW
};
