export default function useMultiSelect() {
  const flatten = (obj, item) => {
    if (item.children) {
      flatten(obj, item.children);
    }
    if (Array.isArray(item)) {
      obj.push(...item);
    } else {
      obj.push(item);
    }
  };
  const flattenNestedArray = (nestedArray) => {
    const allItems = [];
    nestedArray.forEach(item => {
      flatten(allItems, item);
      if (item.children) {
        item.children.forEach(child => flatten(allItems, child));
      }
    });
    return allItems;
  };
  return {
    flattenNestedArray,
  };
}
