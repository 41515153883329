import { ruleTypes } from '../../workflow-rules';

export default {
  'if': [
    {
      'includes': [
        {
          'var': ''
        },
        null,
      ]
    },
    [{
      'type': ruleTypes.BLANK_ACTION,
      'value': JSON.stringify({}),
    }],
    null
  ]
};
