<template>
  <div
    class="mini-nav-dropdown"
  >
    <div class="mini-nav-dropdown__container">
      <div class="mini-nav-dropdown__user">
        <user-avatar
          data-testid="mini-nav-avatar"
          size="large"
        >
          {{ userInitials }}
        </user-avatar>
        <div class="mini-nav-dropdown__user-info">
          <base-text
            type="text-button"
            class="mini-nav-dropdown__user-shop"
          >
            {{ userData.shop.name }}
            <flag-icon
              :iso="userCountry"
              width="20px"
              :data-test-country="userCountry"
            />
          </base-text>
          <base-text
            type="text-body"
            class="mini-nav-dropdown__user-name"
          >
            {{ userData.name }}
          </base-text>
        </div>
        <instance-switcher
          v-if="showInstanceSwitcher"
          @close="emit('close')"
        />
      </div>
      <div class="mini-nav-dropdown__links">
        <line-item
          v-for="link in links"
          :key="link.name"
          :tag="link.external ? 'a' : 'router-link'"
          :url="link.url"
          :icon="link.icon"
          :external="link.external"
          @click="handleMenuClick(link)"
        >
          {{ link.name }}
        </line-item>
        <hr
          class="mini-nav-dropdown__logout-hr"
        />
        <line-item
          class="mini-nav-dropdown__logout"
          tag="router-link"
          url="/logout"
          :destructive="true"
          :icon="'logout'"
        >
          Logout
        </line-item>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { BaseText, UserAvatar, FlagIcon } from '@loophq/ui';
import { useStore } from 'vuex';
import { permissions } from '@/util/schemas/permissions';
import LineItem from '@/components/layout/MiniNav/LineItem';
import InstanceSwitcher from './InstanceSwitcher.vue';
import { identify, track } from '@/util/helpers/segment';
import { trackingEventProperties, trackingEvents } from '@/constants/segment';

const store = useStore();

const props = defineProps({
  userData: {
    type: Object,
    required: true,
  }
});

const emit = defineEmits(['close']);

const userCountry = computed(() => {
  return props.userData.shop.country.toLowerCase();
});

const userInitials = computed(() => {
  return props.userData.name
    .split(' ')
    .map((word) => word[0])
    .join('');
});

const portalLink = computed(() => {
  return store.getters['customerPortalLink'];
});

const instances = computed(() => {
  return store.getters['instances'];
});

const showInstanceSwitcher = computed(() => {
  return props.userData &&
    instances.value.length > 1;
});

const isAccountVisible = computed(() => {
  return store.getters.hasPermission(permissions.VIEW_USERS);
});

const handleMenuClick = (link) => {
  emit('close');

  identify(store.state.userData, store.state.userData.shop);
  track(trackingEvents.NAV_ITEM_CLICKED, {
    navItem: 'Mini nav dropdown links',
    action: link.name,
    version: trackingEventProperties.ADMIN_NAV_UPDATED_V1,
  });
};

const links = ref([
  {
    name: 'Account',
    url: '/settings/account',
    isVisible: isAccountVisible,
    icon: 'user'
  },
  {
    external: true,
    name: 'Release notes',
    url: 'https://help.loopreturns.com/category/235-release-notes',
    newWindow: true,
    icon: 'file-text'
  },
  {
    external: true,
    name: 'Shopper return portal',
    url: portalLink,
    newWindow: true,
    icon: 'external-link'
  },
  {
    external: true,
    name: 'Loop status page',
    url: 'https://status.loopreturns.com/',
    newWindow: true,
    icon: 'external-link'
  },
]);

</script>

<style lang="scss" scoped>
.mini-nav-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 340px;
  border-radius: 8px;
  box-shadow: var(--modal-shadow);
  transition: transform 0.2s ease-in-out;
  background: white;

  &__container {
    padding: var(--spacing-8);
  }

  &__user {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-12);
    background: var(--color-neutral-50);
    padding: var(--spacing-24);
    border-radius: 8px;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    align-items: center;
  }

  &__user-shop {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
  }

  &__user-flag {
    width: 20px;
    height: 100%;
    border-radius: 4px;
  }

  &__user-name {
    color: var(--color-neutral-600);
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    margin-top: var(--spacing-16);
  }

  &__logout {
    &-hr {
      border-top: 1px var(--color-neutral-200);
      margin: 0 -8px;
    }
  }
}

:deep(.flag-icon svg) {
  border-radius: 4px;
  width: unset;
  height: unset;
}

</style>
