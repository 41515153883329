import Bundles from '@/util/api/bundle';
import changeCase from 'change-object-case';
import { cloneDeep, groupBy } from 'lodash';

export default {
  namespaced: true,
  state: {
    bundles: [],
    products: [],
    productsNoFilter: [],
    bundle: null,
    search: '',
    variantsByProductId: {},
    updating: false,
    page: 1,
    lastPage: null,
    pageLimit: 25,
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    setBundles(state, bundles) {
      state.bundles = bundles;
    },
    setBundle(state, bundle) {
      state.bundle = bundle;
    },
    setProducts(state, products) {
      state.products = products;
    },
    setProductsNoFilter(state, products) {
      state.productsNoFilter = products;
    },
    setVariantsByProduct(state, variants) {
      state.variantsByProductId = variants;
    }
  },
  actions: {
    async getBundles({ commit, state }, options = {}) {
      let search = '';
      if (options.query?.search) {
        search = options.query?.search;
      } else if (state.search) {
        search = state.search;
      }

      let page = 1;
      if (!isNaN(options.query?.page)) {
        page = parseInt(options.query.page);
      } else if (!isNaN(state.page)) {
        page = parseInt(state.page);
      }

      commit('setProperty', { key: 'updating', value: true });
      const bundles = await Bundles.get({ page, search });
      if (bundles.length === 0) {
        page = 1;
        commit('setProperty', { key: 'lastPage', value: page });
      }

      if (bundles.length < state.pageLimit) {
        commit('setProperty', { key: 'lastPage', value: page });
      }

      if (history.pushState) {
        const url = window.location.href.split('?')[0];
        history.pushState({}, null, `${url}?page=${page}&search=${search}`);
      }
      commit('setProperty', { key: 'page', value: page });
      commit('setProperty', { key: 'search', value: search });
      commit('setBundles', bundles);
      commit('setProperty', { key: 'updating', value: false });
    },
    async saveBundle({ commit }, bundle) {
      await Bundles.save(bundle);
      commit('setToast', {
        message: 'Bundle saved successfully',
        type: 'success',
      }, { root: true });
    },
    async getBundleData({ commit, dispatch }, route) {
      let bundle = await Bundles.getById(route.params.id);
      bundle = changeCase.toCamel(bundle);
      commit('setBundle', bundle);
      await dispatch('setInitialVariants', bundle.products);
      await dispatch('getProducts');
    },
    async setInitialVariants({ commit }, products) {
      const variants = groupBy(products, 'productId');
      commit('setVariantsByProduct', variants);
    },
    async deleteBundle({ commit }, id) {
      await Bundles.delete(id);
      commit('setToast', {
        message: 'Bundle successfully removed',
        type: 'success',
      }, { root: true });
    },
    async getProducts({ commit, state }, term) {
      if (!term && state.productsNoFilter.length !== 0) {
        commit('setProducts', state.productsNoFilter);
        return;
      }

      let products = await Bundles.getProducts(term);
      products = changeCase.toCamel(products);
      if (term) {
        commit('setProducts', products);
      } else {
        commit('setProducts', products);
        commit('setProductsNoFilter', products);
      }
    },
    importBulk(store, csv) {
      return Bundles.importBulk(csv);
    },
    async exportBundles() {
      return Bundles.export();
    },
    updateVariant({ commit, state }, { productId, variant }) {
      const variants = cloneDeep(state.variantsByProductId);
      let productVariants = variants[productId];
      if (!productVariants) {
        productVariants = [variant];
      } else {
        const index = productVariants.findIndex(item => item.variantId === variant.variantId);
        if (index > -1) {
          productVariants.splice(index, 1);
        } else {
          productVariants.push(variant);
        }
      }
      variants[productId] = productVariants;
      commit('setVariantsByProduct', variants);
    },
    updateVariantGroup({ commit, state }, { productId, variantGroup }) {
      const variants = cloneDeep(state.variantsByProductId);
      let productVariants = variants[productId];
      if (productVariants && productVariants.length > 0) {
        productVariants = [];
      } else {
        productVariants = variantGroup;
      }
      variants[productId] = productVariants;
      commit('setVariantsByProduct', variants);
    },
    async getPageData({ dispatch }) {
      await dispatch('setInitialVariants', null);
      await dispatch('getProducts');
    },
    getPage({ commit, dispatch }, { page }) {
      commit('setProperty', { key: 'page', value: page });
      dispatch('getBundles');
    },
    updateSearch({ commit, dispatch }, { search }) {
      commit('setProperty', { key: 'search', value: search });
      commit('setProperty', { key: 'page', value: 1 });
      dispatch('getBundles');
    },
  },
  getters: {
    bundles: state => state.bundles,
    bundle: state => state.bundle,
    products: state => state.products,
    variantsByProduct: state => productId => state.variantsByProductId[productId],
    variants: (state) => {
      let variants = [];
      Object.keys(state.variantsByProductId).map(item => {
        variants = variants.concat(state.variantsByProductId[item]);
      });
      return variants;
    },
    updating: state => state.updating,
    getPagination(state) {
      return {
        page: state.page,
        previous: {
          disabled: state.page === 1
        },
        next: {
          disabled: state.bundles.length < state.pageLimit || state.lastPage === state.page
        }
      };
    },
  }
};
