import Store from '@/store';
import { permissions } from '@/util/schemas/permissions';
import { featureFlags } from '@/util/schemas/featureFlags';
import { shopSettings } from '@/util/schemas/ShopSettings';

//These are never used in state and can remain outside of the store to avoid direct mutations
const items = [
  {
    name: 'Home',
    url: '/returns',
    icon: 'home',
    home: true,
  },
  {
    name: 'Returns',
    url: '/returns',
    icon: 'inbox',
    iconNew: 'inbox-new',
    routeName: 'ReturnsList',
    bubble: () => Store.state.returns.feed.newReturns || null,
    isVisible: () => {
      return Store.getters.hasPermission(permissions.MANAGE_RETURNS);
    },
    items: [
      {
        name: 'Return processing',
        url: '/returns',
        parent: 'Returns',
      },
      {
        name: 'Warranty processing',
        url: '/returns/warranties',
        parent: 'Orders',
        isVisible: () => Store.getters.getSetting('WARRANTIES_ENABLED'),
      },
      {
        name: 'Blocklist & allowlist',
        url: '/listings',
        parent: 'Returns',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_LISTINGS),
      },
      {
        name: 'Find an order',
        url: '/returns/find-order',
        parent: 'Returns',
      },
      {
        name: 'Return events feed',
        url: '/returns/feed',
        parent: 'Returns',
      },
    ],
  },
  {
    name: 'Analytics',
    url: '/analytics/reports',
    icon: 'activity',
    iconNew: 'activity',
    routeName: 'AdvancedInsights',
    isVisible: () => Store.getters.hasPermission(permissions.VIEW_ANALYTICS),
    items: [
      {
        name: 'Reports',
        url: '/analytics/reports',
        disabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
        badge: () => Store.getters.hasPermission(permissions.MANAGE_REPORTING) ? 'Beta' : null,
      },
      {
        name: 'Overview',
        url: '/analytics/overview',
        parent: 'Analytics',
        disabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
      },
      {
        name: 'Value impact',
        url: '/analytics/loopvalueimpact',
        parent: 'Analytics',
        disabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
      },
      {
        name: 'Products',
        url: '/analytics/products',
        parent: 'Analytics',
        disabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
      },
      {
        name: 'Customers',
        url: '/analytics/customers',
        parent: 'Analytics',
        disabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
      },
      {
        name: 'Operations',
        url: '/analytics/operations',
        parent: 'Analytics',
        disabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
      },
      {
        name: 'Shipping & logistics',
        url: '/analytics/shippinglogistics',
        parent: 'Analytics',
        disabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
      },
      {
        name: 'Happy Returns',
        url: '/analytics/happyreturns',
        parent: 'Analytics',
        isVisible: () => Store.getters.getSetting('INTEGRATION_HAPPY_RETURNS_ENABLED'),
        disabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
      },
      {
        name: 'Happy Returns shipments',
        url: '/analytics/happyreturnsshipments',
        parent: 'Analytics',
        isVisible: () => Store.getters.getSetting('INTEGRATION_HAPPY_RETURNS_ENABLED'),
        disabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
      },
    ]
  },
  {
    name: 'Policy settings',
    url: '/settings/general',
    icon: 'shield',
    routeName: 'PolicyList',
    isVisible: () => {
      return Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS);
    },
    items: [
      {
        name: 'General',
        url: '/settings/general',
        parent: 'Policy settings',
        isVisible: () => {
          return Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS);
        },
      },
      {
        name: 'Return policies',
        url: '/settings/return-policy',
        parent: 'Policy settings',
        routeName: 'ReturnPolicy',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURN_POLICIES),
      },
      {
        name: 'Warranty policies',
        url: '/settings/warranty-policy',
        permission: permissions.MANAGE_WARRANTIES,
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_WARRANTIES) && Store.getters.getSetting('WARRANTIES_ENABLED'),
      },
      {
        name: 'Workflows',
        url: '/workflows',
        parent: 'Policy settings',
        routeName: 'Workflows',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_WORKFLOWS),
      },
      {
        name: 'Policy rules',
        url: '/settings/rules',
        parent: 'Policy settings',
        routeName: 'PolicyRules',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_POLICY_RULES),
      },
      {
        name: 'Blocklist & allowlist',
        url: '/listings',
        parent: 'Policy settings',
        isVisible: () => () => Store.getters.hasPermission(permissions.MANAGE_LISTINGS),
      },
      {
        name: 'Bundles',
        url: '/settings/bundles',
        parent: 'Policy settings',
        isVisible: () => {
          return Store.getters.getSetting('BUNDLES_ENABLED') && Store.getters.getSetting('BUNDLE_PROVIDER') === 'loop' && Store.getters.hasPermission(permissions.MANAGE_BUNDLES);
        }
      },
      {
        name: 'Shop Now',
        url: '/shop-now',
        parent: 'Policy settings',
        isVisible: () => {
          return Store.getters.getSetting('SHOP_NOW_ENABLED') && Store.getters.hasPermission(permissions.MANAGE_SHOP_NOW);
        },
      },
      {
        name: 'Advanced Exchanges',
        url: '/advanced-exchanges',
        parent: 'Policy settings',
        isVisible: () => {
          return Store.getters.getSetting('ADVANCED_EXCHANGES_ENABLED') && Store.getters.hasPermission(permissions.MANAGE_ADVANCED_EXCHANGES);
        },
      },
    ],
  },
  {
    name: 'Shopper experience',
    icon: 'sliders',
    url: '/settings/customizations',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_CUSTOMIZATIONS),
    items: [
      {
        name: 'Portal customizations',
        url: '/settings/customizations',
        parent: 'Shopper experience',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_CUSTOMIZATIONS),
      },
      {
        name: 'Return reasons',
        url: '/settings/reasons',
        parent: 'Shopper experience',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_REASONS),
      },
      {
        name: 'Notifications',
        url: '/settings/notifications',
        parent: 'Shopper experience',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_NOTIFICATIONS),
      },
    ],
  },
  {
    name: 'Shipping',
    url: '/settings/shipping',
    icon: 'shipping',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_SHIPPING_SERVICES),
    items: [
      {
        name: 'Shipping services',
        url: '/settings/shipping',
        parent: 'Shipping',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_SHIPPING_SERVICES),
      },
      {
        name: 'Destinations',
        url: '/settings/destinations',
        parent: 'Shipping',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_DESTINATIONS),
      },
      {
        name: 'Locations',
        url: '/settings/locations',
        parent: 'Shipping',
        isVisible: () => Store.getters.hasFeature(featureFlags.CUSTOMIZED_FULFILLABLE_LOCATIONS)
          && Store.getters.hasPermission(permissions.MANAGE_FULFILLABLE_LOCATIONS),
      },
    ],
  },
  {
    name: 'Tracking',
    url: () => {
      if (!Store.getters.getSetting(shopSettings.ORDER_TRACKING_ALLOWED)) {
        return '/settings/return-and-exchange-tracking';
      }

      return '/settings/order-tracking';
    },
    icon: 'destinations',
    disabled: () => {
      if (!Store.getters.hasPermission(permissions.MANAGE_TRACKING)) {
        return true;
      }

      const orderTrackingAvailable = Store.getters.hasFeature(featureFlags.FORWARD_TRACKING_ENABLED) && Store.getters.getSetting(shopSettings.ORDER_TRACKING_ALLOWED);

      return !Store.getters.getSetting(shopSettings.RETURN_TRACKING_AVAILABLE) && !orderTrackingAvailable;
    },
    items: [
      {
        name: 'Order Tracking',
        url: '/settings/order-tracking',
        parent: 'Tracking',
        isVisible: () => Store.getters.hasFeature(featureFlags.FORWARD_TRACKING_ENABLED) && Store.getters.getSetting(shopSettings.ORDER_TRACKING_ALLOWED),
      },
      {
        name: 'Return and Exchange Tracking',
        url: '/settings/return-and-exchange-tracking',
        parent: 'Tracking',
        isVisible: () => Store.getters.getSetting(shopSettings.RETURN_TRACKING_AVAILABLE),
      },
    ],
  },
  {
    name: 'Point of Sale',
    url: '/settings/point-of-sale',
    parent: 'Tools & integrations',
    icon: 'smartphone',
    isVisible: () => Store.getters.getSetting('ENABLE_POINT_OF_SALE') && Store.getters.hasPermission(permissions.MANAGE_POINT_OF_SALE),
  },
  {
    name: 'Tools & integrations',
    icon: 'tool',
    url: '/settings/integrations',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_INTEGRATIONS),
    items: [
      {
        name: 'Integrations',
        url: '/settings/integrations',
        parent: 'Tools & integrations',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_INTEGRATIONS),
      },
      {
        name: 'Developer tools',
        url: '/settings/developers',
        parent: 'Tools & integrations',
        isVisible: () => {
          return Store.getters.getSetting('DEVELOPER_TOOLS_ENABLED') && Store.getters.hasPermission(permissions.MANAGE_DEVELOPER_TOOLS);
        },
      },
    ],
  },
  {
    name: 'Discover Loop',
    url: '/discover',
    icon: 'sparkles',
    bottomNavItem: true,
  },
  {
    name: 'Onboarding hub',
    url: '/onboarding-hub',
    icon: 'clipboard-check',
    bottomNavItem: true,
    isVisible: () => Store.getters.user.has_onboarding_modules,
    items: [
      {
        name: 'Basic setup',
        url: '/onboarding-hub/basic-setup',
        parent: 'Onboarding hub',
        isVisible: () => Store.getters.user.has_onboarding_modules,
      },
      {
        name: 'Advanced setup',
        url: '/onboarding-hub/advanced-setup',
        parent: 'Onboarding hub',
        isVisible: () => Store.getters.user.has_onboarding_modules,
      },
      {
        name: 'Test & launch',
        url: '/testing-hub/testing',
        parent: 'Onboarding hub',
        isVisible: () => Store.getters.user.has_onboarding_modules,
      },
    ],
  },
  {
    external: true,
    name: 'Shopper return portal',
    url: () => { return Store.getters['customerPortalLink']; },
    icon: 'external-link',
    bottomNavItem: true,
  },
  {
    external: true,
    name: 'Help Center',
    url: 'http://help.loopreturns.com',
    icon: 'help-circle',
    bottomNavItem: true,
  }

];

const evaluateFunction = (func) => typeof func === 'function' ? func() : func;

const evaluateAllFunctionsInItem = (item) => {
  const evaluatedItem = {};
  for (const key in item) {
    evaluatedItem[key] = evaluateFunction(item[key]);
  }

  // Recursively evaluate nested items
  if (item.items && Array.isArray(item.items)) {
    evaluatedItem.items = item.items.map(evaluateAllFunctionsInItem)
      .filter(subitem => subitem.isVisible !== false);
  }

  return evaluatedItem;
};

const filterVisibleSubitems = (subitems) =>
  subitems.map(evaluateAllFunctionsInItem)
    .filter(subitem => subitem.isVisible !== false);

const buildVisibleNavigationItems = (navItems) =>
  navItems.map(evaluateAllFunctionsInItem)
    .filter(navItem => navItem.isVisible !== false)
    .map(navItem => {
      // Only include subitems if they are not empty
      const subitems = filterVisibleSubitems(navItem.items || []);
      return subitems.length > 0 ? { ...navItem, items: subitems } : navItem;
    });


const sidebarNavigation = {
  namespaced: true,
  getters: {
    visibleItems() {
      return buildVisibleNavigationItems(items);
    },
  }
};

export default sidebarNavigation;
