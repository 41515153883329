<template>
  <div
    class="admin-layout"
    :class="{ 'hourglass': !userData }"
  >
    <template v-if="!userData">
      <loading-page :hourglass="true" />
    </template>
    <div
      v-else
      class="admin-layout__wrapper"
    >
      <slot name="navigation">
        <navigation-wrapper
          :user-data="userData"
        />
      </slot>

      <main
        v-if="type ==='full'"
        class="admin-layout__content full"
      >
        <div class="admin-layout__inner">
          <div
            class="admin-layout__inner-scroll"
            :style="{ height: computedHeight }"
          >
            <global-banner
              v-if="userData"
              :user-data="userData"
              :type="type"
            />
            <slot>
              Page not found.
            </slot>
          </div>
        </div>
      </main>

      <main
        v-else-if="type ==='wide' || type ==='normal'"
        class="admin-layout__content full"
      >
        <div class="admin-layout__inner">
          <div
            class="admin-layout__inner-scroll wide-normal"
            :style="{ height: computedHeight }"
          >
            <global-banner
              v-if="userData"
              :user-data="userData"
              :type="type"
            />
            <div :class="type">
              <slot>
                Page not found.
              </slot>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import GlobalBanner from '@/components/feedback/GlobalBanner';
import NavigationWrapper from '@/components/layout/NavigationWrapper.vue';
import LoadingPage from '@/pages/States/LoadingPage';

defineProps({
  userData: {
    type: Object,
    required: false
  },
  type: {
    type: String,
    required: false,
    default() {
      return 'normal';
    },
    validator(value) {
      return ['full', 'normal', 'wide'].includes(value);
    }
  }
});

const windowHeight = ref(window.innerHeight);

const computedHeight = computed(() => {
  //Account for the nav bar height
  return `${windowHeight.value - 72}px`;
});

const updateHeight = () => {
  windowHeight.value = window.innerHeight;
};

onMounted(() => {
  window.addEventListener('resize', updateHeight);
  document.body.style.overflowY = 'hidden';
});

onUnmounted(() => {
  window.removeEventListener('resize', updateHeight);
  document.body.style.overflowY = '';
});

</script>

<style lang="scss" scoped>
.admin-layout {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  padding-left: var(--nav-width-new-admin);
  padding-top: var(--nav-bar-height);
  background-color: var(--color-nav-background);

  :deep(.save-bar) {
    width: calc(100% - var(--nav-width-new-admin));
  }

  &__wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
  }

  &__inner {
    border-top-left-radius: 16px;
    background: white;
    overflow-y: hidden;
    height: 100%;

    &-scroll {
      overflow-y: scroll;
      height: 100%;
      position: relative;

      &.wide-normal {
        padding: 0 var(--spacing-700);
      }
    }
  }

  &__banner {
    margin-bottom: var(--spacing-300);
  }

  &__content {
    position: relative;
    width: 100%;
    padding: 1.5rem 2rem 5.5rem;
    box-sizing: border-box;
    border-top-left-radius: var(--border-radius-small);

    &.full {
      padding: 0;
      max-width: none;

      .layout__banner {
        margin-top: var(--spacing-300);
      }
    }
  }

  //These are outside of the content div, since we only want to apply it to the innermost div
  .normal {
    max-width: 1080px;
    margin: 0 auto;
  }

  &.wide {
    max-width: 1440px;
    margin: 0 auto;
  }

  &.hourglass {
    padding: 0;
    background: white;
  }
}

@media screen and (width <= 960px) {
  .admin-layout {
    padding-left: 0;

    &__content {
      padding: 1rem 1rem 5.5rem;
    }

    &__inner {
      border-radius: 0;
    }
  }

  .layout {
    padding-left: 0;

    &__content {
      padding: 1rem 1rem 5.5rem;
    }
  }
}
</style>
