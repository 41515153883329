<template>
  <div
    class="skeleton-text"
    :class="type"
    aria-hidden="true"
  >
    <span
      v-for="n in lines"
      :key="n"
      class="skeleton-text__line"
      :class="type"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    lines: {
      type: Number,
      required: false,
      default: 3
    },
    type: {
      type: String,
      required: false,
      default: 'body',
      validator: value => ['body', 'heading', 'tabs'].includes(value)
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.skeleton-text';

#{$block} {
  display: flex;
  flex-direction: column;

  &.tabs {
    width: 100%;
    flex-direction: row;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(var(--rgb-grey-200), 0.6);
  }

  &__line {
    @include skeleton;

    width: 100%;
    height: 0.5rem;

    &.heading {
      width: 25%;
      height: 1.75rem;
    }

    &.tabs {
      width: 4rem;
      height: 1rem;

      & + & {
        margin: 0;
        margin-left: 1rem;
      }

      &:last-of-type:not(:first-of-type) {
        width: 4rem;
      }
    }

    & + & {
      margin-top: 1rem;
    }

    &:last-of-type:not(:first-of-type) {
      width: calc(100% - 6rem);
    }
  }
}
</style>
