import { createId } from '@/util/helpers/ids';
import { ruleTypes } from '../../workflow-rules';

const buildRule = (conditionNames = [], actionNames = [], userInputNames = []) => {
  const userInput = userInputNames.length ? buildUserInput() : {};
  conditionNames = ['workflowGroupIdMatch', ...conditionNames];
  actionNames = ['warranty', ...actionNames];
  const warrantyQuestionId = `lineItem.user_input_${createId()}`;

  return {
    'if': [
      {
        'reduce': [
          {
            'filter': [
              conditionNames.map(name => conditions[name]),
              {
                '!==': [
                  {
                    'var': ''
                  },
                  null
                ]
              }
            ]
          },
          {
            'and': [
              {
                'var': 'current'
              },
              {
                'var': 'accumulator'
              }
            ]
          },
          true
        ]
      },
      {
        'if': [
          {
            'missing_some': [
              1,
              [
                warrantyQuestionId,
                'return.workflow_group_id',
              ]
            ]
          },
          [
            {
              'type': ruleTypes.USER_INPUT,
              'value': JSON.stringify({
                'id': warrantyQuestionId,
                'label': {
                  en: null,
                },
                'response': {
                  'isActive': false,
                  'message': null
                },
                'type': 'warranty',
              })
            },
            actions.warranty,
          ],
          {
            'if': [
              {
                'or': [
                  {
                    '==': [
                      {
                        'var': warrantyQuestionId
                      },
                      true
                    ]
                  },
                  {
                    '==': [
                      {
                        'var': 'return.workflow_group_id'
                      },
                      {
                        'var': 'group.id'
                      }
                    ]
                  }
                ]
              },
              {
                'filter': [
                  [...actionNames.map(name => actions[name]), ...userInputNames.map(name => userInput[name])],
                  {
                    '!==': [
                      {
                        'var': ''
                      },
                      null
                    ]
                  }
                ]
              },
            ]
          }
        ]
      },
      []
    ]
  };
};

const conditions = {
  workflowGroupIdMatch: {
    'if': [
      {
        'missing': [
          'group.id',
          'return.workflow_group_id'
        ]
      },
      null,
      {
        'includes': [
          {
            'var': 'group.id'
          },
          {
            'var': 'return.workflow_group_id'
          }
        ]
      }
    ]
  },
  productIds: {
    'if': [
      {
        'missing': [
          'group.parameters.eligibility_product_ids',
          'lineItem.product_id'
        ]
      },
      null,
      {
        'includes': [
          {
            'var': 'lineItem.product_id'
          },
          {
            'var': 'group.parameters.eligibility_product_ids.value'
          }
        ]
      }
    ]
  },
  productSkus: {
    'if': [
      {
        'missing': [
          'group.parameters.eligibility_product_skus',
          'lineItem.sku'
        ]
      },
      null,
      {
        'includes': [
          {
            'var': 'lineItem.sku'
          },
          {
            'var': 'group.parameters.eligibility_product_skus.value'
          }
        ]
      }
    ]
  },
  productTags: {
    'if': [
      {
        'missing': [
          'group.parameters.eligibility_product_tags',
          'lineItem.tags'
        ]
      },
      null,
      {
        'includes': [
          {
            'var': 'lineItem.tags'
          },
          {
            'var': 'group.parameters.eligibility_product_tags.value'
          }
        ]
      }
    ]
  },
  productTypes: {
    'if': [
      {
        'missing': [
          'group.parameters.eligibility_product_types',
          'lineItem.product_type'
        ]
      },
      null,
      {
        'includes': [
          {
            'var': 'lineItem.product_type'
          },
          {
            'var': 'group.parameters.eligibility_product_types.value'
          }
        ]
      }
    ]
  },
  zones: {
    'if': [
      {
        'missing': [
          'group.parameters.eligibility_zones',
          'order.zones'
        ]
      },
      null,
      {
        'includes': [
          {
            'var': 'order.zones'
          },
          {
            'var': 'group.parameters.eligibility_zones.value'
          }
        ]
      }
    ]
  },
  date: {
    'if': [
      {
        'missing': [
          'order.date'
        ]
      },
      null,
      {
        'if': [
          {
            'and': [
              {
                'missing': [
                  'group.parameters.eligibility_order_date_range_start'
                ]
              },
              {
                'missing': [
                  'group.parameters.delay_warranty_until_after_return_window'
                ]
              }
            ]
          },
          {
            'if': [
              {
                'missing': [
                  'group.parameters.eligibility_order_date_range_end'
                ]
              },
              null,
              {
                'beforeDate': [
                  {
                    'var': 'order.date'
                  },
                  {
                    'var': 'group.parameters.eligibility_order_date_range_end.value'
                  }
                ]
              }
            ]
          },
          {
            'if': [
              {
                'missing': [
                  'group.parameters.eligibility_order_date_range_end'
                ]
              },
              {
                'if': [
                  {
                    'and': [
                      {
                        '!': [
                          {
                            'missing': [
                              'group.parameters.delay_warranty_until_after_return_window'
                            ]
                          }
                        ]
                      },
                      {
                        'var': 'group.parameters.delay_warranty_until_after_return_window.value'
                      }
                    ]
                  },
                  {
                    'if': [
                      {
                        'missing': [
                          'group.parameters.eligibility_order_date_range_start.value'
                        ]
                      },
                      {
                        'afterDate': [
                          {
                            'var': 'today.date'
                          },
                          {
                            'var': 'order.eligible_until'
                          }
                        ]
                      },
                      {
                        'and': [
                          {
                            'afterDate': [
                              {
                                'var': 'today.date'
                              },
                              {
                                'var': 'order.eligible_until'
                              }
                            ]
                          },
                          {
                            'afterDate': [
                              {
                                'var': 'order.date'
                              },
                              {
                                'var': 'group.parameters.eligibility_order_date_range_start.value'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    'afterDate': [
                      {
                        'var': 'order.date'
                      },
                      {
                        'var': 'group.parameters.eligibility_order_date_range_start.value'
                      }
                    ]
                  }
                ]
              },
              {
                'if': [
                  {
                    'and': [
                      {
                        '!': [
                          {
                            'missing': [
                              'group.parameters.delay_warranty_until_after_return_window'
                            ]
                          }
                        ]
                      },
                      {
                        'var': 'group.parameters.delay_warranty_until_after_return_window.value'
                      }
                    ]
                  },
                  {
                    'and': [
                      {
                        'afterDate': [
                          {
                            'var': 'today.date'
                          },
                          {
                            'var': 'order.eligible_until'
                          }
                        ]
                      },
                      {
                        'if': [
                          {
                            'missing': [
                              'group.parameters.eligibility_order_date_range_start.value'
                            ]
                          },
                          {
                            'beforeDate': [
                              {
                                'var': 'order.date'
                              },
                              {
                                'var': 'group.parameters.eligibility_order_date_range_end.value'
                              }
                            ]
                          },
                          {
                            'betweenDate': [
                              {
                                'var': 'order.date'
                              },
                              {
                                'start': {
                                  'var': 'group.parameters.eligibility_order_date_range_start.value'
                                },
                                'end': {
                                  'var': 'group.parameters.eligibility_order_date_range_end.value'
                                }
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    'betweenDate': [
                      {
                        'var': 'order.date'
                      },
                      {
                        'end': {
                          'var': 'group.parameters.eligibility_order_date_range_end.value'
                        },
                        'start': {
                          'if': [
                            {
                              'and': [
                                {
                                  '!': [
                                    {
                                      'missing': [
                                        'group.parameters.delay_warranty_until_after_return_window'
                                      ]
                                    }
                                  ]
                                },
                                {
                                  'var': 'group.parameters.delay_warranty_until_after_return_window.value'
                                }
                              ]
                            },
                            {
                              'var': 'order.eligible_until'
                            },
                            {
                              'var': 'group.parameters.eligibility_order_date_range_start.value'
                            }
                          ]
                        }
                      }
                    ]
                  },
                  {
                    'if': [
                      {
                        'missing': [
                          'group.parameters.afterDate'
                        ]
                      },
                      null,
                      {
                        'afterDate': [
                          {
                            'var': 'order.date'
                          },
                          {
                            'var': 'group.parameters.afterDate.value'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    'beforeDate': [
                      {
                        'var': 'order.date'
                      },
                      {
                        'var': 'group.parameters.eligibility_order_date_range_end.value'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};

const actions = {
  warranty: {
    'type': 'Warranty',
    'field': 'warranty',
    'value':
      {
        'cat': [
          '{"workflowGroupId":',
          {
            'var': 'group.id'
          },
          '}'
        ]
      }
  },
  warrantyWindow: {
    'if': [
      {
        'missing': [
          'order.date',
          'group.parameters.warranty_window_length',
          'group.parameters.warranty_window_unit'
        ]
      },
      null,
      {
        'type': 'ReturnWindow',
        'field': 'returnWindow',
        'value': {
          'cat': [
            '{"returnDate":"',
            {
              'addTimeDelta': [
                {
                  'var': 'order.date'
                },
                {
                  'delta': {
                    'var': 'group.parameters.warranty_window_length.value'
                  },
                  'unit': {
                    'var': 'group.parameters.warranty_window_unit.value'
                  }
                }
              ]
            },
            '"}'
          ]
        },
        'display': 'Returnable until end of warranty window',
        'windowType': 'returnDate'
      }
    ]
  },
  manualReview: {
    'if': [
      {
        'and': [
          {
            '!': {
              'missing': [
                'group.parameters.manual_review'
              ]
            }
          },
          {
            'var': 'group.parameters.manual_review.value'
          }
        ]
      },
      {
        'type': 'ReviewReject',
        'field': 'reviewReject',
        'value': '{"outcome":"review"}',
        'display': 'Manual review',
      },
      null
    ]
  },
  processingEvent: {
    'if': [
      {
        'and': [
          {
            '!': {
              'missing': [
                'group.parameters.processing_event'
              ]
            }
          },
          {
            'var': 'group.parameters.processing_event.value'
          }
        ]
      },
      {
        'type': 'ProcessingEvent',
        'field': 'processingEvent',
        'value': {
          'cat': [
            '{"exchange_event_override":"',
            {
              'var': 'group.parameters.processing_event.value'
            },
            '","refund_event_override":"',
            {
              'var': 'group.parameters.processing_event.value'
            },
            '","gift_event_override":"',
            {
              'var': 'group.parameters.processing_event.value'
            },
            '"}'
          ]
        },
        'display': 'Processing event',
      },
      null
    ]
  },
  keepItem: {
    'if': [
      {
        'and': [
          {
            '!': {
              'missing': [
                'group.parameters.keep_item_enabled'
              ]
            }
          },
          {
            'var': 'group.parameters.keep_item_enabled.value'
          }
        ]
      },
      {
        'type': 'KeepDonate',
        'field': 'keepDonate',
        'value': {
          'cat': [
            '{"outcome":"keep","immediate":',
            {
              'if': [
                {
                  'var': 'group.parameters.keep_item_immediate.value',
                },
                1,
                0
              ],
            },
            '}'
          ] 
        },
        'display': 'Keep item',
      },
      null
    ]
  },
  disableLabel: {
    'if': [
      {
        'and': [
          {
            '!': [
              {
                'missing': [
                  'group.parameters.generate_labels_enabled'
                ]
              }
            ]
          },
          {
            '!': [
              {
                'var': 'group.parameters.generate_labels_enabled.value'
              }
            ]
          }
        ]
      },
      {
        'type': 'DisableLabel',
        'field': 'disableLabel',
        'value': '{"disabled":true}',
        'display': 'Disable Label',
      },
      null
    ]
  },
  destinationOverride: {
    'if': [
      {
        'missing': [
          'group.parameters.return_destinations'
        ]
      },
      null,
      {
        'type': 'DestinationOverride',
        'field': 'destinationOverride',
        'value': {
          'cat': [
            '{"destinationIds":[',
            {
              'implode': [
                ',',
                {
                  'var': 'group.parameters.return_destinations.value'
                }
              ]
            },
            ']}'
          ]
        },
        'display': 'Destination override: Warranty'
      }
    ]
  },
  handlingFee: {
    'if': [
      {
        'missing': [
          [
            'group.parameters.handling_fee_type',
            'group.parameters.handling_fee_amount',
            'group.parameters.handling_fee_application_option'
          ]
        ]
      },
      {
        'type': 'HandlingFee',
        'field': 'fees',
        'value': '{"type":"waive"}',
        'display': 'Waive all fees'
      },
      {
        'type': 'HandlingFee',
        'field': 'fees',
        'value': {
          'cat': [
            '{"type":"',
            {
              'var': 'group.parameters.handling_fee_type.value'
            },
            '","applicationOption":"',
            {
              'var': 'group.parameters.handling_fee_application_option.value'
            },
            '","amount":"',
            {
              'var': 'group.parameters.handling_fee_amount.value'
            },
            '"}'
          ]
        },
        'display': 'Warranty handling fee',
        'windowType': 'returnDate'
      }
    ]
  },
  excludeOutcomes: {
    'if': [
      {
        'missing_some': [
          6,
          [
            'group.parameters.replace_enabled',
            'group.parameters.exchange_enabled',
            'group.parameters.advanced_exchange_enabled',
            'group.parameters.shop_now_enabled',
            'group.parameters.store_credit_enabled',
            'group.parameters.refund_enabled'
          ]
        ]
      },
      null,
      {
        'type': 'ExcludeOutcome',
        'field': 'excludeOutcomes',
        'value': {
          'cat': [
            '{"replace":',
            {
              'if': [
                {
                  'var': 'group.parameters.replace_enabled.value'
                },
                0,
                1
              ]
            },
            ',"exchange":',
            {
              'if': [
                {
                  'var': 'group.parameters.exchange_enabled.value'
                },
                0,
                1
              ]
            },
            ',"advancedExchange":',
            {
              'if': [
                {
                  'var': 'group.parameters.advanced_exchange_enabled.value'
                },
                0,
                1
              ]
            },
            ',"shopNow":',
            {
              'if': [
                {
                  'var': 'group.parameters.shop_now_enabled.value'
                },
                0,
                1
              ]
            },
            ',"storeCredit":',
            {
              'if': [
                {
                  'var': 'group.parameters.store_credit_enabled.value'
                },
                0,
                1
              ]
            },
            ',"refund":',
            {
              'if': [
                {
                  'var': 'group.parameters.refund_enabled.value'
                },
                0,
                1
              ]
            },
            '}',
          ]
        },
        'display': 'Exclude outcomes: Warranty outcomes',
      }
    ]
  },
  returnReasons: {
    'if': [
      {
        'missing': [
          'group.parameters.return_reason_group'
        ]
      },
      null,
      {
        'type': 'ReasonGroupOverride',
        'field': 'reasonGroupOverride',
        'value': {
          'cat': [
            '{"reasonGroupId":"',
            {
              'var': 'group.parameters.return_reason_group.value'
            },
            '"}'
          ]
        },
        'display': 'Reason Group override: Default Group',
      }

    ]
  },
  storeCreditIncentives: {
    'if': [
      {
        'missing': [
          'group.parameters.store_credit_enabled'
        ]
      },
      null,
      {
        'if': [
          {
            'var': 'group.parameters.store_credit_enabled.value'
          },
          {
            'type': 'StoreCreditIncentive',
            'field': 'storeCreditIncentive',
            'value': '{"type":"flat_fee","amount":0,"max_amount_enabled":false,"max_amount":0}',
            'display': '$0.00'
          },
          null
        ]
      }
    ]
  },
  shopNowIncentives: {
    'if': [
      {
        'missing': [
          'group.parameters.shop_now_enabled'
        ]
      },
      null,
      {
        'if': [
          {
            'var': 'group.parameters.shop_now_enabled.value'
          },
          {
            'type': 'BonusCredit',
            'field': 'bonusCredit',
            'value': '{"type":"flat_fee","amount":0,"max_amount_enabled":false,"max_amount":0}',
            'display': '$0.00'
          },
          null
        ]
      }
    ]
  }
};

const buildUserInput = () => {
  const photoUploadId = `lineItem.user_input_${createId()}`;
  const commentId = `lineItem.user_input_${createId()}`;

  return {
    photoUpload: {
      'if': [
        {
          'missing': [
            photoUploadId,
            'group.parameters.photo_upload_required',
          ]
        },
        {
          'type': 'UserInput',
          'value': {
            'cat': [
              '{"id":"',
              photoUploadId,
              '","label":{"en":{"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"',
              {
                'if': [
                  {
                    'missing': [
                      'group.parameters.photo_upload_label_text'
                    ]
                  },
                  'Upload an image for your warranty claim.',
                  {
                    'var': 'group.parameters.photo_upload_label_text.value'
                  }
                ]
              },
              '"}]}]}},"response":{"isActive":false,"message":null},"type":"upload","mandatory":',
              {
                'if': [
                  {
                    'var': 'group.parameters.photo_upload_required.value'
                  },
                  1,
                  0
                ]
              },
              ',"photoCount":',
              {
                'if': [
                  {
                    'missing': [
                      'group.parameters.photo_upload_count'
                    ]
                  },
                  1,
                  {
                    'var': 'group.parameters.photo_upload_count.value'
                  }
                ]
              },
              '}'
            ]
          },
          'display': 'Warranty claim photo upload'
        },
        null
      ]
    },
    comment: {
      'if': [
        {
          'missing': [
            commentId
          ]
        },
        {
          'type': 'UserInput',
          'value': {
            'cat': [
              '{"id":"',
              commentId,
              '","label":{"en":{"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"',
              {
                'if': [
                  {
                    'missing': [
                      'group.parameters.comment_label_text'
                    ]
                  },
                  'Share a comment about your warranty claim.',
                  {
                    'var': 'group.parameters.comment_label_text.value'
                  }
                ]
              },
              '"}]}]}},"response":{"isActive":false,"message":null},"type":"comment","mandatory":',
              {
                'if': [
                  {
                    'var': 'group.parameters.comment_required.value'
                  },
                  1,
                  0
                ]
              },
              '}'
            ]
          },
          'display': 'Warranty claim comment'
        },
        null
      ]
    }
  };
};

export default {
  buildRule
};
