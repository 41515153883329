/**
 * Finds an item where the property and value match in a list and moves it to the front (non-mutating)
 * @param {object[]} list - The list to add to or update
 * @param {string} property - The property used to find the item
 * @returns {object[]} The list with the item at the front. If the item is not found, the list is returned unchanged
 */
function moveItemToFront(list, property, value) {
  const newArr = [...list];
  const index = newArr.findIndex(item => item[property] === value);

  if (index !== -1) {
    const [item] = newArr.splice(index, 1);
    newArr.unshift(item);
  }

  return newArr;
}

export default moveItemToFront;
