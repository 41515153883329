<template>
  <component
    :is="tag"
    class="line-item"
    :class="{ '--destructive': destructive }"
    :disabled="disabled"
    v-bind="linkAttrs"
    @click="emit('line-item-click')"
  >
    <svg-icon
      v-if="icon"
      :name="icon"
      size="small"
    />
    <base-text type="text-body">
      <slot></slot>
    </base-text>
  </component>
</template>

<script setup>
import { computed } from 'vue';
import { SvgIcon, BaseText } from '@loophq/ui';

const props = defineProps({
  tag: {
    type: String,
    required: true,
    default: 'div',
  },
  url: {
    type: String,
    required: false,
  },
  icon: {
    type: String,
    required: false,
  },
  external: {
    type: Boolean,
    required: false,
    default: false,
  },
  destructive: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['line-item-click']);

const linkAttrs = computed(() => {
  if (props.url) {
    if (!props.external) {
      return {
        to: props.url,
      };
    }
    return {
      href: props.url,
      target: '_blank',
    };
  }
  return {};
});


</script>

<style lang="scss" scoped>
.line-item {
  display: flex;
  align-items: center;
  padding: var(--spacing-8);
  gap: var(--spacing-8);
  color: var(--color-neutral-900);
  text-decoration: none;
  transition: background 0.2s ease;

  &.--destructive {
    p,
    .svg-icon {
      color: var(--color-mahogany-600) !important;
    }
  }

  &:hover,
  &:focus {
    background: var(--color-neutral-50);
    border-radius: 8px;
  }
}
  </style>
