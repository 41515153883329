import axios from 'axios';

const roles = {
  get() {
    return axios.get(`roles`)
      .then((res) => res.data);
  }
};

export default roles;
