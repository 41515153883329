import store from '@/store';

export default [
  {
    path: '/onboarding-hub',
    component: () => import('@/pages/OnboardingHub/OnboardingHubPage'),
    name: 'OnboardingHub',
    meta: {
      layout: 'app',
      loader: false,
      title: 'Onboarding Hub',
    },
    children: [
      {
        path: '',
        name: 'OnboardingDashboard',
        component: () => import('@/pages/OnboardingHub/Pages/OnboardingDashboard.vue'),
        beforeEnter: (from) => {
          if (!store.state.userData.has_onboarding_modules) {
            return { name: 'OnboardingHubBasicSetup', query: from.query };
          }
        },
      },
      {
        path: 'basic-setup',
        name: 'OnboardingHubBasicSetup',
        component: () => import('@/pages/OnboardingHub/Pages/BasicSetup'),
      },
      {
        path: 'advanced-setup',
        name: 'OnboardingHubAdvancedSetup',
        component: () => import('@/pages/OnboardingHub/Pages/AdvancedSetup'),
        beforeEnter: () => {
          if (!store.state.userData.has_onboarding_modules) {
            return { name: 'OnboardingHubBasicSetup' };
          }
        },
      },
    ]
  },
];
