<template>
  <span
    v-if="active"
    class="spinner"
    :class="{ large: type === 'loop' }"
    :style="{ '--spinner-color': color }"
  >
    <span
      v-if="type === 'thin'"
      class="spinner__thin"
    >
      <span class="visually-hidden">Loading...</span>
    </span>
    <span
      v-if="type === 'loop'"
      class="spinner__loop"
    >
      <svg
        class="spinner__loop-svg"
        width="72"
        height="72"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="spinner__loop-path"
          d="M36 66c16.569 0 30-13.431 30-30C66 19.431 52.569 6 36 6 19.431 6 6 19.431 6 36c0 16.569 13.431 30 30 30z"
          stroke="currentColor"
          stroke-width="12"
          fill="none"
          fill-rule="nonzero"
        />
      </svg>
    </span>
    <span
      v-if="type === 'loop-small'"
      class="spinner__loop-small"
    >
      <svg
        class="spinner__loop-small-svg"
        viewBox="0 0 72 72"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="spinner__loop-small-path"
          d="M36 66c16.569 0 30-13.431 30-30C66 19.431 52.569 6 36 6 19.431 6 6 19.431 6 36c0 16.569 13.431 30 30 30z"
          stroke="currentColor"
          stroke-width="12"
          fill="none"
          fill-rule="nonzero"
        />
      </svg>
    </span>
    <span
      v-if="type === 'loop-tiny'"
      class="spinner__loop-tiny"
    >
      <svg
        class="spinner__loop-tiny-svg"
        viewBox="0 0 72 72"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="spinner__loop-tiny-path"
          d="M36 66c16.569 0 30-13.431 30-30C66 19.431 52.569 6 36 6 19.431 6 6 19.431 6 36c0 16.569 13.431 30 30 30z"
          stroke="currentColor"
          stroke-width="12"
          fill="none"
          fill-rule="nonzero"
        />
      </svg>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'thin',
      validator: value => ['loop', 'loop-small', 'loop-tiny', 'thin'].includes(value)
    },
    color: {
      type: String,
      required: false,
      default: 'var(--color-bloop-600)'
    }
  }
};
</script>

<style lang="scss">
@keyframes logo-spinner {
  0% {
    stroke-dashoffset: 188px;
  }

  40% {
    stroke-dashoffset: 138px;
    transform: rotate(1660deg);
  }

  50% {
    stroke-dashoffset: 0;
    transform: rotate(2160deg);
  }

  90% {
    stroke-dashoffset: 138px;
    transform: rotate(3760deg);
  }

  100% {
    stroke-dashoffset: 188px;
    transform: rotate(4320deg);
  }
}

.spinner {
  width: 1rem;
  height: 1rem;
  display: flex;
  color: var(--spinner-color);

  &.large {
    width: 3rem;
    height: 3rem;
  }

  &__thin {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(--color-neutral-500);
    transition: opacity 0.4s 0, transform 0.4s 0;
    transform: translate3d(0, 0, 0);
    animation: spin 0.8s infinite linear;
  }

  &__loop {
    width: 4.5rem;
    height: 4.5rem;
    transform: translate3d(0, 0, 0);
    stroke-linecap: round;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loop-path {
    transform-origin: center center;
    stroke-dasharray: 189px;
    animation: logo-spinner 6s ease-in-out 0s infinite normal none;
  }

  &__loop-small {
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    stroke-linecap: round;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loop-small-svg {
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    stroke-linecap: round;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loop-small-path {
    transform-origin: center center;
    stroke-dasharray: 189px;
    animation: logo-spinner 6s ease-in-out 0s infinite normal none;
  }

  &__loop-tiny {
    width: 1rem;
    height: 1rem;
    transform: translate3d(0, 0, 0);
    stroke-linecap: round;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loop-tiny-svg {
    width: 1rem;
    height: 1rem;
    transform: translate3d(0, 0, 0);
    stroke-linecap: round;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loop-tiny-path {
    transform-origin: center center;
    stroke-dasharray: 189px;
    animation: logo-spinner 6s ease-in-out 0s infinite normal none;
  }
}
</style>
