import store from '@/store';
import { permissions } from '@/util/schemas/permissions';

export default {
  path: '/home/',
  component: () => import('@/pages/MissionControl/MissionControl.vue'),
  name: 'MissionControl',
  meta: {
    layout: 'app',
    layoutType: 'full',
    title: 'Home',
  },
  beforeEnter: async (to, from, next) => {
    const hasAccess = store.getters.hasPermission(permissions.MANAGE_RETURNS)
      && store.getters.hasPermission(permissions.MANAGE_REPORTING)
      && store.getters.hasPermission(permissions.VIEW_ANALYTICS);

    if (!hasAccess) {
      return next({ name: '404' });
    }

    next();
  },
};
