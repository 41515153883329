import axios from 'axios';

//eslint-disable-next-line
const rules = function () {
};

rules.prototype = {
  get() {
    return new Promise((resolve, reject) => {
      axios.get('rules')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRule(id) {
    return new Promise((resolve, reject) => {
      if (!id) {
        console.error('API: rules.getRule() requires an id as an input.');
        reject();
      }

      axios.get(`rules/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default rules;
