import axios from 'axios';
import changeCase from 'change-object-case';
changeCase.options = { recursive: true, arrayRecursive: true };

export default {
  /**
   * Update a warranty policies state (workflow group & corresponding workflow)
   * @param {string} id
   * @param {boolean} newState
   * @returns {Promise}
   */
  async updateState(id,newState) {
    return await axios.patch(`/warranty-policies/${id}`, {
      active: newState,
    });
  },
};
