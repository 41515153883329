<template>
  <div
    v-click-outside="closeMenu"
    class="navigation-header-mininav"
  >
    <button
      class="navigation-header-mininav__user-wrap"
      @click="menuHandler"
    >
      <user-avatar data-testid="mini-nav-avatar">
        {{ userInitials }}
      </user-avatar>
      <div
        class="navigation-header-mininav__user-info"
      >
        <div
          class="navigation-header-mininav__user-shop-wrapper"
        >
          <base-text
            type="text-button"
            class="navigation-header-mininav__user-shop"
            color="white"
          >
            <span>{{ userData.shop.name }}</span>
          </base-text>
          <flag-icon
            :iso="userCountry"
            width="20px"
          />
        </div>

        <base-text
          type="text-caption"
          class="navigation-header-mininav__user-name"
          color="white"
        >
          {{ userData.name }}
        </base-text>
      </div>
      <div class="navigation-header-mininav__icon">
        <svg-icon name="chevron-down" />
      </div>
    </button>
    <transition name="roll-down">
      <div
        v-if="menuActive"
        class="navigation-header-mininav__menu navigation-header-mininav__menu--desktop"
      >
        <mini-nav-dropdown
          class="navigation-header-mininav__dropdown"
          :userData="userData"
          @close="menuHandler"
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { BaseText, SvgIcon, UserAvatar, FlagIcon } from '@loophq/ui';
import MiniNavDropdown from './MiniNavDropdown.vue';
import { identify, track } from '@/util/helpers/segment';
import { trackingEventProperties, trackingEvents } from '@/constants/segment';

const store = useStore();

const menuActive = ref(false);

const userData = computed(() => {
  return store.state.userData;
});

const userCountry = computed(() => {
  return userData.value.shop.country.toLowerCase();
});

const userInitials = computed(() => {
  return userData.value.name
    .split(' ')
    .map((word) => word[0])
    .join('');
});


const closeMenu = () => {
  menuActive.value = false;
};

const menuHandler = () => {
  menuActive.value = !menuActive.value;

  identify(store.state.userData, store.state.userData.shop);
  track(trackingEvents.NAV_ITEM_CLICKED, {
    navItem: 'Mini nav',
    action: menuActive.value ? 'Open' : 'Close',
    version: trackingEventProperties.ADMIN_NAV_UPDATED_V1,
  });
};

</script>

<style lang="scss" scoped>
.navigation-header-mininav {
  position: relative;

  &__user {
    &-wrap {
      max-width: 345px;
      display: flex;
      align-items: center;
      gap: var(--spacing-12);
      background: rgba(255, 255, 255, 8%);
      padding: var(--spacing-8);
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:hover,
      &:focus-visible,
      &:active {
        background: rgba(255, 255, 255, 12%);
      }

      &:focus-visible {
        outline: auto;
      }
    }

    &-info {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    &-shop {
      font-size: 14px !important;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1 1 100%;

      &-wrapper {
        display: flex;
        gap: var(--spacing-4);
      }
    }

    &-name {
      display: flex;
      align-items: center;
      font-size: 12px !important;
    }
  }

  &__icon {
    color: white;
    margin-left: var(--spacing-4);
  }

  @media screen and (width <= 960px) {
    &__user-wrap {
      background: unset;
    }

    &__user-info,
    &__icon {
      display: none;
    }
  }
}

</style>

<style>
.roll-down-enter-active,
.roll-down-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
  transform-origin: top;
}

.roll-down-enter-from,
.roll-down-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.roll-down-enter-to,
.roll-down-leave-from {
  opacity: 1;
  transform: scaleY(1);
}

.roll-up-enter-active,
.roll-up-leave-active {
  transition: opacity 0.3s ease, transform 0.5s ease;
  transition-delay: opacity 0.1s;
  transform-origin: bottom;
}

.roll-up-enter-from,
.roll-up-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.roll-up-enter-to,
.roll-up-leave-from {
  opacity: 1;
  transform: scaleY(1);
}

</style>
