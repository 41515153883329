import _ from 'lodash';
import AdvancedApi from '@/util/api/advancedExchanges';
import CustomizationsRequest from '@/util/api/customizations';
import SettingsRequest from '@/util/api/policies';

const request = new AdvancedApi();

const defaultExchange = {
  id: null,
  options: [],
  operator: 'contains',
  search_string: '',
  search_tags: [],
  title: '',
  enabled: false,
  title_visible: false,
  exchange_type: 'collection'
};

export default {
  namespaced: true,
  state: {
    pageData: [],
    collections: [],
    defaultExchange,
    existingExchange: null,
    currentExchange: defaultExchange,
    productIdentifier: 'product_name',
    exchangeType: 'collection',
    activeAction: null,
    language: 'en',
  },
  mutations: {
    setProperty(state, { key, value, namespace }) {
      if (namespace) {
        state[namespace][key] = value;
      } else {
        state[key] = value;
      }
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setAction(state, value) {
      state.activeAction = value;
    },
    updateExchange(state) {
      if (state.currentExchange.id) {
        state.pageData = state.pageData.map(item => {
          return item.id === state.currentExchange.id ? state.currentExchange : item;
        });
      } else {
        state.pageData = [
          ...state.pageData,
          Object.assign({}, state.currentExchange, { id: `n${state.pageData.length}` })
        ];
      }
      state.currentExchange = state.defaultExchange;
    },
    insertExchangeAt(state, { exchange, index }) {
      state.pageData = [
        ...state.pageData.slice(0, index),
        exchange,
        ...state.pageData.slice(index)
      ];
    },
    deleteExchange(state, { id }) {
      state.pageData = state.pageData.filter(item => item.id !== id && item.id !== +id);
    },
    setExchange(state, { id }) {
      // Check if item exact matches or matches when cast to number
      const existingExchange = state.pageData.find(item => item.id === id || item.id === +id);

      if (existingExchange) {
        if (existingExchange.subject === 'product_tag') {
          existingExchange.search_tags = existingExchange.search_string.split(',');
          existingExchange.search_string = '';
        }

        if (!existingExchange.search_tags)  existingExchange.search_tags = [];
				
      }

      // sets the selected radio button (defaulting to value for 1st radio button if nothing set)
      state.productIdentifier = existingExchange?.subject || 'product_name';
      state.exchangeType = existingExchange?.exchange_type || 'collection';

      state.existingExchange = existingExchange ? _.cloneDeep(existingExchange) : null;
      state.currentExchange = existingExchange ? _.cloneDeep(existingExchange) : _.cloneDeep(defaultExchange);
    },
    updateItem(state, { key, value }) {
      state.currentExchange[key] = value;
    },
    updateProductIdentifier(state, { value }) {
      state.productIdentifier = value;
      state.exchangeType = value === 'tag_tag' ? 'tag' : 'collection';
    },
    updateSearchTags(state, { value }) {
      state.currentExchange.search_tags = value;
    },
    updateId(state, { oldId, data }) {
      state.pageData = state.pageData.map(item => {
        if (item.id === oldId) {
          return data;
        }
        return item;
      });
    }
  },
  actions: {
    async getPageData({ commit }/* , route */) {
      const [customizationsContent] = await Promise.all([
        CustomizationsRequest.get()
      ]);
      commit('setProperty', {
        key: 'customizationsContent',
        value: customizationsContent.customizations.content
      });

      return Promise.resolve()
        .then(() => {
          return request.get()
            .then(res => {
              commit('setProperty', {
                key: 'pageData',
                value: res.data.advanced_exchanges
              });

              commit('setProperty', {
                key: 'collections',
                value: res.data.collections.filter(item => item.title.toLowerCase() !== 'menu item')
              });
            });
        });
    },
    updateListItem({ state, commit }) {
      // Non-numeric ids are temp ids used until we have a real id from the server
      const cloned = _.cloneDeep(state.currentExchange);
      const newItem = !_.isNumber(cloned.id);
      const existing = !newItem ? _.cloneDeep(state.pageData.find(item => item.id === cloned.id)) : null;
      const tempId = cloned.id ? cloned.id : `n${state.pageData.length}`;

      // Remove new item temp ids when pushing data to the server
      if (newItem) {
        delete cloned.id;
      }

      // Add delete flag to option if we've removed it
      if (existing) {
        const currentIds = cloned.options.map(option => option.id);
        cloned.options = [
          ...cloned.options,
          ...existing.options
            .filter(option => !currentIds.includes(option.id))
            .map(option => {
              option.delete = true;
              return option;
            })
        ];
      }

      // Remove ids from the options array as well
      cloned.options = cloned.options.map(option => {
        // Non-numeric ids are temp ids used until we have a real id from the server
        if (!_.isNumber(option.id)) {
          delete option.id;
        }
        return option;
      });

      // denote which type of search is being made
      cloned.subject = state.productIdentifier;
      cloned.exchange_type = state.exchangeType;

      // Adjust for tags rather than a product name search
      if (state.productIdentifier === 'product_tag') {
        cloned.search_string = cloned.search_tags.join(',');
        delete cloned.search_tags;
      }

      commit('setAction', 'saving');
      return new Promise((resolve, reject) => {
        request.updateOrCreate(cloned)
          .then(res => {
            commit('updateId', { oldId: tempId, newId: res.data.id, data: res.data });
            commit('setToast', {
              message: `${cloned.title} saved.`,
              type: 'success',
            }, { root: true });
            resolve({
              data: res.data,
              tempId
            });
          })
          .catch(err => {
            commit('deleteExchange', { id: tempId });
            commit('setToast', {
              message: `We weren't able to save ${cloned.title ? `"${cloned.title}"` : 'this exchange'}.`,
              type: 'error',
            }, { root: true });
            reject(err);
          })
          .finally(() => {
            commit('setLanguage', 'en');
            commit('setAction', null);
          });
      });
    },
    deleteListItem({ commit, state }, { id }) {
      // Get a reference to the item in case we need it
      const index = state.pageData.findIndex(item => item.id === id || item.id === +id);
      const exchange = _.cloneDeep(state.pageData[index]);

      commit('setAction', 'removing');
      return new Promise((resolve, reject) => {
        request.delete(id)
          .then(res => {
            commit('deleteExchange', { id });
            commit('setToast', {
              message: 'Advanced exchange deleted.',
              type: 'success',
            }, { root: true });
            resolve(res);
          })
          .catch(err => {
            commit('setToast', {
              message: `We weren't able to delete "${exchange.title}".`,
              type: 'error',
            }, { root: true });
            reject(err);
          })
          .finally(() => {
            commit('setAction', null);
          });
      });
    },
    saveSettings({ rootState, rootGetters }) {
      const Settings = new SettingsRequest();
      const differentPricedExchanges = rootState.userData.shop.settings.find(setting => setting.key === 'DIFFERENT_PRICED_EXCHANGES');
      Settings.save([
        {
          ...differentPricedExchanges,
          value: rootGetters.getSetting('DIFFERENT_PRICED_EXCHANGES') ? 'yes' : 'no'
        },
      ], false);
    }
  },
  getters: {
    currentLanguage: state => { return state.language; },
    itemEdited: state => {
      if (state.existingExchange) {
        return !_.isEqual(state.existingExchange, state.currentExchange);
      }

      return true;
    }
  }
};
