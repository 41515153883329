import axios from 'axios';
import changeCase from 'change-object-case';

const products = {
  get(search = '', raw = false) {
    return axios.get('/products', { params: { search } })
      .then((res) => raw ? res.data : changeCase.toCamel(res.data));
  },
  getTypes(search = '') {
    return axios.get('/product-types', { params: { search } })
      .then(({ data }) => {
        return data.map(({ id, type }) => {
          return {
            id,
            label: type
          };
        });
      });
  }
};

export default products;
