export const permissions = {
  MANAGE_RETURNS: 'manage returns',
  MANAGE_REPORTING: 'manage reporting',
  MANAGE_LISTINGS: 'manage listings',
  VIEW_ANALYTICS: 'view analytics',
  MANAGE_POLICY_RULES: 'manage policy rules',
  MANAGE_SHOP_NOW: 'manage shop now',
  MANAGE_ADVANCED_EXCHANGES: 'manage advanced exchanges',
  MANAGE_GENERAL_SETTINGS: 'manage general settings',
  MANAGE_RETURN_POLICIES: 'manage return policies',
  MANAGE_WARRANTIES: 'manage warranty policies',
  MANAGE_DESTINATIONS: 'manage destinations',
  MANAGE_NOTIFICATIONS: 'manage notifications',
  MANAGE_REASONS: 'manage reasons',
  MANAGE_CUSTOMIZATIONS: 'manage customizations',
  VIEW_USERS: 'view users',
  MANAGE_USERS: 'manage users',
  MANAGE_DEVELOPER_TOOLS: 'manage developer tools',
  MANAGE_SCOPES: 'manage scopes',
  MANAGE_SHIPPING_SERVICES: 'manage shipping services',
  MANAGE_CUSTOM_FEATURES: 'manage custom features',
  MANAGE_BUNDLES: 'manage bundles',
  MANAGE_INTEGRATIONS: 'manage integrations',
  MANAGE_WORKFLOWS: 'manage workflows',
  MANAGE_POINT_OF_SALE: 'manage point of sale',
  MANAGE_TRACKING: 'manage tracking',
  MANAGE_INSTANT_EXCHANGES: 'manage instant exchanges',
  MANAGE_FULFILLABLE_LOCATIONS: 'manage fulfillable locations',
};
