export const toSlug = (value) => {
  return value
    .toLowerCase()
    .replace(/[^\w -]+/g, '')
    .replace(/ +/g, '-');
};

export const replaceWithQueryString = (fullUrl) => {
  if (!fullUrl) return '';
  let splitString = fullUrl.split('#');
  return splitString.join('?');
};

export const isValidUrl = (url) => {
  if (!url || url === '') return false;

  const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
  return urlPattern.test(url);
};

export const blankUrlAllowedValidator = (url) => {
  if (url === '') return true;

  const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
  return urlPattern.test(url);
};
