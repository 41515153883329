import request from '@/util/api/billing';

export default {
  namespaced: true,
  state: {
    text: {
    },
    info: null,
    invoices: []
  },
  mutations: {
    setProperty(state, { key, value, namespace }) {
      if (namespace) {
        state[namespace][key] = value;
      } else {
        state[key] = value;
      }
    },
    setInfo(state, info) {
      state.info = info;
    },
    setInvoices(state, invoices) {
      state.invoices = invoices;
    },
  },
  actions: {
    async getPageData() {
      const res = await request.getUrl();
      if (res.url) {
        window.location.replace(res.url);
      }
    },
    updatePaymentInfo({ commit }, token) {
      return request.updatePaymentInfo(token)
        .then(res => {
          commit('setInfo', res.data);
        });
    }
  },
  getters: {
  }
};
