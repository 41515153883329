import axios from 'axios';

export default {
  get(token) {
    return axios.get(`onboard?token=${token}`);
  },
  checkEmail(email, shopId) {
    return axios.post(`check/email`, { email, shopId });
  },
  checkSubdomain(subdomain) {
    return axios.post(`check/subdomain`, { subdomain });
  },
  billingLookup(customerCode) {
    return axios.post('check/billing', { customerCode });
  },
  redirectToMarketingPage(shopId) {
    return axios.get(`onboard/${shopId}/redirect`);
  },
  confirmStore(data, token, isReinstall = false, cb) {
    return axios.post(`onboard/confirm?token=${token}`, {
      ...data,
      confirm: true,
      isReinstall,
    }, {
      onUploadProgress: ((progressEvent) => {
        if (cb) {
          cb(progressEvent.loaded * 100 / progressEvent.total);
        }
      }),
    });
  },
  assignTestingAndValidationTasks() {
    return axios.post(`testing-and-validation`);
  },
  createShopifyOrder(variantId, order) {
    return axios.post(`orders/create`, { variantId, order });
  }
};
