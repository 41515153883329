import axios from 'axios';

const integrations = {
  async get() {
    try {
      return await axios.get('integrations/settings');
    } catch (error) {
      return error;
    }
  },
  async upsert(data) {
    try {
      return await axios.post('integrations/settings', data);
    } catch (error) {
      return error;
    }
  },
  async uninstall(integrationName, shopifyDomain) {
    try {
      return await axios.post(
        `integrations/uninstall/${integrationName}${
          shopifyDomain ? `?shopify_domain=${shopifyDomain}` : ''
        }`
      );
    } catch (error) {
      return error;
    }
  },
  async test(data) {
    try {
      return await axios.post('integrations/settings/test', data);
    } catch (error) {
      return error;
    }
  },
  async getConnectEndpoint(integrationName, subdomain, createDeliveryErrorTickets = false) {
    try {
      return await axios.get(`integrations/authorization-url/${integrationName}?subdomain=${subdomain}&create_delivery_error_tickets=${createDeliveryErrorTickets}`);
    } catch (error) {
      return error;
    }
  },
  async createHttpIntegration(integrationName, subdomain, createDeliveryErrorTickets = false) {
    return await axios.post(`integrations/http-integration/${integrationName}?subdomain=${subdomain}&create_delivery_error_tickets=${createDeliveryErrorTickets}`);
  }
};

export default integrations;
