<template>
  <div
    v-if="routeCanShowBanner"
    class="banners"
    :class="{ full: layoutType === 'full' }"
  >
    <alert-banner
      v-if="missingScopes.length && canShowScopesBanner"
      class="banner-rescoping new-admin"
      heading="The Loop application requires additional permissions within Shopify to update to the latest version."
      description="This only needs completed once by a user with Shopify admin permissions. Clicking “Add Permissions” will allow you to grant the following permissions:"
      primary-action-text="Add Permissions"
      :primary-action-disabled="rescopeLoading"
      @primary-action-clicked="handleRescopeClick"
    >
      <div class="banner-rescoping-scopes">
        <div
          v-for="scope in missingScopes"
          :key="scope"
          class="highlighted"
        >
          {{ scope }}
        </div>
      </div>
    </alert-banner>

    <alert-banner
      v-if="!userData.job_title && !hideRoleBanner"
      class="banner-role"
      heading="Update your profile with your job role"
      description="Add your job role to your Loop profile to ensure you are receiving relevant product updates and notifications"
      :is-closeable="true"
      primary-action-text="Update profile"
      :primary-action-disabled="roleUpdateLoading"
      @primary-action-clicked="updateRole"
      @close="hideRoleBanner = true"
    />
  </div>
</template>

<script>
import { BaseButton } from '@loophq/component-library';
import { AlertBanner } from '@loophq/ui';
import auth from '@/util/api/auth';

export default {
  components: {
    AlertBanner,
    BaseButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rescopeLoading: false,
      roleUpdateLoading: false,
      hideRoleBanner: false,
    };
  },
  computed: {
    routeCanShowBanner() {
      return this.$route.meta?.showBanner !== false;
    },
    layoutType() {
      return this.$route.meta.layoutType;
    },
    canShowScopesBanner() {
      return this.userData.shop.provider === 'shopify';
    },
    missingScopes() {
      const { scopes } = this.userData;

      // eslint-disable-next-line no-unused-vars
      return Object.entries(scopes).filter(([scopeName, enabled]) => {
        return !enabled;
      }).map(([scopeName]) => {
        return scopeName.trim();
      });
    }
  },
  methods: {
    updateRole() {
      this.roleUpdateLoading = true;
      this.$router.push(`/settings/account/edit/${this.userData.id}`);
    },
    async handleRescopeClick() {
      this.rescopeLoading = true;
      const resp = await auth.updateScopes();
      const rescopeUrl = resp.data;
      window.open(rescopeUrl, '_blank');
      this.rescopeLoading = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.banners {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-24) 0 0;
  margin: auto;
  max-width: 1080px;

  &.wide {
    max-width: 1040px;
  }

  &.full {
    max-width: unset;
    padding: var(--spacing-24) var(--spacing-64) 0;
    gap: 0;
  }
}

.banner {
  &-rescoping {
    margin-bottom: var(--spacing-16);

    &-scopes {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-role {
    margin-bottom: var(--spacing-16);

    &:first-child {
      margin-top: var(--spacing-400);
    }
  }
}

.highlighted {
  background-color: var(--color-grey-200);
  font-family: monospace;
  padding-left: var(--spacing-8);
  margin-right: var(--spacing-4);
  margin-top: var(--spacing-4);

  &::after {
    content: ',';
    background-color: var(--color-neutral-100);
    margin-left: var(--spacing-4);
    padding: var(--spacing-4) 0;
  }

  &:last-child {
    padding-right: var(--spacing-4);

    &::after {
      content: '';
    }
  }
}
</style>
