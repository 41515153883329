<template>
  <div
    class="skeleton-input"
    aria-hidden="true"
  >
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
$block: '.skeleton-input';

#{$block} {
  @include skeleton;

  position: relative;
  width: 100%;
  height: 2.5rem;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: rgba(white, 0.75);
  }
}
</style>
