import Roles from '@/util/api/role';
import changeCase from 'change-object-case';

export default {
  namespaced: true,
  state: {
    roles: [],
  },
  mutations: {
    setRoles(state, roles) {
      state.roles = roles;
    }
  },
  actions: {
    async getRoles({ commit }) {
      const resp = await Roles.get();
      const roles = changeCase.toCamel(resp.roles);
      commit('setRoles', roles);
    }
  },
  getters: {
    roles: state => state.roles,
  }
};
