import Store from '@/store';
import { permissions } from '@/util/schemas/permissions';
import { featureFlags } from '@/util/schemas/featureFlags';
import { shopSettings } from '@/util/schemas/ShopSettings';

export const results = [
  // Returns List
  {
    name: 'Return Management',
    description: 'Manage your outstanding returns',
    order: 1,
    url: '/returns',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  // Returns List Filtered
  {
    name: 'Closed Returns (filters)',
    description: 'View and manage closed returns',
    order: 2,
    url: '/returns?returnStatus=closed',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  {
    name: 'Cancelled Returns (filters)',
    description: 'View and manage cancelled returns',
    order: 2,
    url: '/returns?returnStatus=cancelled',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  {
    name: 'Needs Review Returns (filters)',
    description: 'View and manage returns that require manual review',
    order: 2,
    url: '/returns?flagged=true',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  {
    name: 'Refund Outcome Returns List (filters)',
    description: 'View and manage returns that opted for a refund',
    order: 2,
    url: '/returns?outcome=refund',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  {
    name: 'Upsell Outcome Returns List (filters)',
    description: 'View and manage returns that resulted in an upsell charge',
    order: 2,
    url: '/returns?outcome=upsell',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  {
    name: 'Exchange Outcome Returns List (filters)',
    description: 'View and manage exchanges',
    order: 2,
    url: '/returns?outcome=exchange',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  {
    name: 'Store Credit Returns List (filters)',
    description: 'View and manage returns that opted for a store credit/gift card',
    order: 2,
    url: '/returns?outcome=credit',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  {
    name: 'Flagged For Manual Review Returns List (filters)',
    description: 'View and manage returns flagged for manual review returns',
    order: 2,
    url: '/returns?flagged=true',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  {
    name: 'Review Status Returns List (filters)',
    description: 'View and manage returns that are in review status',
    order: 2,
    url: '/returns?returnStatus=review',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },
  {
    name: 'Expired Returns List (filters)',
    description: 'View and manage expired returns',
    order: 2,
    url: '/returns?returnStatus=expired',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
  },

  // Customizations
  {
    name: 'Customer Portal Customizations',
    description: 'View and manage your customer portal customizations',
    order: 1,
    url: '/settings/customizations',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_CUSTOMIZATIONS),
  },
  {
    name: 'Customer Portal Content Customizations',
    description: 'View and manage your customer portal content customizations',
    order: 2,
    url: '/settings/customizations/content',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_CUSTOMIZATIONS),
  },
  {
    name: 'Customer Portal Visual Customizations',
    description: 'View and manage your customer portal visual customizations',
    order: 2,
    url: '/settings/customizations/editor',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_CUSTOMIZATIONS),
  },

  {
    name: 'Return Reasons',
    description: 'View and manage return reasons',
    order: 1,
    url: '/settings/customizations/editor',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_CUSTOMIZATIONS),
  },

  {
    name: 'Notifications',
    description: 'View and manage notifications',
    order: 1,
    url: '/settings/notifications',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_NOTIFICATIONS),
  },

  {
    name: 'Integrations',
    description: 'View and manage integrations with third-party services like Stripe, Klaviyo, Gorgias & more',
    order: 1,
    url: '/settings/integrations',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_INTEGRATIONS),
  },

  {
    name: 'Developer Tools',
    description: 'View and manage developer tools like webhooks & API keys.',
    order: 1,
    url: '/settings/integrations',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_INTEGRATIONS),
  },

  // Tracking
  {
    name: 'Return & Exchange Tracking',
    description: 'View and manage return & exchange tracking settings.',
    order: 1,
    url: '/settings/return-and-exchange-tracking',
    isVisible: () => {
      const orderTrackingAvailable = Store.getters.hasFeature(featureFlags.FORWARD_TRACKING_ENABLED) && Store.getters.getSetting(shopSettings.ORDER_TRACKING_ALLOWED);
      Store.getters.hasPermission(permissions.MANAGE_TRACKING) && Store.getters.getSetting(shopSettings.RETURN_TRACKING_AVAILABLE) && orderTrackingAvailable;
    }
  },


  {
    name: 'Blocklist & Allowlist',
    description: 'View and manage your blocklist + allowlist for listings',
    order: 1,
    url: '/listings',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_LISTINGS),
  },

  {
    name: 'Shop Now',
    description: 'View and manage your settings related to the Shop Now feature',
    order: 1,
    url: '/shop-now',
    isVisible: () => Store.getters.getSetting('SHOP_NOW_ENABLED') && Store.getters.hasPermission(permissions.MANAGE_SHOP_NOW),
  },

  {
    name: 'Advanced Exchanges',
    description: 'View and manage your settings related to advanced exchanges',
    order: 1,
    url: '/advanced-exchanges',
    isVisible: () => Store.getters.getSetting('ADVANCED_EXCHANGES_ENABLED') && Store.getters.hasPermission(permissions.MANAGE_ADVANCED_EXCHANGES),
  },

  {
    name: 'Inventory Settings',
    description: 'View and manage your settings settings like minimum inventory, restocking, & out-of-stock scenarios',
    order: 2,
    url: '/settings/general',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS),
  },
  {
    name: 'Gift Card Settings',
    description: 'View and manage your gift card settings',
    order: 2,
    url: '/settings/general',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS),
  },
  {
    name: 'Gift Return Settings',
    description: 'View and manage your gift return settings',
    order: 2,
    url: '/settings/general',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS),
  },
  {
    name: 'Exchange Settings',
    description: 'View and manage your normal exchange settings and exchange accounting settings',
    order: 2,
    url: '/settings/general',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS),
  },
  {
    name: 'Edit Return',
    description: 'Toggle the ability to utilize the edit return feature',
    order: 2,
    url: '/settings/general',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS),
  },
  {
    name: 'Discounts',
    description: 'View and manage discount codes, automatic discounts & script discounts',
    order: 2,
    url: '/settings/general',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS),
  },
  {
    name: 'Order Lookup Criteria',
    description: 'Manage which criteria can be used for an order lookup. I.E. email, zip code and/or phone number',
    order: 2,
    url: '/settings/general',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS),
  },

  // Shipping
  {
    name: 'Shipping Services',
    description: 'View and manage shipping services like shipping integrations, label & shipment settings & prepaid labels',
    order: 1,
    url: '/settings/shipping',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_SHIPPING_SERVICES),
  },
  {
    name: 'Destination',
    description: 'View and manage shipping destinations and enforce product rules',
    order: 1,
    url: '/settings/destinations',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_DESTINATIONS),
  },
  {
    name: 'Locations',
    description: 'View and manage fulfillable locations',
    order: 1,
    url: '/settings/locations',
    isVisible: () => Store.getters.hasFeature(featureFlags.CUSTOMIZED_FULFILLABLE_LOCATIONS)
      && Store.getters.hasPermission(permissions.MANAGE_FULFILLABLE_LOCATIONS),
  },

  // Bundles
  {
    name: 'Bundles',
    description: 'View and manage bundles',
    order: 2,
    url: '/settings/bundles',
    isVisible: () => Store.getters.getSetting('BUNDLES_ENABLED') && Store.getters.getSetting('BUNDLE_PROVIDER') === 'loop' && Store.getters.hasPermission(permissions.MANAGE_BUNDLES),
  },

  // Workflows
  {
    name: 'Workflows',
    description: 'View and manage your workflows',
    order: 2,
    url: '/workflows',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_WORKFLOWS),
  },

  // Return Policy
  {
    name: 'Return Policy Management',
    description: 'View and manage your return policies',
    order: 2,
    url: '/settings/return-policy',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURN_POLICIES),
  },
  {
    name: 'Return outcomes',
    description: 'View and manage return outcomes, processing event, and return window',
    order: 2,
    url: '/settings/return-policy',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURN_POLICIES),
  },

  // Warranties
  {
    name: 'Warranty Policy Management',
    description: 'View and manage your warranty policies',
    order: 2,
    url: '/settings/warranty-policy',
    isVisible: () => Store.getters.getSetting('WARRANTIES_ENABLED'),
  },
  {
    name: 'Warranty Claims',
    description: 'View and manage warranty claims',
    order: 1,
    url: '/returns/warranties',
    isVisible: () => Store.getters.getSetting('WARRANTIES_ENABLED'),
  },

  // Account Settings
  {
    name: 'Account Settings',
    description: 'View and manage your user accounts',
    order: 1,
    url: '/settings/account',
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_USERS),
  },
];
