import RulesApi from '@/util/api/rules';

const request = new RulesApi();

export default {
  namespaced: true,
  state: {
    text: {
    },
    saving: false,
    items: []
  },
  mutations: {
    setProperty(state, { key, value, namespace }) {
      if (namespace) {
        state[namespace][key] = value;
      } else {
        state[key] = value;
      }
    }
  },
  actions: {
    getPageData({ commit }, route) {
      if (route.query && route.query.from === 'create') {
        return Promise.resolve();
      }
      return request.get()
        .then(res => {
          commit('setProperty', {
            key: 'items',
            value: res.data.rules
          });
        });
    }
  },
  getters: {
  },
};
