import { moduleTypes } from './workflow-rules';
import { askAQuestionTemplate, conditionalOutcomeTemplate } from '@/util/schemas/workflows/templates';
import ReturnlyLegacyTags from '@/util/schemas/workflows/templates/returnlyLegacyTags';
import store from '@/store';

export const WORKFLOWS_PER_PAGE = 25;

const createTemplate = (options) => {
  return {
    heading: options.heading,
    description: options.description,
    image: options.image,
    slug: options.slug,
    rules: options.rules,
    trigger: options.trigger,
    availableIf: options.availableIf ? options.availableIf : () => true,
  };
};

export const templates = [
  createTemplate({
    heading: 'Ask a question',
    description: 'Ask the customer a question that determines an action',
    image: 'question',
    slug: 'ask-a-question',
    rules: askAQuestionTemplate,
    trigger: 'item-selected',
    availableIf: () => {
      const modules = store.state.settings.workflows.modules;
      const userInput = modules.find(module => module.type === moduleTypes.USER_INPUT);
      return userInput?.enabled;
    }
  }),
  createTemplate({
    heading: 'Conditional outcomes',
    description: 'Determine an action based on a condition',
    image: 'keep-item',
    slug: 'keep-item-conditions',
    rules: conditionalOutcomeTemplate,
    trigger: 'return-initiated'
  }),
];

export const templateTypes = {
  CONDITIONAL_OUTCOME: 'conditional-outcome',
  ASK_A_QUESTION: 'ask-a-question',
};

export const returnlyTagsStaticTemplates = [
  createTemplate({
    heading: 'Free Shipping for Gift Card Outcomes',
    slug: 'rtly-gc:offer-free-shipping',
    rules: ReturnlyLegacyTags.GIFT_CARD_FREE_SHIPPING,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Refund to Gift Card',
    slug: 'rtly-gc',
    rules: ReturnlyLegacyTags.GIFT_CARD,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Green Return',
    slug: 'rtly-green-return',
    rules: ReturnlyLegacyTags.GREEN_RETURN,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Multi-warehouse',
    slug: 'rtly-ship-to',
    rules: ReturnlyLegacyTags.SHIP_TO,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Non-Returnable SKU',
    slug: 'rtly-non-returnable-sku',
    rules: ReturnlyLegacyTags.NON_RETURNABLE_SKU,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Non-Returnable',
    slug: 'rtly-non-returnable',
    rules: ReturnlyLegacyTags.NON_RETURNABLE,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Return Policy',
    slug: 'rtly-policy-window-days',
    rules: ReturnlyLegacyTags.POLICY_WINDOW_DAYS,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Shipping Rate',
    rules: ReturnlyLegacyTags.SHIPPING_RATE,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Exchange Block',
    slug: 'rtly-exchange:block',
    rules: ReturnlyLegacyTags.EXCHANGE_BLOCK,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Exchange Only',
    slug: 'rtly-exchange:only',
    rules: ReturnlyLegacyTags.EXCHANGE_ONLY,
    trigger: 'return-initiated',
    availableIf: () => true,
  }),
  createTemplate({
    heading: 'Manual Review',
    slug: 'rtly-manual-review',
    rules: ReturnlyLegacyTags.MANUAL_REVIEW,
    trigger: 'return-initiated',
    availableIf: () => true,
  })
];
