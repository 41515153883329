export default {
  data: function() {
    return {
      currency: {
        USD: '$',
        GBP: '£',
        CAD: '$',
        FUN: '🍑',
        BNE: '💀',
        CHB: '🍺$'
      }
    };
  }
};
