import axios from 'axios';
import changeCase from 'change-object-case';
import env from '@/env';

const auth = {
  login(user) {
    return new Promise((resolve, reject) => {
      if (typeof user !== 'object' || user === null) {
        console.error('API: auth.login() requires an object as an input.');
        reject();
      }

      //we want to override the default headers here and set the route to /api

      const base = env('VITE_API_URL');
      axios.defaults.baseURL = `${base}/api`;
      axios.post(`authenticate`, user)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  logout() {
    return axios.get(`logout`);
  },
  forgotPassword(data) {
    return axios.post(`password/email`, data);
  },
  getCurrentUser() {
    return axios.get(`users/active`);
  },
  getResetForm({ token }) {
    return axios.get(`password/reset/${token}`);
  },
  resetPassword(data) {
    return axios.post(`password/reset`, data);
  },
  install(data) {
    return axios.post(`install`, data);
  },
  updateScopes() {
    return axios.get(`scopes`);
  },
  postInstall(params) {
    return axios.get(`/shopify/auth/callback${params}`);
  },
  completeInstall(params) {
    return axios.get(`/shopify/auth/complete${params}`);
  },
  proxy(user) {
    return axios.get(`/proxy/${user}`);
  },
  pandiumJwt() {
    return axios.post(`authenticate/pandium`)
      .then((res) => res.data);
  },
  checkSSO(email) {
    return axios.get(`authenticate/sso/lookup?email=${encodeURIComponent(email)}`)
      .then((res) => changeCase.toCamel(res.data));
  },
  authenticateSSO() {
    return fetch(`${axios.defaults.baseURL}/authenticate/sso/token`, {
      referrerPolicy: 'no-referrer-when-downgrade',
      credentials: 'include',
    })
      .then((response) => response.json());
  },
  getInstallLink(ulid) {
    return axios.get(`/installation-link/${ulid}`)
      .then((response) => response.data);
  }
};

export default auth;
