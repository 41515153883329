import axios from 'axios';

const billing = {
  getPage() {
    return Promise.all([
      this.getInfo(),
      this.getInvoices()
    ])
      .then(([info, invoices]) => {
        if (info.data.errors) {
          info.data = null;
        }
        if (invoices.data.errors) {
          invoices.data.invoices = null;
        }
        return {
          info: info.data,
          invoices: invoices.data.invoices
        };
      });
  },
  getInfo() {
    return axios.get(`billing/plan`);
  },
  getInvoices() {
    // TODO: Build pagination
    return axios.post(`billing/invoices`, { page_size: 100 });
  },
  updatePaymentInfo(token) {
    return axios.post(`billing/payment`, { token });
  },
  getUrl() {
    return axios.get('/billing/portal_url')
      .then((res) => res.data);
  }
};

export default billing;
