<template>
  <div class="sidebar">
    <div class="sidebar__container">
      <div class="sidebar__logo-container">
        <img
          src="/img/icons/loop-nav-logo.svg"
          alt="Logo"
          class="sidebar__logo"
        />
      </div>
      <nav class="sidebar__nav--desktop">
        <ul
          role="list"
          class="sidebar__nav--list"
        >
          <li>
            <ul
              role="list"
              class="sidebar__list--primary"
            >
              <navigation-item
                v-for="item in primaryNav"
                :key="item.name"
                :item="item"
                :active="isActive(item)"
                @update="handleUpdate(item)"
                @update-subitem-parent="updateSubitemParent"
              />
            </ul>
          </li>
          <li>
            <ul
              role="list"
              class="sidebar__list--secondary"
            >
              <navigation-item
                v-for="item in secondaryNav"
                :key="item.name"
                :item="item"
                :active="isActive(item)"
                @update="handleUpdate(item)"
                @update-subitem-parent="updateSubitemParent"
              />
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import NavigationItem from '@/components/layout/NavigationItem.vue';

const store = useStore();
const route = useRoute();

defineProps({
  active: {
    type: Boolean,
    required: false,
    default: false
  }
});

const navItems = store.getters['sidebarNavigation/visibleItems'];
const primaryNav = ref(navItems.filter(item => !item.bottomNavItem));
const secondaryNav = ref(navItems.filter(item => item.bottomNavItem === true));

const activeItem = ref('');
const activeSubitemParent = ref('');

onMounted(() => {
  getActiveItem();
});

const getActiveItem = () => {
  const routePath = route.path.endsWith('/') ? route.path.slice(0, -1) : route.path;
  if (routePath === '/' || routePath === '/login') {
    activeItem.value = primaryNav.value[0].name;
  } else {
    const active = primaryNav.value.find(item => item.url === routePath) ?? secondaryNav.value.find(item => item.url === routePath);
    if (active) {
      activeItem.value = active.name;
    }
  }
};

const isActive = (item) => {
  if (!item) return false;
  return item.name === activeItem.value || item.name === activeSubitemParent.value;
};

const handleUpdate = (item) => {
  if (activeItem.value === item.name) return;
  activeItem.value = item.name;
};

const updateSubitemParent = (subitem, routePath = '') => {
  if (routePath) {
    // Update the active item based off the route
    const active = primaryNav.value.find(item => item.url === routePath) ?? secondaryNav.value.find(item => item.url === routePath);
    if (active) {
      activeItem.value = active.name;
    }
  } else {
    // Update the active subitem parent or set to empty string if no subitem
    activeSubitemParent.value = subitem?.parent ?? '';
  }
};
</script>

<style lang="scss">
.sidebar {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--nav-bar-height));
  background-color: var(--color-nav-background);
  color: white;
  padding: 0.5rem 1rem;
  overflow-y: auto;
  position: fixed;
  margin-top: var(--nav-bar-height);
  scrollbar-width: none;

  &__container,
  &__nav--desktop,
  &__nav--list {
    height: 100%;
  }

  &__nav--list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__list--primary,
  &__list--secondary {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
  }

  &__logo-container {
    display: none;
  }

  &__list--primary {
    margin-bottom: var(--spacing-24);
  }

  @media screen and (width <= 960px) {
    margin-top: var(--spacing-56);

    &__logo {
      height: 30px;
      width: auto;
    }

    &__logo-container {
      display: block;
      margin-bottom: var(--spacing-8);
    }
  }
}
</style>
