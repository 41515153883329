import Regions from '@/util/api/regions';
import Zones from '@/util/api/zones';

export default {
  namespaced: true,
  state: {
    regions: [],
    countries: [],
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    }
  },
  actions: {
    async getRegions({ commit }) {
      const regions = await Regions.getAll();

      commit('setProperty', {
        key: 'regions',
        value: regions
      });
    },
    async getCountries({ commit }) {
      const countries = await Zones.getCountries();

      commit('setProperty', {
        key: 'countries',
        value: countries
      });
    }
  },
  getters: {
  },
};
