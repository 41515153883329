import Territories from '@/util/api/customsTerritories';

export default {
  namespaced: true,
  state: {
    territories: null
  },
  mutations: {
    setTerritories(state, data) {
      state.territories = data;
    },
  },
  actions: {
    async get({ state, commit }) {
      // Since this data doesn't change often, we want to just grab this from
      // memory if we've already requested it this session
      if (state.territories) {
        return state.territories;
      }

      const res = await Territories.get();
      commit('setTerritories', res);

      return res;
    }
  },
};
