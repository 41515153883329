import DashboardSkeleton from '@/pages/Reporting/DashboardPage/DashboardSkeleton';

export default [
  {
    path: '/analytics/',
    redirect: '/analytics/reports'
  },
  {
    path: '/analytics/reports',
    component: () => import('@/pages/Reporting/ReportsList'),
    name: 'AnalyticsReports',
    meta: {
      layout: 'app',
      layoutType: 'full',
      title: 'Reports',
      loader: false
    },
  },
  {
    path: '/analytics/reports/:slug',
    component: () => import('@/pages/Reporting/ReportDetail'),
    name: 'AnalyticsReportDetail',
    meta: {
      layout: 'app',
      layoutType: 'full',
      title: 'Reports',
      loader: false
    }
  },
  {
    path: '/analytics/:slug',
    component: () => import('@/pages/Reporting/AdvancedInsights'),
    name: 'AdvancedInsights',
    meta: {
      layout: 'app',
      layoutType: 'full',
      title: 'Advanced insights',
      showBanner: false,
      skeleton: () => DashboardSkeleton
    }
  },
  // Legacy routes
  {
    path: '/analytics/advanced',
    redirect: '/analytics/overview'
  },
  {
    path: '/reporting/dashboard',
    redirect: '/analytics/overview'
  },
  {
    path: '/reporting/reports',
    redirect: '/analytics/reports'
  },
  {
    path: '/analytics/dashboard',
    redirect: '/analytics/overview'
  },
];
