<template>
  <div
    class="skeleton-layout"
    aria-hidden="true"
  >
    <skeleton-text
      class="skeleton-layout__heading"
      :lines="1"
      type="heading"
    />
    <page-layout align="right">
      <base-card class="skeleton-layout__card">
        <skeleton-text :lines="20" />
      </base-card>
      <template #sidebar>
        <base-card
          class="skeleton-layout__sidebar"
          type="unstyled"
        >
          <skeleton-text
            :lines="1"
            type="tabs"
          />
          <skeleton-text :lines="4" />
        </base-card>
      </template>
    </page-layout>
  </div>
</template>

<script>
import SkeletonText from '@/components/skeletons/SkeletonText';
import PageLayout from '@/components/structure/PageLayout';

export default {
  components: {
    SkeletonText,
    PageLayout,
  }
};
</script>

<style lang="scss" scoped>
$block: '.skeleton-layout';

#{$block} {
  width: 100%;

  &__heading {
    margin: 2rem 0;
  }

  &__card {
    margin-bottom: 1rem;
  }
}
</style>
