import auth from '@/util/api/auth';
import store from '@/store';
import Cookies from 'js-cookie';

export default [
  {
    path: '/install/:ulid?',
    component: async () => import('@/pages/Install/Pages/InstallPage.vue'),
    name: 'Install',
    meta: {
      title: 'Install',
      layout: 'none',
      loader: false,
      user: false,
    },
    beforeEnter(to, from, next) {
      if (typeof to.query.domain !== 'undefined') {
        auth
          .completeInstall(window.location.search)
          .then((res) => {
            const { status, errors } = res.data;
            if (status === 'ok') {
              const { token } = res.data;
              next({ path: `/onboarding`, query: { token: token }, replace: true });
            } else if (errors && errors.length) {
              console.error(errors);
              store.commit('onboarding/setErrors', errors);
            }
          })
          .catch((res) => {
            store.commit('onboarding/setErrors', [
              'Install failed for an unknown reason.',
            ]);
            console.error(res);
          });
      } else {
        next();
      }
    },
  },
  {
    path: '/install/setup',
    component: () => import('@/pages/Install/InstallSetupPage.vue'),
    meta: {
      title: 'Install',
      loader: false,
      user: false,
      layout: 'none',
      key: 'installOnboarding'
    },
    children: [
      {
        path: 'loading',
        component: () => import('@/pages/Install/Pages/InstallLoading.vue'),
        name: 'InstallLoading',
      },
      {
        path: '',
        name: 'InstallSetup',
        redirect: { name: 'InstallCreateAccount' },
      },
      {
        path: 'customer-lookup',
        name: 'InstallCustomerLookup',
        component: () => import('@/pages/Install/Pages/InstallCustomerLookup.vue'),
      },
      {
        path: 'account',
        name: 'InstallCreateAccount',
        component: () => import('@/pages/Install/Pages/InstallCreateAccount.vue'),
        meta: {
          step: 1,
        },
      },
      {
        path: 'store-settings',
        name: 'InstallStoreSettings',
        component: () => import('@/pages/Install/Pages/InstallStoreSettings.vue'),
        meta: {
          step: 2,
        },
      },
      {
        path: 'email-settings',
        name: 'InstallEmailSettings',
        component: () => import('@/pages/Install/Pages/InstallEmailSettings.vue'),
        meta: {
          step: 3,
        }
      },
      {
        path: 'initializing',
        component: () => import('@/pages/Install/Pages/InitializingApp.vue'),
        name: 'InitializingApp',
      },
    ],
  },
  {
    path: '/install/error',
    component: () => import('@/pages/Install/Pages/InstallError.vue'),
    name: 'InstallError',
    meta: {
      layout: 'none',
      user: false,
      loader: false,
    }
  },
  {
    path: '/shopify/auth/callback',
    component: () => import('@/pages/Auth/InstallingPage.vue'),
    name: 'Callback',
    meta: {
      title: 'Callback',
      layout: 'none',
      loader: false,
      user: false,
    },
    async beforeEnter(to, from, next) {
      // sets a cookie to reference in router index global hook to prevent calls to /active endpoint that 401 and
      // redirect to login. expires in 1 day.
      Cookies.set('loop-install-cookie', 'true', { expires: 1 });
      next({ name: 'InstallLoading', replace: true, query: to.query });
    },
  },
  {
    path: '/scopes',
    name: 'UpdateScopes',
    component: () => import('@/pages/States/LoadingPage'),
    meta: {
      title: 'Callback',
      layout: 'none',
      user: false,
    },
    beforeEnter() {
      auth
        .updateScopes()
        .then((res) => {
          window.location.href = res.data;
        })
        .catch((err) => console.error(err));
    },
  },
  {
    path: '/register',
    component: () => import('@/pages/Onboarding/OnboardingPage'),
    meta: {
      title: 'Register',
      layout: 'none',
      loader: false,
      user: false,
    },
    children: [
      {
        path: '',
        component: () => import('@/pages/Onboarding/ExistingStore'),
        name: 'RegisterPage',
        meta: {
          loader: false,
          action: 'onboarding/getPageData',
          user: false,
        },
      },
      {
        path: 'user',
        component: () => import('@/pages/Onboarding/CreateAccount'),
        name: 'CreateAccount',
        meta: {
          loader: false,
          action: 'onboarding/getPageData',
          user: false,
        },
      },
    ],
  },
];
