import _ from 'lodash';
import SettingsRequest from '@/util/api/policies';
import CustomizationRequest from '@/util/api/customizations';
import CFRequest from '@/util/api/customFeatures';
import Destinations from '@/util/api/destinations';
import shop from '@/util/api/shop';
import store from '@/store';

// TODO: merge this with the policy-utils file in /Pages
// TODO: figure out a better way to handle this
// Needed because store isn't available when loading?
const getStoreItem = name => {
  return store.state.settings.policies[name];
};

const request = (type, items) => {
  const types = {
    setting: new SettingsRequest(),
    destination: Destinations,
    customization: CustomizationRequest,
    'keep-item': new CFRequest('keep-item'),
    prepaid: new CFRequest('prepaid-labels'),
    shop
  };

  const filtered = items.filter(item => {
    // certain types contain period delimited strings to allow further breakdown (e.g. customization.email).
    // in these cases, the first delimited item will always be the primary base type.
    const [itemType] = item.type.split('.');
    return itemType === type;
  });

  if (filtered.length) {
    let mapped = filtered;

    if (type === 'destination') {
      mapped = toObject(filtered)?.destination;
    }

    if (type === 'customization') {
      const customizationsData = getStoreItem('customizationsData');

      // map out all the "email" type customizations.
      const emailCustomizations = filtered.filter(item => item.type === 'customization.email');
      const emailMap = {
        // email customization updates done here are always nested under the "settings" key.
        settings: toObject(emailCustomizations)
      };

      // map out all the "content" type customizations.
      const contentCustomizations = filtered.filter(item => item.type === 'customization.content');
      // content customizations can be nested under many modules (e.g. "moduleOrderLookup").
      // this maps all the content customizations as key->value pairs under their specified module.
      const contentMap = {};
      contentCustomizations.forEach(item => {
        if (item.module in contentMap) {
          Object.assign(contentMap[item.module], { 
            [item.key]: item.value,
          });
        } else {
          contentMap[item.module] = {
            [item.key]: item.value,
          };

          // prevent duplicate entries in doc type customization 
          // modules by setting source to target
          if (item.value?.type === 'doc') {
            const custData = _.cloneDeep(customizationsData.content[customizationsData.lang]);

            if (custData[item.module]) {
              custData[item.module][item.key] = item.value;
            }

            store.commit('settings/policies/setCustomizationsData', {
              payload: custData,
              lang: customizationsData.lang,
            });
          }
        }
      });

      mapped = {
        email: _.merge(
          {},
          customizationsData.email,
          emailMap,
        ),
        content: _.merge(
          {},
          customizationsData.content[customizationsData.lang],
          contentMap,
        ),
      };
    }

    if (type === 'setting') {
      mapped = filtered.map(item => {
        const current = getStoreItem('settingsData').find(cur => cur.key === item.key);
        const mapBooleans = current && current.type === 'radio' ? { value: item.value ? 'yes' : 'no' } : {};
        return Object.assign({}, item, { id: current ? current.id : item.id }, mapBooleans);
      });
    }

    if (type === 'keep-item') {
      const id = filtered[0].id;

      if (id) {
        mapped = Object.assign({}, toObject(filtered), { id });
      } else {
        mapped = toObject(filtered);
      }
    }

    if (type === 'prepaid') {
      mapped = filtered[0];
    }

    if (type === 'shop') {
      mapped = toObject(filtered);
    }

    return types[type].save(mapped);
  }

  // No items for this type, resolve immediately
  return Promise.resolve();
};

const toObject = (arr) => {
  return arr.reduce((acc, { key, value }) => {
    return Object.assign({}, acc, { [key]: value });
  }, {});
};

const makeRequests = (items) => {
  return Promise.all([
    request('setting', items),
    request('destination', items),
    request('customization', items),
    request('keep-item', items),
    request('prepaid', items),
    request('shop', items),
  ]);
};

export default {
  watchProperties(props, type) {
    return props.reduce((acc, cur) => {
      acc[cur] = function (value) {
        if (this.watch || this.watch === undefined) {
          if (value !== this.initialData[cur]) {
            this.$store.commit('settings/policies/addChange', {
              key: cur,
              type: type,
              value
            });
          } else {
            this.$store.commit('settings/policies/removeChange', {
              key: cur,
              type: type,
              value
            });
          }
        }
      };
      return acc;
    }, {});
  },
  makeRequests
};
