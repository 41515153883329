import axios from 'axios';

export default {
  getAll() {
    return axios.get('/return-methods')
      .then((res) => {
        return res.data;
      });
  }
};
