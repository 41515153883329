const BYOA_ROUTE_NAMES = {
  royalmail: 'RoyalMail',
  australiapost: 'AustraliaPost',
};
const FALLBACK_ITEM_CATEGORY = 'fashion';
const ITEM_CATEGORIES = [
  {
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '85171300',
    id: 1,
    includes_battery: true,
    name: 'Mobile Phones',
    slug: 'mobile_phones'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '84713000',
    id: 2,
    includes_battery: true,
    name: 'Tablets',
    slug: 'tablets'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '84713000',
    id: 3,
    includes_battery: true,
    name: 'Computers & Laptops',
    slug: 'computers_laptops'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '85258900',
    id: 4,
    includes_battery: true,
    name: 'Cameras',
    slug: 'cameras'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '85171400',
    id: 5,
    includes_battery: false,
    name: 'Accessory (no-battery)',
    slug: 'accessory_no_battery'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '85171400',
    id: 6,
    includes_battery: true,
    name: 'Accessory (with battery)',
    slug: 'accessory_with_battery'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: true,
    hs_code: '33040000',
    id: 7,
    includes_battery: false,
    name: 'Health & Beauty',
    slug: 'health_beauty'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '62032900',
    id: 8,
    includes_battery: false,
    name: 'Fashion',
    slug: FALLBACK_ITEM_CATEGORY
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '91021900',
    id: 9,
    includes_battery: false,
    name: 'Watches',
    slug: 'watches'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '85098000',
    id: 10,
    includes_battery: false,
    name: 'Home Appliances',
    slug: 'home_appliances'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '94038990',
    id: 11,
    includes_battery: false,
    name: 'Home Decor',
    slug: 'home_decor'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '95030099',
    id: 12,
    includes_battery: false,
    name: 'Toys',
    slug: 'toys'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '9506910000',
    id: 13,
    includes_battery: false,
    name: 'Sport & Leisure',
    slug: 'sport_leisure'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '42029210',
    id: 14,
    includes_battery: false,
    name: 'Bags & Luggages',
    slug: 'bags_luggages'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '85198160',
    id: 15,
    includes_battery: false,
    name: 'Audio Video',
    slug: 'audio_video'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '49011000',
    id: 16,
    includes_battery: false,
    name: 'Documents',
    slug: 'documents'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '71022900',
    id: 17,
    includes_battery: false,
    name: 'Jewelry',
    slug: 'jewelry'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '17049000',
    id: 18,
    includes_battery: false,
    name: 'Dry Food & Supplements',
    slug: 'dry_food_supplements'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '49019900',
    id: 19,
    includes_battery: false,
    name: 'Books & Collectibles',
    slug: 'books_collectibles'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '42010000',
    id: 20,
    includes_battery: false,
    name: 'Pet Accessory',
    slug: 'pet_accessory'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '85234990',
    id: 21,
    includes_battery: false,
    name: 'Gaming',
    slug: 'gaming'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '95049060',
    id: 29,
    includes_battery: false,
    name: 'Board Games',
    slug: 'board_games'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '64041100',
    id: 32,
    includes_battery: false,
    name: 'Shoes',
    slug: 'shoes'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '33049900',
    id: 49,
    includes_battery: false,
    name: 'Health & Beauty (non-liquid)',
    slug: 'health_beauty_non_liquid'
  },
  {
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '5204200000',
    id: 57,
    includes_battery: false,
    name: 'Crafts',
    slug: 'crafts'
  }
];

export default {
  BYOA_ROUTE_NAMES,
  ITEM_CATEGORIES,
  FALLBACK_ITEM_CATEGORY,
  itemCategoriesForSelect() {
    return ITEM_CATEGORIES.map(item => ({
      label: item.name,
      value: item.slug
    })).sort((a, b) => a.label.localeCompare(b.label));
  },
};
