export default {
  'click-outside': {
    // bind
    beforeMount(el, binding) {
      el.clickOutsideEvent = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
          binding.value(event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
    },

    // unbind
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    },
  }
};
