<template>
  <div
    class="skeleton-reporting"
    aria-hidden="true"
  >
    <skeleton-text
      class="skeleton-reporting__heading"
      :lines="1"
      type="heading"
    />
    <page-layout align="right">
      <base-card class="skeleton-reporting__card">
        <skeleton-text :lines="16" />
      </base-card>
      <template #sidebar>
        <base-card
          class="skeleton-reporting__sidebar"
          type="subdued"
        >
          <skeleton-text :lines="16" />
        </base-card>
      </template>
    </page-layout>
  </div>
</template>

<script>
import SkeletonText from '@/components/skeletons/SkeletonText';
import PageLayout from '@/components/structure/PageLayout';

export default {
  components: {
    SkeletonText,
    PageLayout,
  }
};
</script>

<style lang="scss" scoped>
$block: '.skeleton-reporting';

#{$block} {
  width: 100%;

  &__heading {
    margin: 2rem 0;
  }

  &__card {
    margin-bottom: 1rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
}
</style>
