import SkeletonLayout from '@/components/skeletons/SkeletonLayout';
import Request from '@/util/api/shopnow';
const shopnow = new Request();

export default [
  {
    path: '/shop-now/',
    component: () => import('@/pages/ShopNow/ShopNow'),
    name: 'ShopNow',
    meta: {
      layout: 'app',
      title: 'Shop Now',
      skeleton: () => SkeletonLayout,
      action: 'shopnow/getPageData'
    }
  },
  {
    path: '/stripe/oauth',
    name: 'StripeOAuth',
    meta: {
      user: false
    },
    beforeEnter(to, from, next) {
      shopnow.oauth(to.fullPath)
        .then(() => {
          next('/settings/integrations');
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  // Old page redirect
  {
    path: '/storefront/',
    redirect: '/shop-now/'
  }
];
