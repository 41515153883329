export const canConvertToNumber = (str = '') => {
  // Attempt to convert to number
  const converted = Number(str);
  // Set to true if converted vbalue is not not a number (yes, this is a double negative)
  return !isNaN(converted);
};

// This function will convert to number, but only if the entire string is a number
export const convertToNumber = (str = '') => {
  return canConvertToNumber(str) ? Number(str) : str;
};

export const scaleNumberRange = (number, oldMin, oldMax, newMin, newMax) => {
  if (number < oldMin) {
    throw new Error('number is less than oldMin');
  }
  if (number > oldMax) {
    throw new Error('number is greater than oldMax');
  }

  return (((newMax - newMin) * (number - oldMin)) / (oldMax - oldMin)) + newMin;
};

export const capitalize = (value) => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const deKebabWords = (words) => {
  if (!words) return '';
  return words.split('-').join(' ');
};

export const snakeToTitleList = (wordList) => {
  if (!wordList || wordList?.length === 0) return;

  return wordList.map((string) => {
    return snakeToTitleWord(string);
  });
};

export const snakeToTitleWord = (snakeWord) => {
  return snakeWord?.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const titleToKebab = (title) => {
  return title?.toLowerCase().split(' ').join('-');
};


