import axios from 'axios';
import changeCase from 'change-object-case';
changeCase.options = { recursive: true, arrayRecursive: true };

export default {
  /**
   * Lookup an order by name
   */
  async lookup(name) {
    const res = await axios.post(`/orders/lookup`, { name });
    return changeCase.toCamel(res.data);
  },
  /**
	 * Search for orders
	 * @param {string} query
	 * @param {string} searchKey
	 * @param {object} pageInfo
	 * @returns {Promise<OrderSearchResults>}
	 */
  async search({
    query,
    page_info = {},
    search_key,
  }) {
    return axios.post('/orders/search', { query, page_info, search_key });
  },
  async createDeepLink({ order, zip, gift }) {
    return axios.post('/orders/deeplink/create', {
      order,
      zip,
      gift
    });
  },
  async sendDeepLinkEmail({
    lookupData,
    gift,
    email
  }) {
    return axios.post('/orders/deeplink/send', {
      ...lookupData,
      gift,
      email
    });
  }
};
