import Destinations from '@/util/api/destinations';

export default {
  namespaced: true,
  state: {
    destinations: []
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    setDestinations(state, value) {
      state.destinations = value;
    }
  },
  actions: {
    async getAll({ commit }) {
      try {
        const destinations = await Destinations.getAll();
        commit('setDestinations', destinations);
        return Promise.resolve(destinations);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
