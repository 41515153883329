import axios from 'axios';

const apiKeys = {
  async getList() {
    try {
      const res = await axios.get(`api-keys`);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getScopes() {
    try {
      const res = await axios.get(`api-keys/scopes`);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async create(scopes, name) {
    if (!scopes || !scopes.length) {
      console.error('API: apiKeys.create() requires an array of scopes as an input.');
      return false;
    }

    try {
      const res = await axios.post(`api-keys`, {
        scopes,
        name
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async update(id, scopes, name) {
    if (!id || !scopes || !scopes.length) {
      console.error('API: apiKeys.create() requires an id and an array of scopes as inputs.');
      return false;
    }

    try {
      const res = await axios.put(`api-keys/${id}`, {
        scopes,
        name
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async remove(id) {
    if (!id) {
      console.error('API: apiKeys.remove() requires an id as an input.');
      return false;
    }

    try {
      const res = await axios.delete(`api-keys/${id}`);
      return res.data;
    } catch (error) {
      return error;
    }
  }
};

export default apiKeys;
